angular.ctModule('ct.services.csphotoselector', ['ct.services.facebook'])

       .run([
           '$templateCache', function($tempalteCache) {
               $tempalteCache.put('ct.services.csphotoselector/csphotoselector.html', '<!-- Markup for Carson Shold\'s Photo Selector -->' +
                   '<div id="CSPhotoSelector">' +
                   '<div class="CSPhotoSelector_dialog">' +
                   '<a href="#" id="CSPhotoSelector_buttonClose">x</a>' +
                   '<div class="CSPhotoSelector_form">' +
                   '<div class="CSPhotoSelector_header">' +
                   '<p>Wähle ein Foto</p>' +
                   '</div>' +
                   '' +
                   '<div class="CSPhotoSelector_content CSAlbumSelector_wrapper">' +
                   '<p>Durchsuche deine Alben</p>' +
                   '<div class="CSPhotoSelector_searchContainer CSPhotoSelector_clearfix">' +
                   '<div class="CSPhotoSelector_selectedCountContainer">Wähle ein Album</div>' +
                   '</div>' +
                   '<div class="CSPhotoSelector_photosContainer CSAlbum_container"></div>' +
                   '</div>' +
                   '' +
                   '<div class="CSPhotoSelector_content CSPhotoSelector_wrapper">' +
                   '<p>Wähle ein Foto</p>' +
                   '<div class="CSPhotoSelector_searchContainer CSPhotoSelector_clearfix">' +
                   '<div class="CSPhotoSelector_selectedCountContainer"><span class="CSPhotoSelector_selectedPhotoCount">0</span> / <span class="CSPhotoSelector_selectedPhotoCountMax">0</span> photos selected</div>' +
                   '<a href="#" id="CSPhotoSelector_backToAlbums">Zurück zu den Alben</a>' +
                   '</div>' +
                   '<div class="CSPhotoSelector_photosContainer CSPhoto_container"></div>' +
                   '</div>' +
                   '' +
                   '<div id="CSPhotoSelector_loader"></div>' +
                   '' +
                   '' +
                   '<div class="CSPhotoSelector_footer CSPhotoSelector_clearfix">' +
                   '<a href="#" id="CSPhotoSelector_pagePrev" class="CSPhotoSelector_disabled">Zurück</a>' +
                   '<a href="#" id="CSPhotoSelector_pageNext">Weiter</a>' +
                   '<div class="CSPhotoSelector_pageNumberContainer">' +
                   'Seite <span id="CSPhotoSelector_pageNumber">1</span> von <span id="CSPhotoSelector_pageNumberTotal">1</span>' +
                   '</div>' +
                   '<a href="#" id="CSPhotoSelector_buttonOK">Wählen</a>' +
                   '<a href="#" id="CSPhotoSelector_buttonCancel">Abbrechen</a>' +
                   '</div>' +
                   '</div>' +
                   '</div>' +
                   '</div>')
           }
       ])

       /**
        * @ngdoc service
        * @name ct.services.hash.service:CTHash
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * This service provides a callback for changes inside the hash
        *
        */
       .service('CTCSPhotoselector', [
           "$q", "$templateCache", "CTServiceFacebook", function($q, $templateCache, CTServiceFacebook) {
               "use strict";

               var ctCSPhotoselecor = this,
                   csPhotoSelectorInstance,
                   selectPromise;


               function initPhotoSelector() {
                   var csPhotoSelectorElement  = angular.element('<div id="ct-services--csphotoselector"></div>'),
                       body                    = angular.element('body'),
                       buttonOK,

                       callbackAlbumSelected   = function(albumId) {
                           var album, name;
                           album = CTCSPhotoSelector.getAlbumById(albumId);
                           // show album photos
                           csPhotoSelectorInstance.showPhotoSelector(null, album.id);
                       },

                       callbackAlbumUnselected = function(albumId) {

                       },

                       callbackPhotoSelected   = function(photoId) {
                           var photo = CTCSPhotoSelector.getPhotoById(photoId);
                           buttonOK.show();
                       },

                       callbackPhotoUnselected = function(photoId) {
                           buttonOK.hide();
                       },

                       callbackSubmit          = function(photoId) {
                           var photo = CTCSPhotoSelector.getPhotoById(photoId);
                           selectedPhoto(photo);
                       },

                       callbackCancel          = function() {
                           selectPromise.reject();
                       };

                   csPhotoSelectorElement.append($templateCache.get('ct.services.csphotoselector/csphotoselector.html'));
                   body.append(csPhotoSelectorElement);

                   buttonOK = angular.element('#ct-services--csphotoselector #CSPhotoSelector #CSPhotoSelector_buttonOK');

                   CTCSPhotoSelector.init({
                       containerSelector: '#ct-services--csphotoselector #CSPhotoSelector',
                       debug            : false
                   });

                   csPhotoSelectorInstance = CTCSPhotoSelector.newInstance({
                       callbackAlbumSelected  : callbackAlbumSelected,
                       callbackAlbumUnselected: callbackAlbumUnselected,
                       callbackPhotoSelected  : callbackPhotoSelected,
                       callbackPhotoUnselected: callbackPhotoUnselected,
                       callbackSubmit         : callbackSubmit,
                       callbackCancel         : callbackCancel,
                       maxSelection           : 1,
                       albumsPerPage          : 6,
                       photosPerPage          : 200,
                       autoDeselection        : true
                   });
               }

               /**
                * Unset the promise to free it for the next selection
                */
               function deleteSelectPromise() {
                   selectPromise = false;
               }

               /**
                * Reject the promise on an error
                * @param reason
                */
               function failedToConnect(reason) {
                   selectPromise.reject(reason);
               }

               /**
                * Selected the photo - now resolve
                * @param photo
                */
               function selectedPhoto(photo) {
                   $CB_API({
                       method: 'POST',
                       url   : '/uploaded_images',
                       params: {
                           url: photo.source
                       }
                   }).then(function(data) {
                       selectPromise.resolve(data);
                   }, function(error) {
                       failedToConnect(error);
                   });
               }

               ctCSPhotoselecor.select = function() {
                   if(!selectPromise) {
                       selectPromise = $q.defer();

                       selectPromise.promise.then(deleteSelectPromise, deleteSelectPromise);

                       CTServiceFacebook.login({scope: 'email,user_photos'}).then(function(authResponse) {
                           if(authResponse.authResponse) {
                               csPhotoSelectorInstance.reset();
                               csPhotoSelectorInstance.showAlbumSelector('me');
                           }
                           else {
                               failedToConnect();
                           }
                       }, failedToConnect)
                   }

                   return selectPromise.promise;
               };

               initPhotoSelector();
               return ctCSPhotoselecor;
           }
       ]);
