angular.ctModule('ct.services.authentication')

       /**
        * @ngdoc service
        * @name ct.services.authentication.service:CTServiceManageProfile
        *
        * @requires ct.services.facebook.service:CTServiceFacebook
        * @requires ct.services.authentication.service:CTServiceAuthentication
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Open a modal for editing the current users profile information
        */
       .service('CTServiceManageProfile', [
           "$modal", '$q', 'CTServiceAuthentication', function($modal, $q, CTServiceAuthentication) {

               /**
                * @access private
                */
               var ctServiceManageProfile = this;

               var ctServiceManagerProfileController = function($scope, $modalInstance, $timeout, CTServiceAuthentication, CTCSPhotoselector) {

                   function setImageDrag() {
                       var axis = (jQuery(this).width() > jQuery(this).height() ? 'x' : 'y');
                       jQuery('.ct-services--profile-data-manager--image-dragger img')
                           .on('load', function() {
                               axis = (jQuery(this).width() > jQuery(this).height() ? 'x' : 'y');

                               jQuery('.ct-services--profile-data-manager--image-dragger img')
                                   .draggable('option', 'axis', axis);
                               jQuery('.ct-services--profile-data-manager--image-dragger img').css({
                                   left: '0px',
                                   top : '0px'
                               });
                           })
                           .draggable({
                               axis: axis,
                               drag: function(event, ui) {
                                   var parentWidth  = jQuery(this).parent().width(),
                                       parentHeight = jQuery(this).parent().height(),
                                       imageWidth   = jQuery(this).width(),
                                       imageHeight  = jQuery(this).height();

                                   switch(axis) {
                                       case 'x':
                                           if(ui.position.left > 0) return false;
                                           if((ui.position.left + imageWidth) < (parentWidth - 1)) return false;
                                           break;

                                       case 'y':
                                           if(ui.position.top > 0) return false;
                                           if((ui.position.top + imageHeight) < (parentHeight - 1)) return false;
                                           break;
                                   }
                               },
                               stop: function(event, ui) {
                                   var imageNaturalWidth  = jQuery(this)[0].naturalWidth,
                                       imageNaturalHeight = jQuery(this)[0].naturalHeight,
                                       imageWidth         = jQuery(this).width(),
                                       imageHeight        = jQuery(this).height(),

                                       factor             = (axis == 'x' ? (imageNaturalWidth / imageWidth) : (imageNaturalHeight / imageHeight)),

                                       indentTop          = -(ui.position.top * factor),
                                       indentLeft         = -(ui.position.left * factor);

                                   $scope.$apply(function() {
                                       setDragData(indentTop, indentLeft);
                                   });
                               }
                           });
                   }

                   function setDragData(indentTop, indentLeft) {
                       $scope.dialog.changedImagePosition = true;
                       $scope.dialog.imagePosition        = {
                           top_indent : indentTop,
                           left_indent: indentLeft
                       };
                   }

                   function init() {
                       $scope.dialog = {};

                       $scope.dialog.user = CTServiceAuthentication.getCurrentUser();
                       if(!$scope.dialog.user.person.addresses) {
                           $scope.dialog.user.person.addresses = [{street: '', city: '', zip_code: ''}];
                       }
                       else if(!$scope.dialog.user.person.addresses[0]) {
                           $scope.dialog.user.person.addresses[0] = {street: '', city: '', zip_code: ''};
                       }

                       $scope.dialog.user.person.image_url = $scope.dialog.user.person.original_image ? $scope.dialog.user.person.original_image.image_url : $scope.dialog.user.person.image;

                       $scope.dialog.selectFacebookPhoto = function() {
                           CTCSPhotoselector.select().then(function(data) {
                               $scope.dialog.onupload(data.image_id, data.image_url);
                           }, function(error) {

                           });
                       };

                       $scope.dialog.onupload = function(image_id, image_url) {
                           $scope.dialog.user.person.image_url = image_url;
                           $scope.dialog.user.person.image_id  = image_id;

                           $scope.$$childTail.profileForm.APICall = $scope.dialog.imageLoading = false;
                       };

                       $scope.dialog.ondrop = function() {
                           $scope.$$childTail.profileForm.APICall = $scope.dialog.imageLoading = true;
                       };

                       $scope.dialog.deleteImage = function() {
                           delete $scope.dialog.user.person.image_url;
                           delete $scope.dialog.user.person.image_id;

                           $scope.dialog.changedImagePosition = false;
                           $scope.dialog.imagePosition        = {};
                       };

                       $scope.dialog.saveImagePosition = function() {
                           $scope.$$childTail.profileForm.APICall = true;
                           $scope.dialog.imageLoading             = true;

                           $CB_API({
                               method: 'PUT',
                               url   : '/uploaded_images/' + $scope.dialog.user.person.image_id,
                               data  : angular.extend({format: 'quad'}, $scope.dialog.imagePosition)
                           }).then(function() {
                               $scope.$$childTail.profileForm.APICall = false;
                               $scope.dialog.changedImagePosition     = false;
                               $scope.dialog.imageLoading             = false;
                           })
                       };

                       $scope.dialog.submit = function() {

                           $scope.$$childTail.profileForm.APICall = true;

                           $CB_API({
                               method: 'PUT',
                               url   : '/users/' + $scope.dialog.user.id,
                               data  : {
                                   person: {
                                       name    : $scope.dialog.user.person.name,
                                       surname : $scope.dialog.user.person.surname,
                                       address : $scope.dialog.user.person.addresses[0],
                                       image_id: $scope.dialog.user.person.image_id
                                   }
                               }
                           }).then(function(data) {
                               $scope.$$childTail.profileForm.APICall = false;
                               $modalInstance.close($scope.dialog.user);
                           }, function() {
                               $scope.$$childTail.profileForm.APICall = false;
                           });

                       };

                       $scope.dialog.dismiss = function() {
                           $modalInstance.dismiss('cancel');
                       };

                       setTimeout(setImageDrag, 500);
                   }

                   init();
               };


               ctServiceManageProfile.open = function() {
                   return $modal.open({
                       templateUrl: 'ct.services.profileDialog.html',
                       controller : [
                           '$scope',
                           '$modalInstance',
                           '$timeout',
                           'CTServiceAuthentication',
                           'CTCSPhotoselector',
                           ctServiceManagerProfileController
                       ]
                   });
               };

               ctServiceManageProfile.require = function(requirements) {

                   var needsDialog = false;

                   function checkUserData(objectToCheck, requirements) {

                       angular.forEach(requirements, function(requirementValue, requirementKey) {
                           if(angular.isArray(requirementValue) || angular.isObject(requirementValue)) {

                               if(objectToCheck[requirementKey]) {
                                   checkUserData(objectToCheck[requirementKey], requirementValue)
                               }
                               else {
                                   needsDialog = true;
                               }

                           }
                           else if(requirementValue && !objectToCheck[requirementKey]) {
                               needsDialog = true;
                           }
                       });
                   }

                   checkUserData(CTServiceAuthentication.getCurrentUser().person, requirements);

                   if(needsDialog) {// If something is not set - open the dialog
                       return ctServiceManageProfile.open().result;
                   }
                   else {
                       var q = $q.defer();
                       q.resolve(CTServiceAuthentication.getCurrentUser());
                       return q.promise;
                   }

               };


               return ctServiceManageProfile;
           }
       ]);
