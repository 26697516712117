angular.ctModule('ct.directives.copyField', [])
       /**
        * @ngdoc directive
        * @name ct.directives.directive:selecty
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Wrap markup to make a styleable SelectField
        *
        * @example
        <doc:example module="ct.directives.selecty">
        <doc:source>
        <script>
        angular.module('ct.directives.selecty').controller('testCtrl', ["$scope", function($scope) {
        $scope.text = 'Copy me';
    }]);
        </script>
        <div ng-controller="testCtrl">
        <input class="form-control" ng-model="text" data-copy-field>
        </div>
        </doc:source>
        </doc:example>
        */
       .directive('copyField', [
           function() {

               var _copyField = {
                   // Restrict the Directive to attributes
                   restrict: 'A'
               };

               //Create the linking function
               _copyField.link = function($scope, $element, $attrs) {

                   $element.on("focus", function(e) {
                       e.target.select();
                   }).on("mouseup", function(e) {
                       e.preventDefault();
                   }).on("keydown", function(e) {
                   })

               };

               return _copyField;
           }
       ]);
