angular.ctModule('ct.services.infoDialog', ['ui.bootstrap.modal']).service('CTInfoDialog', [
    "$modal", function($modal) {
        /**
         * CTInfoDialog
         *
         * @param {string} title The title of the dialog
         * @param {string} [message] The message of the dialog
         */
        return function CTInfoDialog(title, message) {

            return $modal.open({
                template      : '' +
                '<div class="modal-header">' +
                '<h4 class="modal-title">{{ modal.title }}</h4>' +
                '</div>' +
                '<div class="modal-body" ng-if="modal.message">' +
                '<p>{{ modal.message }}</p>' +
                '</div>',
                windowTempalte:
                "<div tabindex=\"-1\" class=\"modal fade ct__info_modal {{ windowClass }}\" ng-class=\"{in: animate}\" ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\" ng-click=\"close($event)\">\n" +
                "    <div class=\"modal-dialog\"><div class=\"modal-content\" ng-transclude></div></div>\n" +
                "</div>",
                backdrop      : false,
                keyboard      : false,
                controller    : [
                    "$scope",
                    "$modalInstance",
                    "$timeout",
                    function CTInfoDialogController($scope, $modalInstance, $timeout) {
                        $scope.modal = {
                            title  : title,
                            message: message
                        };

                        $timeout(function() {
                            $modalInstance.dismiss();
                        }, 5000);
                    }
                ]
            });

        };
    }
]);
