//DEPRECATED: Use mediaDrop instead!
angular.ctModule('ct.directives.imageUpload', [])

       /**
        * @ngdoc directive
        * @name ct.directives.directive:labelHide
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * Wrap an element for use with drag and drop image uploading
        *
        * @scope
        *
        */
       .directive('ctImageUpload', function() {
           var ImageUploadDirective = {};

           /** @property {string} limit directive to class definition */
           ImageUploadDirective.restrict = 'A';
           ImageUploadDirective.scope = {
               onupload: '&',
               ondrop  : '&'
           };

           ImageUploadDirective.link = function($scope, $element, $attrs) {
               var options = $scope.$eval($attrs.ctImageUpload) || {};

               /** Used to prevent the default action and set the drop effect */
               function useDragDropCopy(event) {
                   event.preventDefault();
                   try {
                       event.originalEvent.dataTransfer.dropEffect = 'copy';
                   } catch(e) {
                       try {
                           event.dataTransfer.dropEffect = 'copy';
                       } catch(ee) {
                       }
                   }
               }

               function doFileUpload(file) {
                   $CB_API({
                       method   : 'POST',
                       url      : '/uploaded_images',
                       imageFile: file
                   }).then(function(data) {
                       $scope.onupload({imageID: data.image_id, imageURL: data.image_url});
                   }, function() {
                       $scope.onupload({imageID: false, imageURL: false});
                   });
               }

               function init() {
                   //console.log(options.dropElement);
                   if(options.dropElement) {
                       $scope.dropElement = angular.element(options.dropElement);
                       //console.log($scope.dropElement);

                       $scope.dropElement.bind('dragover', useDragDropCopy).bind('dragenter', function(event) {
                           useDragDropCopy(event);

                           /** if drag enters a child element set the class */
                           if(jQuery.contains($scope.dropElement[0], event.target)) {
                               $scope.dropElement.addClass('drag-enter');
                           }
                       }).bind('dragleave', function(event) {
                           /** if drag leaves a child element set the class */
                           if(!jQuery.contains($scope.dropElement[0], event.target)) {
                               $scope.dropElement.removeClass('drag-enter');
                           }
                       }).bind('dragend', function(event) {
                           $scope.dropElement.removeClass('drag-enter');
                       }).bind('drop', function(event) {
                           event.stopPropagation();
                           event.preventDefault();

                           /** remove drag class */
                           $scope.dropElement.removeClass('drag-enter');

                           /** get the dropped file */
                           FileAPI.getDropFiles(event, function(files) {
                               /** read the dropped file */
                               FileAPI.readAsDataURL(files[0], function(evt/**Object*/) {
                                   if(evt.type == 'load') {
                                       $scope.ondrop({imageURL: evt.result});
                                   }
                               });

                               doFileUpload(files[0]);
                           });
                       });
                   }

                   if(options.inputElement) {
                       $scope.inputElement = angular.element(options.inputElement);

                       FileAPI.event.on($scope.inputElement[0], 'change', function(event) {
                           $scope.$apply(function() {
                               var files = FileAPI.getFiles(event.target);

                               FileAPI.readAsDataURL(files[0], function(evt) {
                                   if(evt.type == 'load') {
                                       $scope.ondrop({imageURL: evt.result});
                                   }
                               });

                               doFileUpload(files[0]);
                           });
                       });
                   }
               }

               setTimeout(init, 100);
           };

           return ImageUploadDirective;
       });
