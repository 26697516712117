angular.ctModule('ct.directives.fixedMaxHeight', [])
       /**
        * @ngdoc directive
        * @name ct.directives.directive:fixedMaxHeight
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Set max height for fixed signup forms
        *
        *
        * @example
        <doc:example module="ct.directives.fixedMaxHeight">
        <doc:source>
        <script>
        angular.module('ct.directives.fixedMaxHeight').controller('testCtrl', ["$scope", function($scope) {

    }]);
        </script>
        <div ng-controller="testCtrl">

        </div>
        </doc:source>
        </doc:example>
        */
       .directive('fixedMaxHeight', [
           "$parse", function($parse) {

               var _fixedMaxHeight = {};

               _fixedMaxHeight.link = function($scope, $element, $attr) {
                   var $window       = angular.element(window),
                       _getMaxHeight = $parse($attr.fixedMaxHeight),
                       getMaxHeight  = function() {
                           _getMaxHeight($scope);
                       };


                   function getOffsetParentOffsetTop(element) {
                       var offsetParent = element.offsetParent(),
                           offsetTop    = offsetParent.offset().top;
                   }

                   function calculateMaxHeight() {
                       if(getMaxHeight()) {

                       }
                       else {
                           $element.css({'max-height': '', 'overflow': ''});
                       }
                   }


                   $scope.$watch($attr.fixedMaxHeight, calculateMaxHeight);

                   $window.on('scroll', calculateMaxHeight);
                   $window.on('resize', calculateMaxHeight);
               };

               return _fixedMaxHeight;
           }
       ]);
