angular.ctModule('ct.directives.parallax', [])
       .directive('parallax', function() {

           const _parallax = {};

           let prefix;

           _parallax.scope = {
               parallax: '@'
           };

           _parallax.controller = [
               "$scope", "$element", function _parallaxController($scope, $element) {
                   let parallaxFactor  = $scope.parallax,
                       parallaxEnabled = true,
                       mediaQuery      = false,
                       $window         = jQuery(window);

                   function parallaxCalculator() {
                       if(parallaxEnabled) {
                           let elementOffset    = ($element.css('position') === 'absolute' ? $element.offsetParent()
                                                                                                     .offset().top : $element.offset().top),
                               windowHeight     = $window.height(),
                               scrollTop        = $window.scrollTop(),
                               translate        = (elementOffset > windowHeight ? (scrollTop - (elementOffset - windowHeight)) / parallaxFactor : (scrollTop - elementOffset) / parallaxFactor),
                               prefixedProperty = prefix.js + 'Transform';

                           $element[0].style[prefixedProperty] = 'translateY(' + translate + 'px)';
                       }
                   }

                   /**
                    * Checks the Browser and sets the necessary Vendor Prefix
                    */
                   function setPrefix() {
                       let styles = window.getComputedStyle(document.documentElement, ''),
                           pre    = (Array.prototype.slice
                                          .call(styles)
                                          .join('')
                                          .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
                           )[1];

                       return {
                           lowercase: pre,
                           css      : '-' + pre + '-',
                           js       : pre[0].toUpperCase() + pre.substr(1)
                       }

                   }

                   $window.on('scroll', parallaxCalculator);

                   $scope.$watch('parallax', function(newValue, oldValue) {
                       if(newValue != oldValue && $scope.parallax) {
                           parallaxFactor = $scope.parallax;
                       }
                   });


                   /**
                    * Initializes the Directive
                    */
                   function init() {

                       // Set Vendor Prefix
                       prefix = setPrefix();

                       /**
                        * Check if we are on mobile device. If yes, then Parallax will
                        * be disabled
                        */
                       if(window.matchMedia) {
                           mediaQuery = window.matchMedia('(min-width: 768px)');
                           mediaQuery.addListener(function() {
                               parallaxEnabled = mediaQuery.matches;
                           });

                           parallaxEnabled = mediaQuery.matches;
                       }


                       parallaxCalculator();
                   }


                   init();

               }
           ];

           return _parallax;
       });
angular.ctModule('ct.directives.paralax', ['ct.directives.parallax']);
