/**
 * @ngdoc service
 * @name ct.services.events.service:CTServiceEventCategories
 *
 * @author Michael Scharl <ms@campaigning-bureau.com>
 * @description
 * __This Service needs to be documented__
 *
 */
var CTEventCategory;
angular.ctModule('ct.services.events', [
    'ct.filters.truncate',
    'ct.services.ctGetCoordinates',
    'ct.services.authentication',
    'ct.constant',
    'ct.services.data'
])
       .constant('EventStatics', {
           DIALOG          : {
               INFO        : 'openInformationDialog',
               CONFIRM     : 'openConfirmationDialog',
               AUTHENTICATE: 'openAuthenticationDialog',
               EDIT        : 'openEditDialog',
               CAPACITY    : 'openEditCapacityDialog',
               CATEGORY    : 'openSelectCategoryDialog',
               INVITE      : 'openInviteDialog',
               SHARE       : 'openShareDialog',
               ATTEND      : 'openAttendDialog',
               UPDATE      : 'openUpdateDialog'
           },
           EVENTS          : {
               LOADED         : 'newEventsHaveBeenLoaded',
               ALL            : 'allEventsHaveBeenLoaded',
               EVENT_NOT_FOUND: 'eventNotFoundThroughApi',
               RESET          : 'allEventsCleared',
               CATEGORY_RESET : 'allEventCategoriesCleared'
           },
           USER            : {
               AUTHENTICATED: 'userIsNowLoggedIn',
               LOGGED_OUT   : 'userIsNowLoggedOut',
               LEVEL        : {
                   'standard' : 1,
                   'moderator': 3,
                   'admin'    : 2
               }
           },
           EVENT_CATEGORIES: {
               LOADED: 'eventCategoriesLoaded'
           }
       })
       .service('CTServiceEventCategories', [
           "EventStatics",
           "$log",
           "$rootScope",
           "CTConfig",
           "$q",
           function(EventStatics, $log, $rootScope, CTConfig, $q) {
               "use strict";

               var CategoryService       = {},
                   Categories            = {},

                   /** @access private */
                   CategoriesAreLoaded   = false,

                   /** @access private */
                   CategoriesLoadedDefer = false,

                   CategoryByIdPromise   = {};

               /**
                * Load all categories
                */
               function loadCategories(page_id, hide_empty_categories = 0) {
                   return $CB_API({
                       method: 'GET',
                       url: '/pages/' + (page_id || CTConfig.PAGE_ID) + '/event_categories',
                       params: {
                           'hide_empty_categories': hide_empty_categories,
                       },
                   }).done(function(data) {
                       $log.debug('loaded Categories');

                       if(!Categories[page_id]) {
                           Categories[page_id] = []
                       }
                       angular.forEach(data.event_categories, function(category) {
                           var alreadyPushed = (function() {
                               var flag = false;
                               angular.forEach(Categories[page_id], function(c) {
                                   if(c.id === category.id) {
                                       flag = true;
                                   }
                               });
                               return flag;
                           }());

                           if(!alreadyPushed) {
                               Categories[page_id].push(new CTEventCategory(angular.extend(category, {
                                   page_id: (page_id || CTConfig.PAGE_ID)
                               })));
                           }
                       });

                       $rootScope.$broadcast(EventStatics.EVENT_CATEGORIES.LOADED);
                       CategoryByIdPromise[page_id].resolve(Categories[page_id]);
                   });
               }

               /**
                * remove the given event category through an api call.
                *
                * @param category
                * @returns {promise|*|promise.promise|jQuery.promise|r.promise|jQuery.ready.promise}
                */
               CategoryService.remove = function(category) {
                   var deferred = $q.defer();

                   category.APICall = true;

                   $CB_API({
                       method: 'DELETE',
                       url   : '/pages/' + category.page_id + '/event_categories/' + category.id
                   }).then(function(data) {
                       category.APICall = false;
                       deferred.resolve();
                   }, function(error) {
                       deferred.reject(error);
                       category.APICall = false;
                   });

                   return deferred.promise;
               };


               CategoryService.getAllCategories = function(mid, hide_empty_categories = 0) { //TODO: DOCUMENTATION

                   if(!CategoryByIdPromise[mid]) {
                       CategoryByIdPromise[mid] = $q.defer();
                       loadCategories(mid, hide_empty_categories);
                   }

                   return CategoryByIdPromise[mid].promise;
               };

               CategoryService.getCategory = function(mid, id) { //TODO: DOCUMENTATION
                   if(!CategoryByIdPromise[id]) {
                       CategoryByIdPromise[id] = $q.defer();

                       CategoryService.getAllCategories(mid).then(function(categories) {
                           var resolved = false;

                           angular.forEach(categories, function(category) {
                               if(category.id == id) {
                                   resolved = CategoryByIdPromise[id].resolve(category);
                               }
                           });

                           if(!resolved) {
                               CategoryByIdPromise[id].reject();
                           }

                       }, function() {
                           CategoryByIdPromise[id].reject();
                       });
                   }


                   return CategoryByIdPromise[id].promise;
               };

               /**
                * Return all Categories
                *
                * @returns {Array}
                */
               CategoryService.$all = function(mid) { //TODO: REMOVE
                   if(!mid) {
                       var c = [];
                       angular.forEach(Categories, function(categories, key) {
                           c.concat(categories);
                       })
                       return c;
                   }
                   else {
                       return Categories[mid];
                   }
               };

               /**
                * Get a category by ID
                * @param id
                * @returns {boolean|Object}
                */
               CategoryService.$get = function(mid, id) { //TODO: REMOVE
                   var getCategory = false;
                   if(mid && !id) {
                       id  = mid;
                       mid = false;

                       angular.forEach(CategoryService.$all, function(category) {
                           if(category.id == id) {
                               getCategory = category;
                           }
                       })
                   }
                   else {
                       angular.forEach(Categories[mid], function(category) {
                           if(category.id == id) {
                               getCategory = category;
                           }
                       });
                   }
                   return getCategory;
               };

               CategoryService.$count = function(mid) { //TODO: REMOVE
                   if(!mid) {
                       return CategoryService.$all().length;
                   }
                   return Categories.length;
               };


               CategoryService.reloadAllCategories = function() { //TODO: REFACTOR
                   $log.debug('reloadAllCategories');
                   resetCategories();
                   loadCategories();
               };

               /**
                * reset all categories.
                * removes all loaded categories, so it is guaranteed, that they are loaded from the backend on the next
                * get-call.
                */
               CategoryService.reset = function() {
                   resetCategories();
               };

               /**
                * Getter for loaded state
                * @returns {boolean} are all categories loaded?
                */
               CategoryService.isLoaded = function(mid) { //TODO: DOCUMENTATION
                   if(!mid) {
                       return false
                   }
                   else {
                       return !!CategoryByIdPromise[mid];
                   }
               };

               /**
                * reset all categories
                */
               function resetCategories() {
                   Categories          = [];
                   CategoriesAreLoaded = false;
                   CategoryByIdPromise = {};
               }


               CTEventCategory = function(categoryData) {

                   var _category = this;

                   function prepareCategory(category) {

                       category.useDates = !!(category.starts_at && category.ends_at);

                       if(category.starts_at && !(category.starts_at instanceof Date)) {
                           category.starts_at = CT_string2date(category.starts_at + ' 00:00:00');
                       }
                       else if(!(category.starts_at instanceof Date)) {
                           category.starts_at = new Date();
                       }

                       if(category.ends_at && !(category.ends_at instanceof Date)) {
                           category.ends_at = CT_string2date(category.ends_at + ' 23:59:59');
                       }
                       else if(!(category.ends_at instanceof Date)) {
                           category.ends_at = new Date();
                           category.ends_at.setHours(168, 0, 0, 0);
                       }

                       // Unify flags for category
                       category.participant_requires_address = !(category.participant_requires_address == 0 || category.participant_requires_address == "0");
                       category.participant_requires_name    = !(category.participant_requires_name == 0 || category.participant_requires_name == "0");
                       category.participant_requires_phone   = !(category.participant_requires_phone == 0 || category.participant_requires_phone == "0");
                       category.participant_requires_zip     = !(category.participant_requires_zip == 0 || category.participant_requires_zip == "0");

                       category.official_events = !(category.official_events == 0 || category.official_events == "0");
                   }

                   _category.save = function() {
                       //console.log('saving data');
                       //console.log(angular.copy(_category));

                       var rawData = {};

                       if(_category.useDates) {
                           rawData.starts_at = CT_date2string(_category.starts_at, 'date');
                           rawData.ends_at   = CT_date2string(_category.ends_at, 'date');
                       }

                       rawData.active = _category.active;

                       rawData.name        = _category.name;
                       rawData.description = _category.description;

                       rawData.ask_for_host_phone           = _category.ask_for_host_phone;
                       rawData.ask_for_host_address         = _category.ask_for_host_address;
                       rawData.event_visibility_level       = _category.event_visibility_level;
                       rawData.host_alias_allowed           = _category.host_alias_allowed;
                       rawData.invitation_level             = _category.invitation_level;
                       rawData.moderated                    = _category.moderated;
                       rawData.official_events              = _category.official_events;
                       rawData.participant_requires_address = _category.participant_requires_address;
                       rawData.participant_requires_name    = _category.participant_requires_name;
                       rawData.participant_requires_phone   = _category.participant_requires_phone;
                       rawData.participant_requires_zip     = _category.participant_requires_zip;
                       rawData.participant_visibility_level = _category.participant_visibility_level;
                       rawData.users_can_create_event       = _category.users_can_create_event;
                       rawData.rsvp_acceptance_level        = _category.rsvp_acceptance_level;

                       if(_category.image_id) {
                           rawData.image_id = _category.image_id;
                       }
                       else {
                           rawData.image_id = null;
                       }


                       var url    = '/pages/' + category.page_id,
                           method = 'POST';
                       if(_category.id) {
                           url += '/event_categories/' + _category.id;
                           method = 'PUT'
                       }

                       var request = $CB_API({
                           method: method,
                           url   : url,
                           data  : rawData
                       });

                       request.then(function(data) {
                           prepareCategory(data.event_category);
                           angular.extend(_category, data.event_category);
                       }, function(data) {
                           $log.error(data);
                       });

                       return request;
                   };

                   /**
                    * remove the given event category through an api call.
                    *
                    * @param category
                    * @returns {promise|*|promise.promise|jQuery.promise|r.promise|jQuery.ready.promise}
                    */
                   _category.remove = function() {
                       var deferred = $q.defer();

                       _category.APICall = true;

                       // if the category has no id, it wasn't saved before and no actual api call is needed
                       if(typeof _category.id === 'undefined') {
                           deferred.resolve();
                       }
                       else {
                           $CB_API({
                               method: 'DELETE',
                               url   : '/pages/' + category.page_id + '/event_categories/' + category.id
                           }).then(function(data) {
                               _category.APICall = false;
                               deferred.resolve();
                           }, function(error) {
                               deferred.reject(error);
                               _category.APICall = false;
                           });
                       }

                       return deferred.promise;
                   };


                   //INIT
                   prepareCategory(categoryData);
                   angular.extend(this, categoryData);

                   return _category;
               };

               return CategoryService;
           }
       ]);
