const ALWAYS_VALID = true;

angular.ctModule('ct.services.authentication')

/**
 * @ngdoc service
 * @name ct.services.authentication.service:CTServiceLogin
 *
 * @requires ct.services.facebook.service:CTServiceFacebook
 *
 * @author Michael Scharl <ms@campaigning-bureau.com>
 *          Stefan Schindler <stefan.schindler@campaigning-bureau.com>
 *
 * @description
 * Manage the Login window with it´ variants
 */
    .service('CTServiceLogin', [
        '$modal',
        '$timeout',
        '$location',
        '$log',
        'CTServiceFacebook',
        'CTConfig',
        'CTServiceHelper',
        function($modal, $timeout, $location, $log, CTServiceFacebook, CTConfig, CTServiceHelper) {

            var /** @access private */
            ctServiceLogin = this;

            /**
             * Controller function for login dialog
             *
             * @param {object} $scope
             * @param {object} $modalInstance
             * @param $log
             * @param presetState
             * @param currentUser
             */
            function authenticationModalController($scope, $modalInstance, $log, presetState, currentUser) {

                /** Save dialog specific data */
                $scope.dialog = {
                    APICall: false,
                    sentSuccessfully: false
                };


                $scope.states = {};

                /**
                 * Close the modal - reject promise
                 */
                $scope.dismiss = function() {
                    $modalInstance.dismiss('cancel');
                };

                /**
                 * close the modal - accept promise
                 */
                $scope.close = function(data) {
                    $modalInstance.close(data);
                };


                /**
                 * Do Facebook login
                 * TODO call proxy route
                 */
                $scope.loginWithFacebook = function() {
                    $scope.dialog.APICall = true;

                    CTServiceFacebook.login({ scope: 'email' })
                        .then(//Facebook Login was successful
                            function(fbResponse) {

                                let params = appendDisclaimerAcceptedPropertyIfSet({
                                    facebook_id: fbResponse.authResponse.userID,
                                    access_token: fbResponse.authResponse.accessToken
                                });

                                $CB_API({
                                    method: 'POST',
                                    url: '/oauth/facebook',
                                    params: {
                                        facebook_id: fbResponse.authResponse.userID,
                                        access_token: fbResponse.authResponse.accessToken
                                    },
                                    excludeApiVersionPart: true
                                })
                                    .then(//Login was successful
                                        function(data) {
                                            //Login finished - Return user to promise
                                            $modalInstance.close(data.user);
                                        }, //Could not login
                                        function(data) {
                                            formErrorSet();
                                            $scope.formError.loginMask.facebook = 'Hoppla, wir konnten dich leider nicht mit Facebook verbinden';
                                        }
                                    )
                                    .always(function() {
                                        $scope.dialog.APICall = false;
                                        $scope.$apply();
                                    });

                            }, //Facebook couldn't be used
                            function() {
                                $scope.dialog.APICall = false;
                                formErrorSet();
                                $scope.formError.loginMask.facebook = 'Hoppla, wir konnten dich leider nicht mit Facebook verbinden';
                            }
                        );
                };


                /**
                 * Do Login with E-Mail and Password
                 */
                $scope.loginWithEmailAndPassword = function() {
                    var auth = {
                        username: $scope.data.email,
                        password: $scope.data.password
                    };

                    $scope.dialog.APICall = true;

                    $CB_API({
                        method: 'POST',
                        url: '/oauth/login',
                        params: auth,
                        excludeApiVersionPart: true
                    })
                        .done(function(data) {
                            //Login finished - Return user to promise
                            $modalInstance.close(data.user);

                        })
                        .fail(function(data) {
                            resetErrors();
                            formErrorSet();
                            if (data.message.lastIndexOf('AUTH_ERROR_01', 0) === 0) {
                                $scope.formError.loginMask.email = 'Diese E-Mail-Adresse ist uns unbekannt';
                            }
                            else if (data.message.lastIndexOf('AUTH_ERROR_02', 0) === 0) {
                                $scope.formError.loginMask.password = 'Hoppla, das Passswort stimmt leider nicht';
                            }
                            else if (data.message.lastIndexOf('AUTH_ERROR_04', 0) === 0) {
                                $scope.formError.loginMask.email = 'Ein User mit dieser E-Mail-Adresse existiert bereits';
                            }
                        })
                        .always(function() {
                            $scope.dialog.APICall = false;
                            $scope.$apply();
                        });
                };

                /**
                 * the user has forgot his password
                 * redirect to the 'setPasswordInfoForm', but with other layout, so set a parameter
                 */
                $scope.forgotPassword = function() {
                    $scope.dialog.step = 'setPasswordInfoForm';
                };

                /**
                 * Create a new account with Email and Password
                 */
                $scope.createAccountWithPassword = function() {
                    createNewUser();
                };

                /**
                 * change the password of an existing user
                 */
                $scope.changePassword = function() {

                    // check if the passwords match
                    resetErrors();
                    if ($scope.data.password !== $scope.data.repassword) {
                        formErrorSet();
                        $scope.formError.createAccount.repassword = 'Das Passwort und die Bestätigung stimmen leider nicht überein';
                        return;
                    }

                    var user = {};

                    user.password_hash = md5($scope.data.password);

                    $scope.dialog.APICall = true;

                    $CB_API({
                        method: 'PUT',
                        url: '/users/' + currentUser.id,
                        authentication: { type: 'user' },
                        data: user
                    })
                        .done(function(data) {
                            $scope.dialog.APICall = false;
                            $scope.dialog.step = 'changedPasswordInfo';
                            $scope.dialog.sentSuccessfully = true;
                        })
                        .fail(function(data) {
                            if (data.message.lastIndexOf('AUTH_ERROR_05', 0) === 0) {
                                $scope.formError.createAccount.repassword = 'Diese E-Mail-Adresse ist uns unbekannt';
                            }
                            $scope.dialog.APICall = false;
                            $log.error(data);
                        })
                        .always(function() {
                            $scope.$apply();
                        });
                };

                /**
                 * create a new user
                 */
                function createNewUser() {
                    // check if the passwords match
                    resetErrors();
                    if ($scope.data.password !== $scope.data.repassword) {
                        formErrorSet();
                        $scope.formError.createAccount.repassword = 'Das Passwort und die Bestätigung stimmen leider nicht überein';
                        return;
                    }

                    var user = {};

                    user.password_hash = md5($scope.data.password);
                    user.person = {
                        email: $scope.data.email
                    };

                    user.abuse_redirect_url = CTServiceHelper.addParameterToUrl('action', 'user.delete_account');
                    user = appendDisclaimerAcceptedPropertyIfSet(user);


                    $scope.dialog.APICall = true;

                    $CB_API({
                        method: 'POST',
                        url: '/users',
                        data: user
                    })
                        .done(function(data) {

                            $scope.dialog.sentSuccessfully = true;

                            if (!data.user.opt_in) {
                                $scope.formError.createAccount.create = 'Bitte bestätige deine E-Mail-Adresse. Du solltes in Kürze ein Mail bekommen.';

                                $scope.dialog.APICall = false;
                                $scope.$apply();
                            }
                            else {
                                // direct login of the newly created user
                                $CB_API({

                                    method: 'POST',
                                    url: '/oauth/login',
                                    params: {
                                        username: $scope.data.email,
                                        password: $scope.data.password
                                    },
                                    excludeApiVersionPart: true
                                })
                                    .then(function(data) {
                                        // New user successfully created
                                        $modalInstance.close(data.user);
                                    })
                                    .always(function() {
                                        $scope.dialog.APICall = false;
                                        $scope.$apply();
                                    });
                            }
                        })
                        .fail(function(data) {
                            $scope.dialog.APICall = false;
                            formErrorSet();
                            if (data.message.lastIndexOf('AUTH_ERROR_04', 0) === 0) {
                                $scope.formError.createAccount.email = 'Hoppla, diese E-Mail-Adresse ist schon registriert';
                            }
                            else if (data.message.lastIndexOf('AUTH_ERROR_05', 0) === 0) {
                                $scope.formError.createAccount.email = 'Hoppla, diese E-Mail-Adresse ist schon registriert';
                            }
                        })
                        .always(function() {
                            $scope.$apply();
                        });
                }

                $scope.resendEmail = function() {
                    $scope.sendPasswordResetLink();
                };

                /**
                 * a password reset link has been sent
                 */
                $scope.sendPasswordResetLink = function() {
                    sendEmailLoginLink($scope.data.email,
                        CTServiceHelper.addParameterToUrl('action', 'user.set_password'),
                        'user.set_password'
                    );
                };

                /**
                 * send an email login link
                 *
                 * @param email
                 * @param redirect_url
                 * @param custom_action
                 */
                function sendEmailLoginLink(email, redirect_url, custom_action) {
                    var data = {};
                    data.email = email;

                    data.redirect_url = redirect_url;

                    if (custom_action !== '') {
                        data.custom_action = custom_action;
                    }

                    $scope.dialog.APICall = true;

                    $CB_API({
                        method: 'POST',
                        url: '/password_reset',
                        data: data
                    })
                        .done(function(data) {
                            $scope.dialog.step = 'sentPasswordInfo';
                        })
                        .fail(function(data) {
                            $log.error(data);
                        })
                        .always(function() {
                            $scope.dialog.APICall = false;
                            $scope.$apply();
                        });
                }

                /**
                 * delete the user via the api and redirect to the deletedUserInfo state
                 */
                $scope.deleteAccount = function() {
                    $scope.dialog.APICall = true;
                    $CB_API({
                        method: 'DELETE',
                        url: '/users/' + currentUser.identifier,
                        authentication: { type: 'user' }
                    })
                        .done(function(data) {
                            $scope.dialog.step = 'deletedUserInfo';
                        })
                        .fail(function(data) {
                            $log.error(data);
                        })
                        .always(function() {
                            $scope.dialog.APICall = false;
                            $scope.$apply();
                        });
                };

                $scope.hasError = function() {
                    var hasError = false;
                    angular.forEach($scope.formError, function(error) {
                        if (error) {
                            hasError = true;
                        }
                    });

                    return hasError;
                };

                function formErrorSet() {

                    $scope.$$childTail.loginMask.$setPristine();
                    var watcher = $scope.$watch(function() {
                        return $scope.$$childTail.loginMask.$dirty;
                    }, function(oldVal, newVal) {
                        if (typeof oldVal !== 'undefined' && typeof newVal !== 'undefined' && oldVal != newVal) {
                            watcher();
                            resetErrors();
                        }
                    });
                }

                function resetErrors() {
                    $scope.formError = {
                        loginMask: {
                            email: false,
                            password: false,
                            facebook: false
                        },
                        createAccount: {
                            email: false,
                            password: false,
                            repassword: false,
                            disclaimerAccepted: false
                        },
                        setPasswordInfo: {
                            email: false
                        }
                    };
                }

                function init() {

                    setData();
                    resetErrors();
                    $scope.dialog.step = 'loginMask';

                    if (presetState && presetState !== false) {
                        $scope.dialog.step = presetState;
                    }
                }

                const setData = () => {
                    /** Save user input data */
                    $scope.data = {};
                    setDisclaimerAccepted(false);
                };


                /**
                 * Only Sends the Param to the Backend, when Flag is enabled
                 * @param data
                 * @returns {{} & any & any}
                 */
                const appendDisclaimerAcceptedPropertyIfSet = (data) => {
                    return Object.assign({},
                        data,
                        (typeof $scope.data.disclaimer_accepted !==
                         'undefined') ? { disclaimer_accepted: $scope.data.disclaimer_accepted } : {}
                    );
                };

                /**
                 * Initialize disclaimer_accepted only when config Value is set
                 * @param val
                 */
                const setDisclaimerAccepted = (val) => {
                    if ($scope.acceptDisclaimerEnabled()) {
                        $scope.data.disclaimer_accepted = val;
                    }
                };


                $scope.acceptDisclaimerEnabled = () => CTConfig.USER_CREATE_ACCEPT_DISCLAIMER_ENABLED;

                $scope.disclaimerAcceptedAndConfigSet = () => (CTConfig.USER_CREATE_ACCEPT_DISCLAIMER_ENABLED) ? $scope.data.disclaimer_accepted : ALWAYS_VALID;

                init();
            }

            /**
             * @ngdocs method
             * @name ct.services.authentication.service#login
             * @methodOf ct.services.authentication.service:CTServiceLogin
             *
             * @param {object} params Parameters for this dialog ('callback_url' or 'title')
             * @returns {promise} the modal promise
             */
            ctServiceLogin.login = function(params) {
                return $modal.open({
                    templateUrl: 'ct.services.loginDialog.html',
                    controller: authenticationModalController,
                    resolve: {
                        presetState: function() {
                            return false;
                        },
                        currentUser: function() {
                            return false;
                        }
                    }
                });
            };

            /**
             * called when a pw is to be changed from a clicked email link. directly opens the necessary dialog
             *
             * @param currentUser
             * @returns {*}
             */
            ctServiceLogin.setPassword = function(currentUser) {
                return $modal.open({
                    templateUrl: 'ct.services.loginDialog.html',
                    controller: authenticationModalController,
                    resolve: {
                        presetState: function() {
                            return 'changePassword';
                        },
                        currentUser: function() {
                            return currentUser;
                        }
                    }
                });
            };


            /**
             * called when a user is to be deleted. directly opens the necessary dialog
             *
             * @param currentUser
             * @returns {*}
             */
            ctServiceLogin.deleteUser = function(currentUser) {
                return $modal.open({
                    templateUrl: 'ct.services.loginDialog.html',
                    controller: authenticationModalController,
                    resolve: {
                        presetState: function() {
                            return 'deleteUserRequest';
                        },
                        currentUser: function() {
                            return currentUser;
                        }
                    }
                });
            };


            return ctServiceLogin;
        }
    ]);
