angular.ctModule('ct.services.helpers', []).service('CTServiceModalHelper', [
    "$log", "$modal", function($log, $modal) {
        "use strict";

        /**
         * @description
         * the modal helper service object
         *
         * @type {{}}
         */
        var CTServiceModalHelper = {};


        /**
         * @description
         * opens a confirmation modal. returns a promise that is resolved when 'ok' is clicked, else rejected.
         *
         * @param {string} confirmationQuestion the question for the modal
         * @param {string} confirmationIcon a fontawesome icon class
         * @returns {promise}
         */
        CTServiceModalHelper.openConfirmationModal = function(confirmationQuestion, confirmationIcon) {
            var popup = $modal.open({
                templateUrl: 'ct.services.modals.confirmationModal.html',
                controller : [
                    '$scope', 'question', '$modalInstance', function($scope, question, $modalInstance) {
                        $scope.question = question;

                        $scope.confirm = function() {
                            $modalInstance.close('ok');
                        };

                        $scope.dismiss = function() {
                            $modalInstance.dismiss('cancel');
                        };
                    }
                ],
                resolve    : {
                    question: function() {
                        return confirmationQuestion;
                    }
                }
            });

            return popup.result;
        };

        return CTServiceModalHelper;
    }
]);
