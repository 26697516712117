angular.ctModule('ct.directives.labelHide', [])

       /**
        * @ngdoc directive
        * @name ct.directives.directive:labelHide
        * @restrict C
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * Hide a input label based on the ngModel value
        *
        * @example
        <doc:example module="ct.directives.labelHide">
        <doc:source>
        <style>
        .label-hide { position: relative; }
        .label-hide .control-label {
                position:absolute;
                line-height: 39px;
                padding-left: 6px;

                top: 0;
                left: 0;

                cursor: text;
            }
        </style>
        <div class="form-group label-hide">
        <label class="control-label" for="bar">Label</label>
        <input type="text" class="form-control" ng-model="foo" id="bar">
        </div>
        </doc:source>
        </doc:example>


        */
       .directive('labelHide', function() {
           var labelHideDirective = {};

           /** @property {string} limit directive to class definition */
           labelHideDirective.restrict = 'C';

           labelHideDirective.link = function($scope, $element, $attrs) {
               var el, index, label, ngModel;

               function init() {
                   for(index = 0; index < $element[0].children.length; index++) {
                       el = $element[0].children[index];

                       if(el.tagName.toUpperCase() === 'LABEL') {
                           label = el;
                       }
                   }
                   if(label) {
                       el      = $element.find('#' + label.getAttribute('for'));
                       ngModel = angular.element(el).data('$ngModelController');
                   }

                   if(ngModel) {
                       $scope.$watch(function() {
                           return ngModel.$viewValue;
                       }, changeLabelStatus);
                       changeLabelStatus();
                   }
               }

               function changeLabelStatus() {
                   switch(typeof ngModel.$viewValue) {
                       case 'string':
                           ngModel.$viewValue.length ? setLabelHidden(true) : setLabelHidden(false);
                           break;
                       case 'number':
                           //label.style.display = (ngModel.$viewValue ? 'none' : 'block');
                           setLabelHidden(true);
                           break;
                       case 'object':

                           break;

                       case 'function':
                       case 'boolean':
                       case 'undefined':
                       default:
                           setLabelHidden(false);
                           break;
                   }
               }

               /**
                * Hide or Show the label
                *
                * @param flag
                */
               function setLabelHidden(flag) {
                   switch(flag) {
                       case true:
                           $attrs.hasOwnProperty('customLabelHide') ? angular.element($element)
                                                                             .addClass('label-hide--hidden') : label.style.display = 'none';
                           break;

                       case false:
                           $attrs.hasOwnProperty('customLabelHide') ? angular.element($element)
                                                                             .removeClass('label-hide--hidden') : label.style.display = 'block';
                   }
               }

               setTimeout(init, 0);
           };

           return labelHideDirective;
       });
