angular.ctModule('ct.filters.truncate', [])

       /**
        * @ngdoc filter
        * @name ct.filters.filter:truncate
        *
        * @function
        * @param {string} input The text you want to truncate
        * @param {number=} [count=120] The number of characters to extract
        * @param {string=} [appendix='…'] The sign that will be appended if your text has been truncated
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * Extract maximum the first x character of a text without breaking words. If more characters than count,
        * stop at the last space
        *
        * @example
        <doc:example module="ct.filters.truncate">
        <doc:source>
        <div ng-init="foo={'text': 'Hallo Welt!', count: 5, appendix: '…'}">
        <div class="form-group">
        <label class="control-label">Text</label>
        <textarea class="form-control" ng-model="foo.text"></textarea>
        </div>
        <div class="form-group">
        <label class="control-label">Count</label>
        <input type="number" class="form-control" ng-model="foo.count">
        </div>

        <div class="form-group">
        <label class="control-label">Appendix</label>
        <input type="text" max-length="1" class="form-control" ng-model="foo.appendix">
        </div>
        <div>
        <strong>Original:</strong><br>
        {{ foo.text }}
        </div>
        <div>
        <strong>Shorten:</strong><br>
        {{ foo.text | truncate:foo.count:foo.appendix }}
        </div>
        </div>
        </doc:source>
        </doc:example>

        */
       .filter('truncate', function() {

           return function(input, count, appendix) {
               input    = input || '';
               count    = count || 120;
               appendix = appendix || ' …';

               var regEx = new RegExp('^(.{0,' + count + '})(?!\\w)'),
                   match = regEx.exec(input)[0];

               return match + (match.length != input.length ? appendix : '');
           };
       });
