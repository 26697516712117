angular.ctModule('ct.services.helpers', [])
       .constant('ctHelperEvents', {
           /**
            * @ngdoc event
            * @name ctServiceHelper.service#CHANGE_STATUS
            * @eventOf ct.services.helpers.service:CTServiceHelper
            * @eventType broadcast on $rootScope
            *
            * @description
            * To use this event you need to inject the constant `ctHelperEvents` and listen to `ctHelperEvents.CHANGE_STATUS`.
            * This event must contain an object with the mandatory params `module_identifier`, `status_from` and `status_to`,
            * and the optinal param `count` (whicht defaults to 1)
            */
           CHANGE_STATUS: 'ctHelperEvents.CHANGE_STATUS'
       });
