angular.ctModule('ct.directives.clickalytics', ['ct.services.helpers'])
       /**
        * @ngdoc directive
        * @name ct.directives.directive:selecty
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Wrap markup to make a styleable SelectField
        *
        * @example
        <doc:example module="ct.directives.selecty">
        <doc:source>
        <script>
        angular.module('ct.directives.selecty').controller('testCtrl', ["$scope", function($scope) {
        $scope.text = 'Copy me';
    }]);
        </script>
        <div ng-controller="testCtrl">
        <input class="form-control" ng-model="text" data-copy-field>
        </div>
        </doc:source>
        </doc:example>
        */
       .directive('clickalytics', [
           "$parse", "CTServiceHelper", function($parse, CTServiceHelper) {

               var _copyField = {
                   // Restrict the Directive to attributes
                   restrict: 'A'
               };

               //Create the linking function
               _copyField.link = function($scope, $element, $attrs) {
                   var analyticsData = $parse($attrs.clickalytics), sendAction;

                   sendAction = function() {
                       CTServiceHelper.addGaq(analyticsData($scope));
                   };

                   $element.on("click", sendAction);
               };

               return _copyField;
           }
       ]);
