var CTCustomer;
angular.ctModule('ct.services.customers', ['ui.bootstrap', 'ct.constant', 'ct.services.authentication'])

       /**
        * @ngdoc service
        * @name ct.services.authentication.service:CTServiceCustomers
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Manage available Customers
        */
       .service('CTServiceCustomers', [
           "$modal",
           "$timeout",
           "CTConfig",
           "CTServiceHelper",
           "$q",
           "$http",
           "CTServiceAdminAuthentication",
           function($modal, $timeout, CTConfig, CTServiceHelper, $q, $http, CTServiceAdminAuthentication) {

               var
                   /** @access private */
                   ctServiceCustomers      = this,

                   /** @access private */
                   _customerDeferred       = false,

                   /** @access private */
                   _selectCustomerDeferred = false,

                   /** @access private */
                   _loadedCustomers        = [],

                   /** @access private */
                   _selectedCustomer       = false
               ;

               /**
                * Controller function for login dialog
                *
                * @param {object} $scope
                * @param {object} $modalInstance
                */
               var customerSelectDialogController = [
                   "$scope", "$modalInstance", "authData", function($scope, $modalInstance, authData) {

                       $scope.APICall   = true;
                       $scope.customers = [];

                       ctServiceCustomers.loadCustomers(authData.email, authData.password_hash)
                                         .then(function(customers) {
                                             $scope.APICall   = false;
                                             $scope.customers = customers;

                                             if($scope.customers.length == 1) {
                                                 $modalInstance.close($scope.customers[0]);
                                             }

                                         }, $modalInstance.dismiss);

                       $scope.selectCustomer = function(customer) {
                           $modalInstance.close(customer);
                       };
                   }
               ];

               /**
                * Load Customer from API
                * Each combination of Email and Password hash will be loaded and saved separat to ensure no overriding of customers
                *
                * @param email
                * @param password_hash
                *
                * @return {promise}
                */
               function getCustomers(email, password_hash) {

                   if(!_customerDeferred) {
                       _customerDeferred = $q.defer();
                       _customerDeferred.promise.then(angular.noop, function() {
                           _customerDeferred = false;
                       });

                       $http({
                           method: 'GET',
                           url   : CTConfig.HOST + '/api/v1/customers',
                           params: {
                               email        : email,
                               password_hash: password_hash
                           }
                       }).then(function(response) {

                           if(response.data.customers.length) {
                               getCustomersSuccess(response.data.customers);
                           }
                           else {
                               getCustomersFailed();
                           }

                       }, getCustomersFailed);
                   }

                   return _customerDeferred.promise;
               }

               function getCustomersSuccess(customers) {
                   angular.forEach(customers, function(customer) {
                       _loadedCustomers.push(new CTCustomer(customer));
                   });

                   _customerDeferred.resolve(_loadedCustomers);
               }

               function getCustomersFailed() {
                   _customerDeferred.reject();
               }

               /**
                * Prepare Service
                *
                * @returns {object}
                */
               function init() {

                   CTServiceAdminAuthentication.isLoggedIn()
                                               .then(function() {
                                                   getCustomers(CTServiceAdminAuthentication.getCurrentUser().person.email, CTServiceAdminAuthentication.getCurrentUser().password_hash)
                                                       .then(function() {
                                                           angular.forEach(_loadedCustomers, function(customer) {
                                                               if(customer.identifier === CTConfig.CUSTOMER_IDENTIFIER) {
                                                                   _selectedCustomer = customer;
                                                               }
                                                           });
                                                       })
                                               });


                   return ctServiceCustomers;
               }

               /**
                * @ngdoc function
                * @name CTServiceCustomers.service#resetCustomers
                * @methodOf ct.services.authentication.service:CTServiceCustomers
                *
                * @description
                * Reset the currently loaded customers
                */
               ctServiceCustomers.resetCustomers = function() {
                   _loadedCustomers  = [];
                   _customerDeferred = false;
               };


               /**
                * @ngdoc function
                * @name CTServiceCustomers.service#loadCustomers
                * @methodOf ct.services.authentication.service:CTServiceCustomers
                *
                * @description
                * Load Customers for the given email and password_hash
                * Previously loaded Customers will be deleted
                *
                * @param {string} email The email to use for loading customers
                * @param {string} password_hash The password_hash to use for loading customers
                *
                * @return {promise} Will resolve with the loaded customers
                */
               ctServiceCustomers.loadCustomers = function(email, password_hash) {
                   //            ctServiceCustomers.resetCustomers();
                   return getCustomers(email, password_hash);
               };


               /**
                * @ngdoc function
                * @name CTServiceCustomers.service#getCurrentCustomers
                * @methodOf ct.services.authentication.service:CTServiceCustomers
                *
                * @description
                * get currently loaded Customers
                *
                * @return {array} All loaded customers
                */
               ctServiceCustomers.getCurrentCustomers = function() {
                   return _loadedCustomers;
               };


               /**
                * @ngdoc function
                * @name CTServiceCustomers.service#selectCustomer
                * @methodOf ct.services.authentication.service:CTServiceCustomers
                *
                * @description
                * Opens a dialog to select a `CTCustomer`. If only one `CTCustomer` is available this `CTCustomer` will be returned without a dialog. If no `CTCustomer` is available `false` will be returned
                *
                * @return {promise} will resolve with the selected `CTCustomer`
                */
               ctServiceCustomers.selectCustomer = function(email, password_hash) {
                   if(!_selectCustomerDeferred) {
                       _selectCustomerDeferred = $modal.open({
                           templateUrl: 'ct.services.customerSelectDialog.html',
                           //windowTemplateUrl: 'ct.modals.boxModal.html',
                           controller : customerSelectDialogController,
                           resolve    : {
                               authData: function() {
                                   return {email: email, password_hash: password_hash};
                               }
                           }
                       });

                       _selectCustomerDeferred.result["finally"](function() {
                           _selectCustomerDeferred = false;
                       });

                       _selectCustomerDeferred.result.then(function(customer) {
                           _selectedCustomer = customer;
                       }, function() {
                           _selectedCustomer = false;
                           ctServiceCustomers.resetCustomers();
                       })
                   }

                   return _selectCustomerDeferred.result;
               };


               /**
                * @ngdoc function
                * @name CTServiceCustomers.service#getSelectedCustomer
                * @methodOf ct.services.authentication.service:CTServiceCustomers
                *
                * @description
                * Return the currently selected `CTCustomer`
                *
                * @return {CTCustomer} the selected `CTCustomer` or false
                */
               ctServiceCustomers.getSelectedCustomer = function() {
                   return _selectedCustomer;
               };


               return init();
           }
       ])

       /**
        * Customer Prototype
        * CTCustomer
        */
       .run([
           function() {
               CTCustomer = function(customerData) {
                   var _ctCustomer = this;


                   /**
                    * Create a new CTCustomer
                    *
                    * @param _customerData
                    * @return {CTCustomer}
                    * @private
                    */
                   function __constructor(_customerData) {
                       // Delete ID
                       if(_customerData.id) delete _customerData.id;

                       // Extend ctCustomer with default data and the given customer information
                       angular.extend(_ctCustomer, {
                           identifier       : false,
                           active           : false,
                           customer_features: [],
                           modules          : [],
                           name             : ''
                       });

                       _prepareData(_customerData);
                       angular.extend(_ctCustomer, _customerData);

                       return _ctCustomer;
                   }


                   /**
                    * Prepare the given CustomerData
                    *
                    * @param _customerData
                    * @private
                    */
                   function _prepareData(_customerData) {
                       angular.forEach(_customerData.customer_features, function(feature) {
                           if(feature.customer_id) delete feature.customer_id;
                           if(feature.id) delete feature.id;
                       });

                       angular.forEach(_customerData.modules, function(module) {
                           if(module.customer_id) delete module.customer_id;
                           if(module.id) delete module.id;

                           if(module.created_at) module.created_at = CT_string2date(module.created_at);
                           if(module.updated_at) module.updated_at = CT_string2date(module.updated_at);
                       });
                   }

                   /**
                    * Return all available modules
                    *
                    * @return {array} An array of modules
                    */
                   _ctCustomer.getModules = function() {
                       return _ctCustomer.modules;
                   };


                   /**
                    * Return a specific modules
                    *
                    * @param {string} identifier The identifier of the module to load
                    *
                    * @return {object} The desired module
                    */
                   _ctCustomer.getModule = function(identifier) {
                       var returnModule = false;
                       angular.forEach(_ctCustomer.getModules(), function(module) {
                           if(module.identifier === identifier) {
                               returnModule = module;
                           }
                       });

                       return returnModule;
                   };

                   /**
                    * Returns all features
                    *
                    * @return {array} An array of features
                    */
                   _ctCustomer.getFeatures = function() {
                       return _ctCustomer.customer_features;
                   };


                   /**
                    * Check if this customer has a specific feature
                    *
                    * @param {string} key The key of the feature
                    *
                    * @return {boolean} Will return true if the feature is set and enabled
                    */
                   _ctCustomer.hasFeature = function(key) {
                       var hasFeature = false;
                       angular.forEach(_ctCustomer.getFeatures(), function(feature) {
                           if(feature.key === key) hasFeature = parseInt(feature.value, 10) > 0;
                       });

                       return hasFeature;
                   };

                   /**
                    * Return the module config value for the given key
                    *
                    * @param module_identifier
                    * @param key {string} The name of the config key
                    *
                    * @returns {*}
                    */
                   _ctCustomer.getModuleConfig = function(module_identifier, key) {
                       var returnConfigFlagValue = false;

                       var module = _ctCustomer.getModule(module_identifier);
                       if(module === false) {
                           return false;
                       }

                       angular.forEach(module.module_configurations, function(configValue, configKey) {
                           if(configKey == key) {
                               returnConfigFlagValue = configValue;
                           }
                       });

                       return returnConfigFlagValue;
                   };

                   return __constructor(customerData);
               }
           }
       ]);
