/* jshint ignore:start */
function utf8_encode(a) {
    if(null === a || "undefined" == typeof a) return "";
    var d, e, b = a + "", c = "", f = 0;
    d = e = 0, f = b.length;
    for(var g = 0; f > g; g++) {
        var h = b.charCodeAt(g), i = null;
        if(128 > h) {
            e++;
        }
        else if(h > 127 && 2048 > h) {
            i = String.fromCharCode(192 | h >> 6, 128 | 63 & h);
        }
        else if(55296 != (63488 & h)) {
            i = String.fromCharCode(224 | h >> 12, 128 | 63 & h >> 6, 128 | 63 & h);
        }
        else {
            if(55296 != (64512 & h)) throw new RangeError("Unmatched trail surrogate at " + g);
            var j = b.charCodeAt(++g);
            if(56320 != (64512 & j)) throw new RangeError("Unmatched lead surrogate at " + (g - 1));
            h = ((1023 & h) << 10) + (1023 & j) + 65536, i = String.fromCharCode(240 | h >> 18, 128 | 63 & h >> 12, 128 | 63 & h >> 6, 128 | 63 & h)
        }
        null !== i && (e > d && (c += b.slice(d, e)), c += i, d = e = g + 1)
    }
    return e > d && (c += b.slice(d, f)), c
}

/* jshint ignore:end */
