angular.ctModule('ct.directives.fixedBar', [])

       /**
        * @ngdoc directive
        * @name ct.directives.directive:fixedBar
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Set the given element fixed when it reaches the configured fixed elements bottom
        *
        * - attributes
        *   - fixedClass {string}
        *     passes the Class, that will be set on the Container, when fixed
        *
        *   - onstatechange {expression}
        *     Expression to evalute when the state of the directive changes. (Available variables: `fixed {Bool}`)
        *
        */
       .directive('fixedBar', [
           "$window", "$parse", function($window, $parse) {

               var _fixedBarDirective = {};

               _fixedBarDirective.link = function($scope, $element, $attrs) {


                   // Get the DOM-Element of the defined offset sibling
                   var $offsetSibling = function() {

                       /**
                        * If no reference is set - the element should always bind to window top
                        * We return an object that "fakes" a dom element with position-top 0 and height 0
                        */
                       if(!$attrs.fixedBar) {
                           return {
                               height  : function() {
                                   return 0;
                               },
                               position: function() {
                                   return {top: 0}
                               },
                               css     : function() {
                                   return 'fixed'
                               }
                           }
                       }
                       else {
                           return angular.element($attrs.fixedBar);
                       }
                   }();

                   /**
                    * The default Fixed Class
                    * @type {string}
                    */
                   var defaultFixedClass = 'fixed';

                   // The top position of the fixed element
                   var offsetTop = 0;

                   var $preserveLayout = angular.element('<div></div>');

                   var elementIsFixed = false;

                   //& Handle the window scroll event
                   angular.element($window).on('scroll', function() {
                       // get the windows scroll position
                       var scrollTop = $window.scrollY;

                       var offsetSiblingIsFixed = $offsetSibling.css('position') == 'fixed';

                       // get the current absolute position of the offset sibling relativ to the window
                       var offsetSiblingTop    = offsetSiblingIsFixed ? $offsetSibling.position().top : $offsetSibling.position().top - scrollTop,

                           // get the current height of the offset sibling
                           offsetSiblingHeight = $offsetSibling.height();

                       offsetTop = offsetSiblingTop + offsetSiblingHeight;

                       // the position of when the element should be fixed
                       var fixedTrigger = (scrollTop + offsetTop);

                       // get the elements offset top
                       var currentElementTop = elementIsFixed ? $preserveLayout.offset().top : $element.offset().top;

                       if(currentElementTop <= fixedTrigger && !elementIsFixed) {
                           setElementFixed();
                       }
                       else if(currentElementTop > fixedTrigger && elementIsFixed) {
                           setElementUnfixed();
                       }
                   });


                   /**
                    * Set the element fixed
                    */
                   function setElementFixed() {
                       //Add CSS
                       $element.css({position: 'fixed', top: offsetTop, left: 0, right: 0});

                       // show element the preserve the layout
                       $preserveLayout.insertBefore($element);
                       $preserveLayout.height($element.height());

                       // add Fixed Class
                       toggleFixedClass(true);

                       elementIsFixed = true;
                       stateChangeCallback();
                   }

                   /**
                    * Unset the element fixed
                    */
                   function setElementUnfixed() {
                       //remove css
                       $element.css({position: '', top: '', left: '', right: ''});

                       //hide the preserve element
                       $preserveLayout.detach();

                       // remove Fixed Class
                       toggleFixedClass(false);

                       elementIsFixed = false;
                       stateChangeCallback();
                   }

                   /**
                    * Add/Removes a fixed Class on the Element
                    *
                    * @param toggle {boolean} if true, it sets the class else removes it
                    */
                   function toggleFixedClass(toggle) {
                       /**
                        * If we have a custom Fixed Class in our Options, we set it, else
                        * we set the standard class 'fixed'
                        */
                       if(typeof $attrs.fixedClass !== 'undefined') {
                           var className = $attrs.fixedClass === '' ? defaultFixedClass : $attrs.fixedClass;
                           $element.toggleClass(className, toggle)
                       }
                       else {
                           $element.toggleClass(defaultFixedClass, toggle)
                       }
                   }


                   /**
                    * Execute the state change expression
                    */
                   function stateChangeCallback() {
                       if($attrs.hasOwnProperty('onstatechange') && $attrs.onstatechange != '') {
                           $parse($attrs.onstatechange)($scope, {fixed: elementIsFixed});
                       }
                   }
               };

               return _fixedBarDirective;
           }
       ]);


