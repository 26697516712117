angular.ctModule('ct.directives.shareWindow', ['ct.constant'])

       .constant('ctShareWindowDefaults', {
           width : 500,
           height: 375,
           posx  : 'center',
           posy  : 'center'
       })

       /**
        * @ngdoc directive
        * @name ct.directives.directive:shareWindow
        * @restrict C
        *
        * @element A
        *
        * @scope
        *
        * @todo Calculate Window Position
        * @todo Do not create a new scope - Better load the ``href`` from the $attr
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Automatically open a link in a popup window
        *
        * @example
        <doc:example module="ct.directives.shareWindow">
        <doc:source>
        <div>
        <a href="https://twitter.com/intent/tweet?url=http://example.com" class="share-window btn btn-info btn-small">Share on Twitter</a>
        <a href="https://facebook.com/share.php?u=http://example.com" class="share-window btn btn-info btn-small">Share on Facebook</a>
        </div>
        </doc:source>
        </doc:example>
        */
       .directive('shareWindow', [
           "ctShareWindowDefaults", function(ctShareWindowDefaults) {
               var shareWindowDirective = {};

               /** @property {string} limit directive to class definition */
               shareWindowDirective.restrict = 'AC';

               shareWindowDirective.scope = {
                   href: '@'
               };

               shareWindowDirective.link = function($scope, $element) {
                   $element.on('click', function($event) {
                       shareWindowDirectiveClickHandler($event, $scope);
                   });
               };

               function shareWindowDirectiveClickHandler($event, $scope) {
                   $event.preventDefault();

                   var windowOptions = [];

                   windowOptions.push('width=' + ctShareWindowDefaults.width.toString());
                   windowOptions.push('height=' + ctShareWindowDefaults.height.toString());

                   windowOptions.push('top=' + 0);
                   windowOptions.push('left=' + 0);

                   windowOptions.push('scrollbars=yes');

                   var shareWindow = window.open($scope.href, 'Window', windowOptions.join(','));
                   shareWindow.focus();
               }

               return shareWindowDirective;
           }
       ])

       .directive('shareFb', [
           "CTConfig", function(CTConfig) {
               var shareWindowDirective = {};

               /** @property {string} limit directive to class definition */
               shareWindowDirective.restrict = 'AC';

               shareWindowDirective.link = function($scope, $element, $attrs) {
                   $element.on('click', function($event) {
                       if(typeof FB !== 'undefined') {
                           $event.preventDefault();
                           FB.ui({
                               method: 'share',
                               href  : $attrs.shareFb
                           });
                       }
                   });

                   function getFBId() {
                       return (CTConfig.FB_APP_ID || CTConfig.FB_ID || CTConfig.FACEBOOK_APP_ID || CTConfig.FACEBOOK_ID || '');
                   }

                   var fbLink = 'https://www.facebook.com/dialog/share?app_id=%FB_ID%&display=popup&href=%URL%';
                   $element.attr('href', fbLink.replace('%FB_ID%', getFBId())
                                               .replace('%URL%', encodeURIComponent(location.href) /*encodeURIComponent($attrs.shareFb)*/));
                   console.log(fbLink.replace('%FB_ID%', getFBId())
                                     .replace('%URL%', encodeURIComponent($attrs.shareFb)));
               };

               return shareWindowDirective;
           }
       ]);
