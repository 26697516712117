angular.ctModule('ct.directives.resourceState', [])
       .value('resourceStateMessages', {
           'story'     : {
               'new'    : 'Dieser Beitrag muss noch freigegeben werden.',
               'active' : 'Dieser Beitrag aktiv.',
               'blocked': 'Dieser Beitrag ist geperrt.'
           },
           'commitment': {
               'new'    : 'Dieser Eintrag muss noch freigegeben werden.',
               'active' : 'Dieser Eintrag aktiv.',
               'blocked': 'Dieser Eintrag ist geperrt.'
           },
           'event'     : {
               'new'    : 'Dieses Event muss noch freigegeben werden.',
               'active' : 'Dieses Event aktiv.',
               'blocked': 'Dieses Event ist geperrt.'
           },
           'default'   : {
               'new'    : 'Dieser Beitrag muss noch freigegeben werden.',
               'active' : 'Dieser Beitrag aktiv.',
               'blocked': 'Dieser Beitrag ist geperrt.'
           }
       })
       /**
        * @ngdoc directive
        * @name ct.directives.directive:resourceState
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Create a subelement that outputs a message depending on the given state
        *
        * Supported States:
        *  - 0: New - The resource needs to be moderated and is only visible to an administrator
        *  - 1: Active - The resource is visible for everyone
        *  - 2: Blocked - The resource is blocked and only visible to an administrator
        *
        * Optional Attribute `Type`
        * By setting the attribute `resource-state-type` you can differ between the messages.
        *  - story
        *  - commitment
        *  - event
        *
        * @example
        <doc:example module="ct.directives.resourceState">
        <doc:source>
        <script>
        angular.module('ct.directives.resourceState').controller('testCtrl', ["$scope", function($scope) {
        $scope.resource = {state: 0};
        $scope.setState = function(state) {
            $scope.resource.state = state
        }
    }]);
        </script>
        <div ng-controller="testCtrl">
        <h2>State: {{ resource.state }}</h2>
        <ul class="list-inline">
        <li><button type="button" data-ng-click="setState(0)">State 0</button></li>
        <li><button type="button" data-ng-click="setState(1)">State 1</button></li>
        <li><button type="button" data-ng-click="setState(2)">State 2</button></li>
        </ul>
        <div data-resource-state="resource.state"></div>
        <div data-resource-state="resource.state" data-resource-state-type="story"></div>
        <div data-resource-state="resource.state" data-resource-state-type="commitment"></div>
        <div data-resource-state="resource.state" data-resource-state-type="event"></div>
        </div>
        </doc:source>
        </doc:example>
        */
       .directive('resourceState', [
           "$compile", "$parse", "resourceStateMessages", function($compile, $parse, resourceStateMessages) {

               var _resourceState       = {},
                   _resourceStateMarkup = '<div class="ct-resource-state" data-ng-class="resourceState.classes()"><div class="ct-resource-state__message">{{ resourceState.message() }}</div></div>';


               _resourceState.link = function($scope, $element, $attr) {

                   var
                       // Create a new scope
                       $resourceStateScope = $scope.$new(),

                       // Create a shortlink to the current state. __Call as a function with $scope as parameter__
                       currentState        = $parse($attr.resourceState),

                       // Create a variable that will hold the element
                       resourceStateElement;

                   // Setup the new scope
                   $resourceStateScope.resourceState = {
                       // Return active classes depending on the current state
                       classes: function() {
                           var state = currentState($scope);
                           return {
                               'ct-resource-state--new'    : (state == 0),
                               'ct-resource-state--active' : (state == 1),
                               'ct-resource-state--blocked': (state == 2)
                           }
                       },

                       // Return the message string depending on the resource type and current state
                       message: function() {
                           var type  = $attr.resourceStateType || 'default',
                               state = currentState($scope);

                           switch(state) {
                               case 0:
                                   return resourceStateMessages[type]["new"];
                               case 1:
                                   return resourceStateMessages[type].active;
                               case 2:
                                   return resourceStateMessages[type].blocked;
                           }
                       }
                   };

                   // Create the message element
                   resourceStateElement = angular.element(_resourceStateMarkup);
                   // Append the message element
                   $element.append(resourceStateElement);

                   // Compile the message element
                   $compile(resourceStateElement)($resourceStateScope);
               };

               return _resourceState;
           }
       ]);
