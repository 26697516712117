(function(bindTo, named) {

    var globalDebug = {
        debug: false,
        log  : function(type, arg1, arg2, arg3, arg4, arg5) {
            if(!globalDebug.debug) return;

            switch(type) {
                case 'log':
                    if(arg5) {
                        console.log(arg1, arg2, arg3, arg4, arg5);
                    }
                    else if(arg4) {
                        console.log(arg1, arg2, arg3, arg4);
                    }
                    else if(arg3) {
                        console.log(arg1, arg2, arg3);
                    }
                    else if(arg2) {
                        console.log(arg1, arg2);
                    }
                    else {
                        console.log(arg1);
                    }
                    break;
                case 'info':
                    if(arg5) {
                        console.info(arg1, arg2, arg3, arg4, arg5);
                    }
                    else if(arg4) {
                        console.info(arg1, arg2, arg3, arg4);
                    }
                    else if(arg3) {
                        console.info(arg1, arg2, arg3);
                    }
                    else if(arg2) {
                        console.info(arg1, arg2);
                    }
                    else {
                        console.info(arg1);
                    }
                    break;
                case 'debug':
                    if(arg5) {
                        console.debug(arg1, arg2, arg3, arg4, arg5);
                    }
                    else if(arg4) {
                        console.debug(arg1, arg2, arg3, arg4);
                    }
                    else if(arg3) {
                        console.debug(arg1, arg2, arg3);
                    }
                    else if(arg2) {
                        console.debug(arg1, arg2);
                    }
                    else {
                        console.debug(arg1);
                    }
                    break;
                case 'warn':
                    if(arg5) {
                        console.warn(arg1, arg2, arg3, arg4, arg5);
                    }
                    else if(arg4) {
                        console.warn(arg1, arg2, arg3, arg4);
                    }
                    else if(arg3) {
                        console.warn(arg1, arg2, arg3);
                    }
                    else if(arg2) {
                        console.warn(arg1, arg2);
                    }
                    else {
                        console.warn(arg1);
                    }
                    break;
                case 'error':
                    if(arg5) {
                        console.error(arg1, arg2, arg3, arg4, arg5);
                    }
                    else if(arg4) {
                        console.error(arg1, arg2, arg3, arg4);
                    }
                    else if(arg3) {
                        console.error(arg1, arg2, arg3);
                    }
                    else if(arg2) {
                        console.error(arg1, arg2);
                    }
                    else {
                        console.error(arg1);
                    }
                    break;
            }
        }
    };

    bindTo[named] = globalDebug;

}(window, 'globalDebug'));
