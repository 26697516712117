var CTEvent;
angular.ctModule('ct.services.events', [
    'ct.filters.truncate',
    'ct.services.ctGetCoordinates',
    'ct.services.authentication',
    'ct.constant',
    'ct.services.data',
    'ct.filters.parseLinks',
])
    .run([
        '$filter',
        '$templateCache',
        '$compile',
        '$location',
        'CTConfig',
        '$rootScope',
        'CTServiceEventCategories',
        'CTGetCoordinates',
        'CTServiceAuthentication',
        'CTServiceHelper',
        function($filter,
            $templateCache,
            $compile,
            $location,
            CTConfig,
            $rootScope,
            CTServiceEventCategories,
            CTGetCoordinates,
            CTServiceAuthentication,
            CTServiceHelper
        ) {

            /**
             * Creates a new Event
             *
             * @constructor
             */

            CTEvent = function(eventData, fullEvent) {
                /**
                 * Reference to itself for easier access to functions and variables
                 * @type {CTEvent}
                 */
                var ctEvent = this;


                /**
                 * Initializes a new CTEvent
                 *
                 * @param {object} newEventData
                 * @param {boolean} fullEvent
                 * @private
                 */
                function __constructor(newEventData, fullEvent) {

                    /** Prepare the event data */
                    _prepareEventData(newEventData);

                    /** Set if the event is fully loaded */
                    newEventData.fullyLoaded = !!fullEvent;

                    /** Extend CTEvents with data */
                    var person = angular.extend({}, (newEventData.host_object || {}).person || {}),
                        host_object = angular.extend({}, newEventData.host_object || {}, { person: person });
                    angular.extend(ctEvent, newEventData, { host_object: host_object });

                    return ctEvent;
                }

                /**
                 * Prepare the event data for use as CTEvent
                 *
                 * @param {object} event
                 * @private
                 */
                function _prepareEventData(event) {

                    /** Check if a host content is set */
                    if (event.host_contact) {
                        event.host_contact_filtered = $filter('parseLinks')('' + event.host_contact);
                    }

                    /** Check if the Event has an image */
                    if (typeof event.image !== 'undefined' && event.image && typeof event.image.sm !== 'undefined') {
                        event.teaser_image = event.image.sm;
                    }

                    event.cancelled = parseInt(event.cancelled, 10);
                    event.cancelled = !(event.cancelled == 0 || isNaN(event.cancelled));

                    event.csv_download_url = CTConfig.HOST +
                                             '/api/v1/pages/' +
                                             CTConfig.PAGE_ID +
                                             '/events/' +
                                             event.id +
                                             '/participants?output=csv&user_id=' +
                                             $CB_API.getConfig().USER_IDENTIFIER +
                                             '&user_nonce=' +
                                             $CB_API.getConfig().USER_NONCE;

                    /** Create a JS Date out of a DateString ('1992-07-28 11:26:00') */
                    if (event.starts_at && !(event.starts_at instanceof Date)) {
                        event.starts_at = CT_string2date(event.starts_at);
                    }
                    else if (!event.starts_at) {
                        event.starts_at = new Date();
                    }
                    if (event.ends_at && !(event.ends_at instanceof Date)) {
                        event.ends_at = CT_string2date(event.ends_at);
                    }
                    else if (!event.ends_at) {
                        event.ends_at = new Date();
                    }

                    event.starts_at_date = new Date(event.starts_at);
                    event.starts_at_time = new Date(event.starts_at);

                    event.ends_at_date = new Date(event.ends_at);
                    event.ends_at_time = new Date(event.ends_at);

                    if (event.ends_at instanceof Date) {
                        event.ends_at_milliseconds = event.ends_at.getTime();
                    }
                    else {
                        event.ends_at_milliseconds = 0;
                    }

                    event.total_followers = event.total_followers || 0;


                    /** calculate today and tomorrow flags */
                    var startDate = new Date(event.starts_at),
                        endDate = new Date(event.ends_at),

                        todayDate = new Date(),
                        tomorrowDate = new Date();

                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(23, 59, 59, 999);

                    todayDate.setHours(0, 0, 0, 0);

                    tomorrowDate.setHours(0, 0, 0, 0);
                    tomorrowDate.setDate(tomorrowDate.getDate() + 1);


                    /* Set "isToday" Flag */
                    event.isToday = (startDate <= todayDate && endDate >= todayDate);

                    /* Set "isTomorow" Flag */
                    event.isTomorrow = (startDate == tomorrowDate);


                    /** Preset event texts */
                    event.teaser_texts = {};
                    event.texts = {};

                    event.teaser_texts.event_date_title_text = $filter('date')(event.starts_at, 'EEEE, d. MMMM');
                    if ((new Date(startDate).setHours(0, 0, 0, 0)) == (new Date(endDate).setHours(0, 0, 0, 0))) {
                        event.single_day_event = true;
                        event.teaser_texts.event_date_detailed_text = $filter('date')(
                            event.starts_at,
                            'EEEE, d.MM. HH:mm'
                        ) + ' - ' + $filter('date')(event.ends_at, 'HH:mm');
                        event.texts.event_date_detailed_text = event.teaser_texts.event_date_detailed_text;

                    }
                    else {
                        event.single_day_event = false;
                        event.teaser_texts.event_date_detailed_text = $filter('date')(
                            event.starts_at,
                            'EEE, d.MM. HH:mm'
                        ) + ' - ' + $filter('date')(event.ends_at, 'EEE, d.MM. HH:mm');
                        event.texts.event_date_detailed_text = event.teaser_texts.event_date_detailed_text;

                        event.teaser_texts.start_time = $filter('date')(event.starts_at, 'dd. MMMM yyyy HH:mm') +
                                                        ' Uhr -';
                        event.teaser_texts.end_time = $filter('date')(event.ends_at, 'dd. MMMM yyyy HH:mm') + ' Uhr';

                    }


                    /** Set Location teaser */
                    if (event.location_name && event.addresses) {
                        event.teaser_texts.event_location_name = event.location_name +
                                                                 ', ' +
                                                                 event.addresses[0].zip_code +
                                                                 ' ' +
                                                                 event.addresses[0].city;
                    }
                    else if (event.addresses && event.addresses.length > 0) {
                        event.teaser_texts.event_location_name = event.addresses[0].zip_code +
                                                                 ' ' +
                                                                 event.addresses[0].city;
                    }

                    /** Add geo data */
                    event.map = {};
                    try {

                        /** Add GeoCoordinates */
                        event.map.latlng = new mapboxgl.LngLat(parseFloat(event.addresses[0].lng),
                            parseFloat(event.addresses[0].lat)
                        );
                        /** set marker for the category if it exists */
                        if (event.event_category.image !== null) {
                            // create a DOM element for the marker
                            var el = document.createElement('div');
                            el.className = 'marker';
                            el.style.backgroundImage = 'url(' + event.event_category.image.original + ')';
                            el.style.width = '40px';
                            el.style.height = '40px';
                            el.style.backgroundSize = '100%';
                            event.map.marker = new mapboxgl.Marker(el)
                                .setLngLat(event.map.latlng);
                        }
                        else {
                            /** Add a marker */
                            event.map.marker = new mapboxgl.Marker()
                                .setLngLat(event.map.latlng);
                        }

                    } catch (e) {

                        /** If google not loaded */
                        event.map.latlng = false;
                        event.map.marker = false;
                    }

                    if (event.addresses && event.addresses.length) {
                        // load coordinates via geocoder if not set yet
                        if (!event.addresses[0].lat || !event.addresses[0].lng) {
                            CTGetCoordinates.byAddress(event.addresses[0].street,
                                event.addresses[0].zip_code,
                                event.addresses[0].city
                            )
                                .then(function(coordinates) {
                                    event.addresses[0].lat = coordinates.lat;
                                    event.addresses[0].lng = coordinates.lng;
                                });
                        }
                    }

                    /** share url */
                    event.share_url = CTConfig.HOST.replace(/^\/\//,
                        (location.protocol == 'https' ? 'https://' : 'http://')
                                      ) +
                                      '/api/v1/pages/' +
                                      CTConfig.PAGE_ID +
                                      '/events/' +
                                      event.id +
                                      '/share?redirect_url=' +
                                      encodeURIComponent(location.href.replace(location.hash, ''));
                    event.share_url_encoded = encodeURIComponent(event.share_url);

                    /** invite url **/
                    event.invite_url = (window.location.protocol + '//' + window.location.hostname) +
                                       location.pathname +
                                       '?invitation_hash=' +
                                       event.invitation_hash +
                                       '&user_identifier=' +
                                       CTServiceAuthentication.getCurrentUser().identifier +
                                       '#/events/' +
                                       event.id;
                    event.invite_url_encoded = encodeURIComponent(event.invite_url);


                    if (event.event_category) {
                        CTServiceEventCategories.getCategory((event.page_id || CTConfig.PAGE_ID),
                            event.event_category.id
                        )
                            .then(function(category) {
                                event.event_category = category;
                            });


                        //TODO das sollte eigentlich eh im oberen block beim prepare der getCategory passieren (aber die änderungen sind zu langsam für den scope des event-detail controllers) Was tun?
                        // Unify flags for category
                        event.event_category.participant_requires_address = !(event.event_category.participant_requires_address ==
                                                                              0 ||
                                                                              event.event_category.participant_requires_address ==
                                                                              '0');
                        event.event_category.participant_requires_name = !(event.event_category.participant_requires_name ==
                                                                           0 ||
                                                                           event.event_category.participant_requires_name ==
                                                                           '0');
                        event.event_category.participant_requires_phone = !(event.event_category.participant_requires_phone ==
                                                                            0 ||
                                                                            event.event_category.participant_requires_phone ==
                                                                            '0');
                        event.event_category.participant_requires_zip = !(event.event_category.participant_requires_zip ==
                                                                          0 ||
                                                                          event.event_category.participant_requires_zip ==
                                                                          '0');
                    }
                    event.blog_entries = event.blog_entries || [];

                    event.isInModeration = (event.state == 0);
                }


                /**
                 * Get the raw data from this CTEvent for API Calls
                 *
                 * @private
                 */
                function _getRawEventData() {
                    var rawData = {};

                    rawData.name = ctEvent.name;
                    rawData.description = ctEvent.description;

                    rawData.image_id = ctEvent.image_id;

                    rawData.starts_at = CT_date2string(ctEvent.starts_at);
                    rawData.ends_at = CT_date2string(ctEvent.ends_at);

                    rawData.notify_on_participation_email = ctEvent.notify_on_participation_email || false;

                    rawData.cancelled = ctEvent.cancelled;

                    rawData.event_category_id = ctEvent.event_category_id;

                    rawData.rsvp_acceptance_level = ctEvent.rsvp_acceptance_level;
                    rawData.event_visibility_level = ctEvent.event_visibility_level;
                    rawData.invitation_level = ctEvent.invitation_level;
                    rawData.participant_visibility_level = ctEvent.participant_visibility_level;

                    rawData.location_name = ctEvent.location_name;
                    rawData.location_directions = ctEvent.location_directions;

                    rawData.custom_field_label = ctEvent.custom_field_label;
                    rawData.max_friends = ctEvent.max_friends;

                    rawData.address = {};
                    rawData.address.street = ctEvent.addresses[0].street;
                    rawData.address.city = ctEvent.addresses[0].city;
                    rawData.address.zip_code = ctEvent.addresses[0].zip_code;
                    rawData.address.country = ctEvent.addresses[0].country;

                    rawData.capacities = angular.copy(ctEvent.capacities);

                    rawData.person = angular.copy(ctEvent.person);

                    // if the addresses array is set, use the first entry as address and remove addresses array
                    if (rawData.person && rawData.person.addresses && rawData.person.addresses.length > 0) {
                        rawData.person.address = rawData.person.addresses[0];
                        delete rawData.person.addresses;
                    }

                    rawData.host_alias = ctEvent.host_alias;
                    rawData.host_contact = (ctEvent.host_contact ? ctEvent.host_contact.toString() : ctEvent.host_contact);

                    rawData.official = ctEvent.official;

                    //rawData.progress = ctEvent.progress;

                    if (!ctEvent.id) {
                        rawData.capacities = ctEvent.event_capacities;
                    }

                    if (CTServiceAuthentication.hasContentEditPermission()) {
                        rawData.state = ctEvent.state;
                    }

                    return rawData;
                }


                /**
                 * Save this CTEvent
                 * Will automatically detect if it is new by checking if an `id` is set
                 */
                ctEvent.save = function() {
                    var data = _getRawEventData(),
                        config = {
                            method: 'POST',
                            url: '/pages/' + CTConfig.PAGE_ID + '/events',
                            data: data,
                        },
                        isNew = !ctEvent.id;

                    if (!isNew) {
                        config.method = 'PUT';
                        config.url += '/' + ctEvent.id;
                    }

                    ctEvent.APICall = true;

                    return $CB_API(config)
                        .done(function(data) {
                            _prepareEventData(data.event);
                            angular.extend(ctEvent, data.event);

                            if (ctEvent.isNew) {
                                delete ctEvent.isNew;
                            }
                        })
                        .always(function() {
                            ctEvent.APICall = true;
                            $rootScope.$apply(angular.noop);
                        });
                };

                /**
                 * Flag the current event
                 * @returns {promise}
                 */
                ctEvent.flag = function() {
                    return $CB_API({
                        method: 'POST',
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/flag',
                        authentication: { type: 'user' },
                        level: 'public',
                    });
                };

                /**
                 * cancel an event
                 * @param reason
                 * @returns {promise}
                 */
                ctEvent.cancel = function(reason) {
                    return $CB_API({
                        method: 'PUT',
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id,
                        authentication: { type: 'user' },
                        data: {
                            cancelled: true,
                            cancelled_reason: reason,
                        },
                    });
                };

                /**
                 * update an event state.
                 *
                 * @param state
                 * @returns {promise}
                 */
                ctEvent.updateState = function(state) {
                    ctEvent.state = state;
                    return ctEvent.save();
                };

                /**
                 * get the hosts person
                 * @returns {CTPerson}
                 */
                ctEvent.getPerson = function() {
                    return new CTPerson(ctEvent.host_object.person);
                };

                /**
                 * Update the current event with new data
                 *
                 * @param newEventData
                 */
                ctEvent.update = function(newEventData) {

                    /** Remove elements that could cause dramatic errors */
                    if (newEventData.save) {
                        delete newEventData.save;
                    }

                    if (newEventData.update) {
                        delete newEventData.update;
                    }

                    if (newEventData.loadBlogEntries) {
                        delete newEventData.loadBlogEntries;
                    }

                    if (newEventData.visibleOnMap) {
                        delete newEventData.visibleOnMap;
                    }

                    if (newEventData.notOlderThan) {
                        delete newEventData.notOlderThan;
                    }

                    if (newEventData.isInRadius) {
                        delete newEventData.isInRadius;
                    }

                    if (newEventData.isCategoryId) {
                        delete newEventData.isCategoryId;
                    }


                    _prepareEventData(newEventData);

                    /** Extend the data */
                    angular.extend(ctEvent, newEventData);
                };


                /**
                 * Add a new capacity
                 * @param capacity
                 * @returns {promise}
                 */
                ctEvent.addCapacity = function(capacity) {
                    var apiPromise = $CB_API({
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/capacities',
                        method: 'POST',
                        data: capacity,
                    });
                    apiPromise.then(function(data) {
                        ctEvent.event_capacities.push(data.event_capacity);
                    });

                    return apiPromise;
                };

                /**
                 * Update an existing capacity
                 *
                 * @param capacity
                 * @returns {promise}
                 */
                ctEvent.updateCapacity = function(capacity) {
                    return $CB_API({
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/capacities/' + capacity.id,
                        method: 'PUT',
                        data: capacity,
                    })
                        .then(function(data) {
                            angular.forEach(ctEvent.event_capacities, function(event_capacity) {
                                if (event_capacity.id == capacity.id) {
                                    angular.extend(event_capacity, data.event_capacity);
                                }
                            });
                        });
                };

                ctEvent.bulkUpdateCapacities = function(capacities) {
                    var apiPromise = $CB_API({
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/capacities',
                        method: 'PUT',
                        data: {
                            capacities: capacities,
                        },
                    });

                    apiPromise.then(function(data) {
                        // update all capacities for the event that have been successfully edited (= returned by the server)
                        angular.forEach(data.event_capacities, function(received_capacity) {
                            angular.forEach(ctEvent.event_capacities, function(event_capacity) {
                                if (event_capacity.id == received_capacity.id) {
                                    angular.extend(event_capacity, received_capacity);
                                }
                            });
                        });
                    });

                    return apiPromise;
                };

                /**
                 * Removean existing cateogry
                 *
                 * @param capacityId
                 * @returns {*}
                 */
                ctEvent.removeCapacity = function(capacityId) {
                    var apiPromise = $CB_API({
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/capacities/' + capacityId,
                        method: 'DELETE',
                    });

                    apiPromise.then(function() {
                        angular.forEach(ctEvent.event_capacities, function(event_capacity, index) {
                            if (event_capacity.id == capacityId) {
                                ctEvent.event_capacities.splice(index, 1);
                            }
                        });
                    });

                    return apiPromise;
                };


                /**
                 * Remove an existing update
                 *
                 * @param updateId
                 * @returns {promise}
                 */
                ctEvent.removeUpdate = function(updateId) {
                    return $CB_API({
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/updates/' + updateId,
                        method: 'DELETE',
                    });
                };

                /**
                 * Load updates for this event
                 */
                ctEvent.loadBlogEntries = function() {
                    ctEvent.blog_entries_loading = true;

                    $CB_API({
                        method: 'GET',
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/updates',
                        authentication: { type: 'none' },
                        params: {},
                    })
                        .done(function(data) {
                            ctEvent.blog_entries = data.blog_entries;

                            angular.forEach(ctEvent.blog_entries, function(blog_post) {

                                // create date object
                                blog_post.created_at_date_object = CT_string2date(blog_post.created_at);

                                // create the formatted date
                                blog_post.created_at_formatted = $filter('date')(
                                    blog_post.created_at_date_object,
                                    'dd.MM.yyyy HH:mm'
                                );
                            });

                        })
                        .fail(function() {
                            ctEvent.blog_entries = [];
                        })
                        .always(function() {
                            ctEvent.blog_entries_loading = false;
                            $rootScope.$apply();
                        });
                };


                /**
                 * Return if this event would be visible on the given map
                 *
                 * @param map
                 * @returns {boolean}
                 */
                ctEvent.visibleOnMap = function(map) {

                    /** If Map is not defined */
                    if (!map) {
                        return true;
                    }

                    /** If map.getBounds is not available */
                    if (!map.getBounds) {
                        return true;
                    }

                    return map.getBounds()
                        .contains(ctEvent.map.latlng);
                };


                /**
                 * Return if the event is older or equal the given date
                 *
                 * @param minDate
                 * @returns {boolean}
                 */
                ctEvent.notOlderThan = function(minDate) {
                    minDate = (minDate instanceof Date ? minDate : new Date());
                    minDate.setHours(0, 0, 0, 0);

                    return ctEvent.ends_at >= minDate;
                };


                /**
                 * Return if the event is inside a given radius
                 *
                 * @param latlng
                 * @param radius
                 * @returns {boolean}
                 */
                ctEvent.isInRadius = function(latlng, radius) {
                    var distance = 0,
                        rad = function(x) {
                            return x * Math.PI / 180;
                        };
                    try {
                        /** Formular by http://www.codecodex.com/wiki/Calculate_Distance_Between_Two_Points_on_a_Globe#JavaScript */
                        distance = function(p1, p2) {
                            var R = 6378137, // Earth’s mean radius in meter
                                dLat = rad(p2.lat() - p1.lat()),
                                dLong = rad(p2.lng() - p1.lng()),
                                a = Math.sin(dLat / 2) *
                                    Math.sin(dLat / 2) +
                                    Math.cos(rad(p1.lat())) *
                                    Math.cos(rad(p2.lat())) *
                                    Math.sin(dLong / 2) *
                                    Math.sin(dLong / 2),
                                c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                            return R * c; // Return the distance in Meters
                        }(ctEvent.map.latlng, latlng);
                    } catch (e) {
                    }


                    return (distance <= radius * 1000);
                };


                /**
                 * Return if the event has the given category by id
                 *
                 * @param categoryId
                 * @returns {boolean}
                 */
                ctEvent.isCategoryId = function(categoryId) {
                    return parseInt(ctEvent.event_category.id, 10) === parseInt(categoryId, 10);
                };


                /**
                 * Return all participants
                 *
                 * @returns {Array}
                 */
                ctEvent.calculateParticipantsArray = function() {

                    if (ctEvent.total_participants < 1) {
                        return [];
                    } //the event has no participants

                    var return_array = [];
                    angular.forEach(ctEvent.event_capacities, function(capacity) {
                        if (capacity.total_participants < 1) {
                            return;
                        } //capacity has no participants

                        angular.forEach(capacity.event_rsvps, function(rsvp) {
                            return_array.push(rsvp);
                        });

                    });

                    //                console.debug('calculateParticipantsArray', return_array);

                    return return_array;
                };


                /**
                 * Get all participant
                 *
                 * @param {object} [capacityId=undefined] If an id is given - participants will only be loaded from this capacity
                 *
                 * @returns {Array} An array of all participant person
                 */
                ctEvent.getParticipants = function(capacityId) {

                    var participants = [];

                    angular.forEach(ctEvent.event_capacities, function(capacity) {
                        if (!capacityId || capacityId != capacity.id) {
                            angular.forEach(capacity.event_rsvps, function(rsvp) {
                                participants.push(rsvp.person);
                                rsvp.person.capacity_name = capacity.name;
                            });
                        }
                    });

                    return participants;
                };

                /**
                 * Get all rsvps
                 *
                 *
                 * @param [capacity_id] If given the function will only return rsvps from this capacity
                 *
                 * @returns {Array}
                 */
                ctEvent.getRSVPS = function(capacity_id) {
                    var rsvps = [];

                    angular.forEach(ctEvent.event_rsvps, function(rsvp) {
                        rsvps.push(rsvp);
                    });

                    return rsvps;
                };

                ctEvent.duplicate = function() {
                    return $CB_API({
                        method: 'POST',
                        url: '/pages/' + CTConfig.PAGE_ID + '/events/' + ctEvent.id + '/duplicate',
                    });
                };

                ctEvent.isFull = function() {
                    var maxParticipants = 0,
                        totalParticipants = 0;

                    angular.forEach(ctEvent.event_capacities, function(capacity) {
                        var max_people = parseInt(capacity.max_people, 10),
                            total_participants = parseInt(capacity.total_participants, 10);

                        maxParticipants += isNaN(max_people) ? 0 : max_people;
                        totalParticipants += isNaN(total_participants) ? 0 : total_participants;
                    });

                    return maxParticipants <= totalParticipants;
                };


                /**
                 * isOneDayEvent
                 * @description
                 * Determines if the start and end date of this event is on the same day
                 *
                 * @returns {boolean}
                 */
                ctEvent.isOneDayEvent = function() {
                    return ((ctEvent.starts_at.getDate() == ctEvent.ends_at.getDate()) &&
                            (ctEvent.starts_at.getMonth() == ctEvent.ends_at.getMonth()) &&
                            (ctEvent.starts_at.getFullYear() == ctEvent.ends_at.getFullYear()));
                };


                /**
                 * isPastEvent
                 * @description
                 * Determine if this event happend in the past
                 *
                 * @returns {boolean}
                 */
                ctEvent.isPastEvent = function() {
                    return (new Date() > ctEvent.ends_at)
                };

                /**
                 * hasImage
                 * @description
                 * determines if the event has an image
                 *
                 * @returns {boolean}
                 */
                ctEvent.hasImage = function() {
                    return typeof ctEvent.image !== 'undefined' && ctEvent.image !== null;
                };

                /**
                 * count a facebook share button click
                 */
                ctEvent.countFacebookShare = function() {
                    CTServiceHelper.trackEvent([
                        'Events',
                        'Submit Share',
                        'Facebook'
                    ]);
                };

                /**
                 * count a twitter share button click
                 */
                ctEvent.countTwitterShare = function() {
                    CTServiceHelper.trackEvent([
                        'Events',
                        'Submit Share',
                        'Twitter'
                    ]);
                };

                /**
                 * return the current share-url
                 * @returns {*}
                 */
                ctEvent.getEncodedShareUrl = function() {
                    return ctEvent.share_url_encoded.replace(/^\/\//,
                        (location.protocol == 'https' ? 'https://' : 'http://')
                    );
                };

                /**
                 * static_map_image
                 * @description
                 * Return the URL for a static map image
                 *
                 * @param width width of the image
                 * @param height height of the image
                 * @param zoom map zoom
                 * @returns {string}
                 */
                ctEvent.static_map_image = function(width, height, zoom) {

                    zoom = zoom || 15;

                    if (ctEvent.map && ctEvent.map.latlng) {

                        let mapboxClient = mapboxSdk({ accessToken: CTConfig.MAPBOX_ACCESS_TOKEN });
                        const request = mapboxClient.static.getStaticImage({
                            ownerId: 'mapbox',
                            styleId: 'streets-v11',
                            width: width,
                            height: height,
                            position: {
                                coordinates: ctEvent.map.latlng.toArray(),
                                zoom: zoom
                            },
                            overlays: [
                                {
                                    marker: {
                                        coordinates: ctEvent.map.latlng.toArray()
                                    }
                                }
                            ]
                        });
                        return request.url();
                    }

                    return '';
                };

                var _mapPopupWindow = false;
                ctEvent.mapPopupWindow = function() {

                    if (!_mapPopupWindow) {

                        var $template = jQuery($templateCache.get('event-map-popup.html')),
                            $scope = $rootScope.$new(true);

                        $scope.event = ctEvent;
                        $template.appendTo('body');

                        setTimeout(function() {
                            $scope.$apply(function() {
                                $compile($template.contents())($scope);
                            });
                        }, 50);


                        $template.on('click', function() {
                            if (typeof CTConfig.openInNewWindow !== 'undefined' && CTConfig.openInNewWindow == true) {
                                window.open((CTConfig.eventModuleURL ? CTConfig.eventModuleURL : '/events') +
                                            '#/events/' +
                                            ctEvent.id);
                            }
                            else {
                                $rootScope.$apply(function() {
                                    $location.path('/events/' + ctEvent.id);
                                });
                            }
                        });

                        _mapPopupWindow = new mapboxgl.Popup({
                            maxWidth: 'none',
                            closeButton: false
                        }).setDOMContent($template[0]);

                    }
                    return _mapPopupWindow;
                };

                /** Construct the Event and return */
                return __constructor(eventData, fullEvent);
            };


        },
    ]);