angular.ctModule('ct.services.events').service('CTServiceEventCancel', [
    "$modal", function($modal) {
        /**
         * CTServiceEventCancel
         *
         * opened when cancelling an event. displays a modal with a 'cancel-reason' textarea
         *
         * @param {CTEvent} event The event to cancel
         */
        return function CTServiceEventCancel(event) {

            return $modal.open({
                templateUrl: 'ct.services.events.eventCancel.html',
                controller : [
                    "$scope",
                    "$modalInstance",
                    "CTServiceHelper",
                    function CTServiceEventCancelController($scope, $modalInstance, CTServiceHelper) {

                        $scope.cancelled = {
                            reason: ''
                        };

                        function init() {
                            CTServiceHelper.trackPageView('/edit/cancel');
                            $scope.event = event;
                        }

                        $scope.submitCancel = function() {
                            $modalInstance.close($scope.cancelled.reason);
                        };

                        $scope.cancel = function() {
                            $modalInstance.dismiss();
                        };

                        init();
                    }
                ]
            });

        };
    }
]);
