angular.ctModule('ct.services.events', [
    'ct.filters.truncate',
    'ct.services.ctGetCoordinates',
    'ct.services.authentication',
    'ct.constant',
    'ct.services.data'
])

    /**
     * @ngdoc service
     * @name ct.services.events.service:CTServiceEventDataManger
     *
     * @author Michael Scharl <ms@campaigning-bureau.com>
     *
     * @description
     * Manages the event create/edit modals.
     * Use it to let the user create a new `CTEvent` or to let the user Edit a  `CTEvent`
     */
    .service('CTServiceEventDataManger', [
        '$q',
        '$modal',
        '$rootScope',
        '$timeout',
        'CTServiceEventCategories',
        'CTServiceAuthentication',
        'CTGetCoordinates',
        'CTServiceEvents',
        'CTConfig',
        'CTServiceFacebook',
        'CTServiceHelper',
        function($q,
            $modal,
            $rootScope,
            $timeout,
            CTServiceEventCategories,
            CTServiceAuthentication,
            CTGetCoordinates,
            CTServiceEvents,
            CTConfig,
            CTServiceFacebook,
            CTServiceHelper
        ) {

            var CTServiceEventDataManger = {};

            /**
             * The ModalController
             *
             * @param $scope
             * @param $filter
             * @param $modalInstance
             * @param $event
             * @param section
             * @param CTConfig
             */
            function eventManagerController($scope, $filter, $modalInstance, $event, section, CTConfig) {


                var postSaveHooks = {};

                mapboxgl.accessToken = CTConfig.MAPBOX_ACCESS_TOKEN;

                function setLoading(state) {
                    try {
                        $scope.$$childTail.SettingsData.APICall = state;
                    } catch (e) { /*console.warn(e);*/
                    }
                    try {
                        $scope.$$childTail.CategorySelector.APICall = state;
                    } catch (e) { /*console.warn(e);*/
                    }
                    try {
                        $scope.$$childTail.TitleData.APICall = state;
                    } catch (e) { /*console.warn(e);*/
                    }
                    try {
                        $scope.$$childTail.DateData.APICall = state;
                    } catch (e) { /*console.warn(e);*/
                    }
                    try {
                        $scope.$$childTail.HostData.APICall = state;
                    } catch (e) { /*console.warn(e);*/
                    }
                    try {
                        $scope.$$childTail.LocationData.APICall = state;
                    } catch (e) { /*console.warn(e);*/
                    }
                    try {
                        $scope.$$childTail.CapacitiesData.APICall = state;
                    } catch (e) { /*console.warn(e);*/
                    }

                    $scope.dialog.loading = state;
                }

                /**
                 * Dismiss the modal
                 *
                 * @param {string} atSection can be used to send to analytics
                 */
                $scope.dismiss = function(atSection) {
                    $modalInstance.dismiss('cancel');
                };


                $scope.disabledByCategory = function(setting) {
                    try {
                        //console.log($scope.$event.event_category);
                        return ($scope.$event.event_category[setting] !== null);
                    } catch (e) {
                    }

                    return false;
                };

                $scope.maxFriends = function() {
                    var maxFriends = [];

                    for (var i = 0; i <= 10; i++) {
                        maxFriends.push({
                            count: i,
                            name: i == 0 ? 'Teilnehmer dürfen niemanden mitbringen' : (i ==
                                                                                       1 ? 'Teilnehmer dürfen eine Person mitbringen' : 'Teilnehmer dürfen ' +
                                                                                                                                        i +
                                                                                                                                        ' Personen mitbringen')
                        });
                    }

                    return maxFriends;
                }();

                $scope.visibilityLevels = [
                    {
                        key: 'ALL',
                        name: 'Dieses Event ist öffentlich sichtbar'
                    },
                    {
                        key: 'PRIVATE',
                        name: 'Dieses Event ist nur über den Direktlink sichtbar'
                    },
                    {
                        key: 'INVITED',
                        name: 'Nur eingeladene Personen dürfen dieses Event sehen'
                    }
                ];

                $scope.acceptanceLevels = [
                    {
                        key: 'ALL',
                        name: 'Alle Personen dürfen Teilnehmen'
                    },
                    {
                        key: 'INVITED',
                        name: 'Nur eingeladene Personen dürfen Teilnehmen'
                    },
                    {
                        key: 'NONE',
                        name: 'Niemand darf teilnehmen'
                    }
                ];

                $scope.invitationLevels = [
                    {
                        key: 'HOST',
                        name: 'Nur ich darf Personen einladen'
                    },
                    {
                        key: 'PARTICIPANT',
                        name: 'Alle Teilnehmer dürfen Personen einladen'
                    },
                    {
                        key: 'USER',
                        name: 'Jeder darf Personen einladen'
                    }
                ];

                $scope.participantVisibilityLevels = [
                    {
                        key: 'ALL',
                        name: 'Jeder darf die Teilnehmerliste sehen'
                    },
                    {
                        key: 'PARTICIPANT',
                        name: 'Alle Teilnehmer dürfen die Teilnehmerliste sehen'
                    },
                    {
                        key: 'HOST',
                        name: 'Nur ich darf die Teilnehmerliste sehen'
                    }
                ];


                /**
                 * Close the given section - If a new event is created the next section is activated
                 * Event data is automatically updated
                 *
                 * @param section
                 */
                $scope.closeSection = function(section) {
                    var lastSection = false,
                        nextIsLast = false;

                    /** Set next section */
                    switch (section) {
                        case 'category':
                            $scope.dialog.activeSection = 'title';
                            break;
                        case 'title':
                            $scope.dialog.activeSection = 'date';
                            break;
                        case 'date':
                            $scope.dialog.activeSection = 'location';
                            break;
                        case 'location':
                            $scope.dialog.activeSection = 'host';
                            nextIsLast = true;
                            break;
                        case 'host':
                            $scope.dialog.activeSection = 'capacities';
                            // nextIsLast = true;
                            lastSection = true;
                            break;
                        // case 'capacities':
                            // lastSection = true;
                            /* no more sections */
                            // break;
                    }

                    /** Update event data */
                    $event.update($scope.$event);


                    if (!$scope.$event.isNew || lastSection) {
                        /** Avoid form flash */
                        $scope.dialog.activeSection = section;

                        setLoading(true);

                        // extend person with host_objects person to save all relevant data.
                        if (typeof $event.person !== 'undefined' && typeof $event.host_object.person !== 'undefined') {
                            angular.extend($event.person, $event.host_object.person);
                        }

                        if ($event && $event.person) {
                            delete $event.person.user_type;
                        }

                        $event.save()
                            .then(function(data) {

                                var postHookPromises = [];
                                angular.forEach(postSaveHooks, function(hook) {
                                    postHookPromises.push(hook());
                                });

                                $q.all(postHookPromises)
                                    .then(function() {
                                        $modalInstance.close($event);

                                        if ($scope.$event.isNew) {
                                            CTServiceEvents.pushEvent(CTConfig.PAGE_ID, $event);

                                            $rootScope.$broadcast('openInformationDialog',
                                                { title: 'Das Event wurde erstellt.' }
                                            );
                                        }
                                        else {
                                            $rootScope.$broadcast('openInformationDialog',
                                                { title: 'Das Event wurde aktualisiert.' }
                                            );
                                        }
                                    }, failedCreating);

                            }, failedCreating);
                    }
                    else {
                        initSection($scope.dialog.activeSection, nextIsLast);
                    }
                };

                function failedCreating() {
                    $rootScope.$broadcast('openInformationDialog', {
                        title: 'Fehler beim Erstellen',
                        body: 'Beim erstellen des Events ist leider ein Fehler aufgetreten.'
                    });
                    $modalInstance.dismiss('error');
                }

                function init() {
                    $scope.dialog = {};
                    $scope.$event = new CTEvent($event);

                    if (jQuery.fn.cbToolkit_Selectizr) {
                        jQuery('#ct-services--event-data-manager')
                            .cbToolkit_Selectizr();
                    }

                    $scope.dialog.activeSection = section || 'category';
                    initSection($scope.dialog.activeSection);


                    /** Preset Start and End Date */
                    if (typeof $scope.$event.starts_at === 'undefined' || !($scope.$event.starts_at instanceof Date)) {
                        $scope.$event.starts_at = new Date();
                        $scope.$event.starts_at.setHours(9, 0, 0, 0);
                    }
                    if (typeof $scope.$event.ends_at === 'undefined' || !($scope.$event.ends_at instanceof Date)) {
                        $scope.$event.ends_at = new Date();
                        $scope.$event.ends_at.setHours(18, 0, 0, 0);
                    }
                    /** Preset Start Date/Time */
                    if (typeof $scope.$event.starts_at_date ===
                        'undefined' ||
                        !($scope.$event.starts_at_date instanceof Date)) {
                        $scope.$event.starts_at_date = new Date($scope.$event.starts_at.getTime());
                    }
                    if (typeof $scope.$event.starts_at_time ===
                        'undefined' ||
                        !($scope.$event.starts_at_time instanceof Date)) {
                        $scope.$event.starts_at_time = new Date($scope.$event.starts_at.getTime());
                    }

                    /** Preset End Date/Time */
                    if (typeof $scope.$event.ends_at_date ===
                        'undefined' ||
                        !($scope.$event.ends_at_date instanceof Date)) {
                        $scope.$event.ends_at_date = new Date($scope.$event.ends_at.getTime());
                    }
                    if (typeof $scope.$event.ends_at_time ===
                        'undefined' ||
                        !($scope.$event.ends_at_time instanceof Date)) {
                        $scope.$event.ends_at_time = new Date($scope.$event.ends_at.getTime());
                    }

                    /**
                     * Add Forms to current scope. Seems to be a binding bug in combination with $modal
                     */
                    angular.forEach($scope.$$childTail, function(element, key) {
                        if (element.$name) {
                            $scope[key] = $scope.$$childTail[key];
                        }
                    });
                }

                function initSection(section, isLastSection) {
                    switch (section) {
                        case 'category':
                            initCategorySelector(isLastSection);
                            break;
                        case 'title':
                            initTitleData(isLastSection);
                            break;
                        case 'date':
                            initDateData(isLastSection);
                            break;
                        case 'location':
                            initLocationData(isLastSection);
                            break;
                        case 'host':
                            initHostData(isLastSection);
                            break;
                        case 'settings':
                            initSettingsData(isLastSection);
                            break;
                        // case 'capacities':
                        //     initCapacitiesData(isLastSection);
                    }
                }

                /**
                 * Init data to show and work with the category selector
                 */
                function initCategorySelector() {
                    $scope.dialog.CategorySelector = {};
                    $scope.dialog.CategorySelector.loading = true;
                    setLoading(true);

                    $scope.dialog.CategorySelector.submit = function() {

                        $scope.$event.event_category_id = $scope.$event.event_category.id;

                        $scope.closeSection('category');
                    };

                    /** Load Categories */
                    CTServiceEventCategories.getAllCategories()
                        .then(function(categories) {

                            /** unset flag and set categories */
                            $scope.dialog.CategorySelector.loading = false;
                            setLoading(false);
                            $scope.dialog.CategorySelector.categories = $filter('filter')(
                                categories,
                                function(value, index) {
                                    return CTServiceAuthentication.hasContentEditPermission() ||
                                           value.users_can_create_event;
                                }
                            );

                            /** preset category if not set */
                            if (!$scope.$event.event_category) {
                                $scope.$event.event_category = $scope.dialog.CategorySelector.categories[0];
                            }

                            if ($scope.dialog.CategorySelector.categories.length == 1) {
                                $scope.dialog.CategorySelector.submit();
                            }
                            else {
                                CTServiceHelper.trackPageView('/edit/type');
                            }
                        }, failedCreating);
                }

                function initTitleData() {
                    CTServiceHelper.trackPageView('/edit/description');
                    $scope.dialog.TitleData = {};

                    $scope.dialog.TitleData.submit = function() {
                        //Nothing to do - just close the section
                        if ($scope.$$childTail.TitleDataMediaDrop) {
                            setLoading(true);
                            $scope.$$childTail.TitleDataMediaDrop.submitMedia()
                                .then(function() {
                                    setLoading(false);
                                    $scope.closeSection('title');
                                }, function() {
                                    setLoading(false);
                                    $scope.closeSection('title');
                                })
                        }
                        else {
                            $scope.closeSection('title');
                        }
                    };

                    $scope.dialog.TitleData.imageUpload = function(image_id, image) {
                        console.log(image_id, image);
                        setLoading(false);

                        setTimeout(function() {
                            $scope.$apply(function() {
                                if (image_id && image) {

                                    $scope.dialog.TitleData.image_url = image;

                                    delete $scope.dialog.TitleData.mediaError;
                                    delete $scope.dialog.TitleData.mediaErrorText;

                                    $scope.$event.image_id = image_id;
                                    $scope.$event.image_url = image.md;
                                }
                            });
                        }, 1);
                    };

                    $scope.dialog.TitleData.imageDrop = function(image_url) {
                        setLoading(false);
                        $scope.dialog.TitleData.image_url = image_url;

                        $scope.$apply();
                    };

                    $scope.dialog.TitleData.imageRemove = function() {
                        delete $scope.$event.image_url;
                        $scope.$event.image_id = null;

                        $scope.$apply();
                    };

                    $scope.dialog.TitleData.mediaOnError = function(data) {
                        $scope.dialog.TitleData.mediaError = true;
                        if (data.message) {
                            $scope.dialog.TitleData.mediaErrorText = data.message;
                        }
                    };

                    $timeout(function() {
                        if ($scope.$event.hasImage()) {
                            $scope.$$childTail.TitleDataMediaDrop.setMedia($scope.$event.image.md, 'IMG', true);
                        }
                    }, 500);
                }

                function initDateData() {
                    CTServiceHelper.trackPageView('/edit/datetime');
                    $scope.dialog.DateData = {};

                    $scope.dialog.DateData.submit = function() {
                        $scope.$event.starts_at = new Date($scope.$event.starts_at.getTime());
                        $scope.$event.starts_at.setHours($scope.$event.starts_at_time.getHours(),
                            $scope.$event.starts_at_time.getMinutes(),
                            0,
                            0
                        );

                        $scope.$event.ends_at = new Date($scope.$event.ends_at.getTime());
                        $scope.$event.ends_at.setHours($scope.$event.ends_at_time.getHours(),
                            $scope.$event.ends_at_time.getMinutes(),
                            0,
                            0
                        );

                        $scope.closeSection('date');
                    };

                    // watch the start date. if this model changes, automatically set the end date to the same day
                    $scope.$watch('$event.starts_at_date', function() {
                        $scope.$event.ends_at_date = new Date($scope.$event.starts_at_date.getTime());
                    });
                }

                function initHostData(isLastSection) {
                    CTServiceHelper.trackPageView('/edit/host');
                    $scope.dialog.HostData = {};
                    $scope.dialog.HostData.isLastSection = isLastSection;

                    $event.person = $event.person || {};
                    $scope.dialog.HostData.editPersonData = (!$event.person.name || !$event.person.surname);

                    $scope.dialog.HostData.submit = function() {
                        //Nothing to do - just close the section
                        $scope.closeSection('host');
                    };

                    $scope.$event.host_object.person = $scope.$event.person = $scope.$event.host_object.person ||
                                                                              ($scope.$event.person || {});

                    $scope.dialog.HostData.facebookConnect = function() {
                        CTServiceFacebook.login({ scope: 'email' })
                            .then(function(response) {
                                if (response.authResponse) {
                                    $scope.$event.person.social_connectors = $scope.$event.person.social_connectors ||
                                        [];
                                    $scope.$event.person.social_connectors.push({
                                        name: 'facebook',
                                        foreign_id: response.authResponse.userID
                                    });
                                }

                            }, function(error) {
                                console.warn(error);
                            });
                    };

                    $scope.dialog.HostData.hasFacebookConnect = function() {

                        if ($scope.$event.person && $scope.$event.person.social_connectors) {
                            for (var index = 0; index <
                                                ($scope.$event.person.social_connectors || []).length; index++) {
                                if ($scope.$event.person.social_connectors[index].name == 'facebook') {
                                    return true;
                                }
                            }
                        }

                        return false;
                    };

                    /** Preset HostObject */
                    if (!$scope.$event.host_object) {
                        $scope.$event.host_object = CTServiceAuthentication.getCurrentUser();
                    }
                    if (!$scope.$event.host_object.person) {
                        $scope.$event.host_object.person = {};
                    }
                    if (!$scope.$event.host_object.person.addresses || !$scope.$event.host_object.person.addresses[0]) {
                        $scope.$event.host_object.person.addresses = [
                            {}
                        ];
                    }
                }

                function initLocationData() {
                    CTServiceHelper.trackPageView('/edit/location');
                    $scope.dialog.LocationData = {};
                    $scope.dialog.LocationData.waitForAddressTimer = false;

                    $scope.dialog.LocationData.submit = function() {
                        //Nothing to do - just close the section
                        $scope.closeSection('location');
                    };


                    if (!$scope.$event.addresses) {
                        $scope.$event.addresses = [
                            {}
                        ];
                    }

                    /** Watch the address */
                    $scope.$watchCollection(function() {
                        return {
                            street: $scope.$event.addresses[0].street,
                            zip_code: $scope.$event.addresses[0].zip_code,
                            city: $scope.$event.addresses[0].city
                        };
                    }, function() {

                        /** give the user time to finish */
                        $timeout.cancel($scope.dialog.LocationData.waitForAddressTimer);
                        $scope.dialog.LocationData.waitForAddressTimer = $timeout(function() {

                            /** Load the coordinates for the given address */
                            if ($scope.dialog.LocationData.map) {
                                if (!$scope.$event.addresses[0].street ||
                                    !$scope.$event.addresses[0].zip_code ||
                                    !$scope.$event.addresses[0].city) {
                                    return;
                                }
                                setLoading(true);

                                CTGetCoordinates.byAddress($scope.$event.addresses[0].street,
                                    $scope.$event.addresses[0].zip_code,
                                    $scope.$event.addresses[0].city
                                )
                                    .then(function(coordinates) {
                                        /** Create marker */
                                        if (!$scope.dialog.LocationData.marker) {
                                            $scope.dialog.LocationData.marker = new mapboxgl.Marker({ draggable: true }).setLngLat(
                                                coordinates);
                                            $scope.dialog.LocationData.marker.addTo($scope.dialog.LocationData.map);

                                            $scope.dialog.LocationData.marker.on('dragend', function() {
                                                console.log($scope.dialog.LocationData.marker.getLngLat());
                                                $scope.$event.addresses[0].lat = $scope.dialog.LocationData.marker.getLngLat().lat;
                                                $scope.$event.addresses[0].lng = $scope.dialog.LocationData.marker.getLngLat().lng;
                                                console.log(angular.copy($scope.$event.addresses[0]));
                                            });
                                        }
                                        else {
                                            $scope.dialog.LocationData.marker.setLngLat(coordinates);
                                        }
                                        $scope.dialog.LocationData.map.setCenter(coordinates);

                                        setLoading(false);
                                    }, function(error) {
                                    });
                            }
                        }, 1000);
                    });

                    setTimeout(function() {
                        try {
                            $scope.dialog.LocationData.map = new mapboxgl.Map({
                                container: 'location-data--marker-map',
                                style: 'mapbox://styles/mapbox/streets-v11',
                                zoom: 4,
                                center: new mapboxgl.LngLat(16.373471, 48.208411),
                                scrollZoom: false,
                                dragRotate: false,
                                dragPan: true
                            });

                            $scope.dialog.LocationData.marker = false;
                        } catch (e) {
                        }
                    }, 1000);

                }

                function initSettingsData() {
                    $scope.dialog.SettingsData = {};

                    $scope.dialog.SettingsData.submit = function() {
                        //Nothing to do - just close the section
                        $scope.closeSection('settings');
                    };
                }

                // function initCapacitiesData(isLastSection) {
                //     CTServiceHelper.trackPageView('/edit/attendees');
                //     $scope.dialog.CapacitiesData = {};
                //     $scope.dialog.CapacitiesData.isLastSection = isLastSection;

                //     if ($scope.$event.custom_field_label) {
                //         $scope.dialog.CapacitiesData.enable_custom_field = true;
                //     }

                //     $scope.dialog.CapacitiesData.submit = function() {
                //         if (!$scope.dialog.CapacitiesData.enable_custom_field) {
                //             delete $scope.$event.custom_field_label;
                //         }


                //         $scope.closeSection('capacities');
                //     };

                //     $scope.dialog.CapacitiesData.addCapacity = function() {
                //         if (!$scope.$event.event_capacities) {
                //             $scope.$event.event_capacities = [];
                //         }
                //         $scope.$event.event_capacities.push({
                //             id: -$scope.$event.event_capacities.length,
                //             name: '',
                //             max_people: 0
                //         });
                //     };

                //     $scope.dialog.CapacitiesData.deleteCapacity = function(capacityToDelete) {
                //         angular.forEach($scope.$event.event_capacities, function(capacity, index) {
                //             if (capacity.id == capacityToDelete.id) {

                //                 if (parseInt(capacity.id) < 0) {
                //                     $scope.$event.event_capacities.splice(index, 1);
                //                 }
                //                 else if (parseInt(capacity.id) > 0) {
                //                     capacity.deleted = true;
                //                 }

                //             }
                //         })
                //     };

                //     $scope.dialog.CapacitiesData.filterCapacities = function(value) {
                //         return !value.deleted;
                //     };

                //     $scope.$event.event_capacities = $scope.$event.event_capacities || [];
                //     if (!$scope.$event.event_capacities.length) {
                //         $scope.dialog.CapacitiesData.addCapacity();
                //         $scope.$event.event_capacities[0].name = 'Teilnehmer'
                //     }

                //     var eventIsNew = !$scope.$event.id;
                //     postSaveHooks.CapacitiesData = function() {
                //         var promise,
                //             waitForAll = [];

                //         if (!eventIsNew) {
                //             angular.forEach($scope.$event.event_capacities, function(capacity) {

                //                 if (parseInt(capacity.id) < 0) {
                //                     delete capacity.id;
                //                     waitForAll.push($event.addCapacity(capacity));
                //                 }

                //                 else if (parseInt(capacity.id) > 0 && capacity.deleted) {
                //                     waitForAll.push($event.removeCapacity(capacity.id));
                //                 }

                //                 else if (parseInt(capacity.id) > 0) {
                //                     waitForAll.push($event.updateCapacity(capacity));
                //                 }

                //             });

                //             promise = $q.all(waitForAll);
                //         }
                //         else {
                //             var defer = $q.defer();
                //             defer.resolve();
                //             promise = defer.promise
                //         }

                //         return promise;
                //     }
                // }


                /** Run everything */
                init();
            }

            /**
             * @ngdoc method
             * @name ct.services.events.service#edit
             * @methodOf ct.services.events.service:CTServiceEventDataManger
             *
             * @param {CTEvent} event The Event you want to edit
             * @param {string} section
             * The section you want to edit. Valid options are as followed:
             * <ul>
             *  <li>`category`</li>
             *  <li>`title`</li>
             *  <li>`date`</li>
             *  <li>`location`</li>
             *  <li>`host`</li>
             * </ul>
             *
             * @description
             * Load all events that may be accessed by the current user.
             *
             * @returns {promise}
             * Resolves after the user Saves the desired event section
             * All data will be automatically be updated in the CTService via
             * ´CTEvent.update´ and saved via ´CTEvent.save´. The updated event will
             * be delivered to the resolve handle.
             *
             * Rejects if the user cancels the dialog.
             */
            CTServiceEventDataManger.edit = function(event, section) {
                var modalInstance = $modal.open({
                    templateUrl: 'ct.services.events.eventManager.html',
                    controller: [
                        '$scope',
                        '$filter',
                        '$modalInstance',
                        '$event',
                        'section',
                        'CTConfig',
                        eventManagerController
                    ],
                    resolve: {
                        $event: function() {
                            return event;
                        },
                        section: function() {
                            return section;
                        }
                    }
                });

                return modalInstance.result;
            };

            /**
             * @ngdoc method
             * @name ct.services.events.service#create
             * @methodOf ct.services.events.service:CTServiceEventDataManger
             *
             * @description
             * Create a new CTEvent and walk through all event sections
             *
             * @returns {promise}
             * Resolves after the user Saves the event. All data will be
             * automatically be saved via ´CTEvent.save´. The event will be inserted
             * into the loaded events array of CTServiceEvents and delivered to the
             * resolve handle.
             *
             * Rejects if the user cancels the dialog.
             */
            CTServiceEventDataManger.create = function() {
                var modalInstance = $modal.open({
                    templateUrl: 'ct.services.events.eventManager.html',
                    controller: [
                        '$scope',
                        '$filter',
                        '$modalInstance',
                        '$event',
                        'section',
                        'CTConfig',
                        eventManagerController
                    ],
                    resolve: {
                        $event: function() {
                            return new CTEvent({
                                isNew: true,
                                host_object: CTServiceAuthentication.getCurrentUser(),
                                capacities: [
                                    {
                                        id: -1,
                                        name: 'Teilnehmer',
                                        max_people: 0
                                    }
                                ]
                            });
                        },
                        section: function() {
                            return false;
                        }
                    }
                });

                return modalInstance.result;
            };


            return CTServiceEventDataManger;
        }
    ]);
