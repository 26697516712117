angular.ctModule('ct.directives.textOrMedia', []).directive('textormedia', [
    function() {
        return {
            require: ['ngModel'], scope: {
                textIsRequired: '='     // defines if a text is required or not
            }, link: function($scope, elem, attr, ctrls) {

                var ngModel = ctrls[0];

                /**
                 * defines if a media was set
                 * @type {boolean}
                 */
                var mediaIsSet = false;

                /**
                 * initially, set the validity to false
                 */
                ngModel.$setValidity('textOrMedia', false);

                //For DOM -> model validation
                ngModel.$parsers.unshift(function(value) {
                    // field should be valid if it is not empty OR (if a media is set AND no text is required)
                    var valid = (value !== '') || (!$scope.textIsRequired && mediaIsSet);
                    ngModel.$setValidity('textOrMedia', valid);
                    return value;
                });

                /**
                 * this function is called, when an event from the mediaDrop directive was received.
                 * if no text is required AND a media was set, this function sets the validity of the
                 * text element to true.
                 */
                function validate() {
                    if(!$scope.textIsRequired && mediaIsSet) {
                        ngModel.$setValidity('textOrMedia', true);
                    }
                }


                /**
                 * listen to events, that are called by the mediaDrop directive
                 * or by the signup controller, if a media file was uploaded by url.
                 *
                 * these events are catched and a validate function is called.
                 */
                $scope.$on('mediaUpload', function(event, type) {
                    // if a 'success' event is received, the media was successfully set
                    mediaIsSet = (type === 'success');
                    validate();
                });
            }
        };
    }
]);
