angular.ctModule('ct.services.data', ['ct.constant', 'ct.services.people'])
       /**
        * @ngdoc service
        * @name ct.services.data.service:CTServiceDataParent
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * A service to manage all elements
        *
        * config can have the following values:
        *  - defaultFilterParams Object    the default filter params
        *  - route_suffix String           suffix to append after the page id
        */
       .service('CTServiceDataParent', [
           "$q", "$filter", "$rootScope", function($q, $filter, $rootScope) {
               "use strict";

               if(angular.version.major < 1 || angular.version.minor < 2 || (angular.version.minor <= 2 && parseInt(angular.version.dot, 10) <= 0)) {
                   throw new Error('CTServiceDataParent needs a more up to date angular version');
               }

               return function(route, CTDataClass, dataKeys, service_config) {

                   var _CTServiceDataParent = this;


                   /**
                    * Set the default number of elements to load per page
                    *
                    * @private
                    * @type {number}
                    */
                   var DataDefaultPageSize = 20;

                   var
                       /**
                        * Stores the route to use for API requests
                        *
                        * @type {string}
                        * @private
                        */
                       _serviceDataRoute,

                       /**
                        * References the constructor of the DataClass to use
                        *
                        * @type {function}
                        * @private
                        */
                       _CTDataClass,

                       /**
                        * stores the config object for the service (events, storywall, etc.)
                        *
                        * @type {object}
                        * @private
                        */
                       _serviceConfig          = {},

                       /**
                        * stores the config object for the specific modules (if event service, all event modules), with page_id as key
                        *
                        * @type {object}
                        * @private
                        */
                       _moduleConfig           = {},

                       /**
                        * An object that stores the data key for multiple or single element
                        * @type {object}
                        * @private
                        */
                       _serviceDataKeys        = {},

                       /**
                        * A multi dimensional Object/Array.
                        * The first layer is keyed with the page_id. It's element is
                        * an array of elements
                        *
                        * @type {object}
                        * @private
                        */
                       _loadedData             = {},

                       /**
                        * This object is keyed with the page_id. It's element is a
                        * `$q.defer()` object
                        * @type {object}
                        * @private
                        */
                       _loadedDataDefer        = {},

                       /**
                        * Total number of available elements
                        * @type {object}
                        * @private
                        */
                       _totalDataLoading       = {},

                       /**
                        * Total number of available elements
                        * @type {object}
                        * @private
                        */
                       _totalDataCount         = {},

                       /**
                        * Is it completely loaded
                        * @type {object}
                        * @private
                        */
                       _reachedTheEnd          = {},

                       /**
                        * Calculated next ids
                        * @type {number}
                        * @private
                        */
                       _nextDataIdPromises     = {},

                       /**
                        * Calculated previous ids
                        * @type {number}
                        * @private
                        */
                       _previousDataIdPromises = {},

                       /**
                        * Flag for using Cache
                        * @type {bool}
                        * @private
                        */
                       _useCachedRoutes        = false;


                   /**
                    * getPage
                    *
                    * @description
                    * Loads a page with or without filters - use THIS for EVERY page load
                    *
                    * @param {string} page_id
                    * @param {object} filter
                    * @param {number} page_nr
                    * @param {number} page_size
                    * @returns {promise}
                    */
                   function getPage(page_id, filter, page_nr, page_size) {
                       if(typeof page_id === 'undefined') {
                           throw new Error('page_id is not defined');
                       }
                       if(typeof filter === 'undefined') {
                           throw new Error('\'filter\' is not defined, can be an empty object');
                       }
                       if(typeof page_nr === 'undefined') {
                           throw new Error('\'page_nr\' is not defined');
                       }
                       if(typeof page_size === 'undefined') {
                           throw new Error('\'page_size\' is not defined');
                       }

                       var waitForIt = $q.defer(),
                           url       = _serviceDataRoute === page_id ? '/' + _serviceDataRoute : '/pages/' + page_id + '/' + _serviceDataRoute;

                       // if a route suffix is set (param to be sent after the module identictfier), also use this for the url
                       if(typeof _serviceConfig.route_suffix !== 'undefined') {
                           url += _serviceConfig.route_suffix;
                       }

                       var minify = 0;
                       if(typeof filter.person !== 'undefined') {
                           minify += 2;
                           if(typeof CTPerson === 'function') {
                               minify += 2;
                               if(filter.person instanceof CTPerson) {
                                   url = '/people/' + filter.person.id + '/' + _serviceDataRoute + '/' + page_id;
                                   delete filter.person;
                               }
                           }
                       }

                       var overrideFilterParams = {
                           page_size: page_size,
                           page_nr  : page_nr
                       };

                       if(_useCachedRoutes) {
                           overrideFilterParams.use_cache = true;
                       }

                       $CB_API({
                           method: 'GET',
                           url   : url,
                           params: angular.extend({}, _serviceConfig.defaultFilterParams, filter, overrideFilterParams)
                       }).then(function(data) {
                           $rootScope.$apply(function() {
                               waitForIt.resolve(data);
                           });
                       }, function(error) {
                           $rootScope.$apply(function() {
                               waitForIt.reject(error);
                           });
                       });

                       return waitForIt.promise;
                   }


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#setUseCachedRoutes
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {bool} flag If cache should be used or not
                    *
                    * @description
                    * Set the private flag
                    */
                   _CTServiceDataParent.setUseCachedRoutes = function(flag) {
                       _useCachedRoutes = flag;
                   };
                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getUseCachedRoutes
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @description
                    * Return the private flag
                    */
                   _CTServiceDataParent.getUseCachedRoutes = function() {
                       return _useCachedRoutes;
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getFilteredUntilPage
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page id to use to load
                    * @param {object} filter An object that contains filter for the backend
                    * @param {number} page_nr The number of pages to load
                    * @param {number} page_size The size of each page
                    *
                    * @description
                    * Get the array of filtered elements for the given module
                    *
                    * @returns {promise}
                    *  - Resolves with an array of `CTData` elements
                    *  - Rejects with
                    *    - `reset` If a reset is called during the call
                    *    - `failed` If the elements couldn´t be loaded
                    */
                   _CTServiceDataParent.getFilteredUntilPage = function(page_id, filter, page_nr, page_size) {
                       if(!page_id) {
                           throw new Error('Page id is not defined');
                       }

                       filter    = filter ? filter : {};
                       page_nr   = page_nr ? page_nr : 0;
                       page_size = page_size ? page_size : DataDefaultPageSize;


                       //Calculate how much elements are needed
                       var minCount     = (page_nr + 1) * page_size,

                           //A hash that identifies the request
                           requestHash  = getRequestHash(page_id, filter),

                           //The deferred object for this request
                           requestDefer = $q.defer();

                       _totalDataLoading[requestHash] = Math.max(_totalDataLoading[requestHash], minCount);

                       _loadedData[requestHash] = _loadedData[requestHash] || [];


                       /**
                        * Set the total available elements and add all loaded elements to the
                        * `_loadedData` object
                        *
                        * @param data
                        */
                       function checkLoadedData(data) {

                           _totalDataCount[requestHash] = data.total_count;
                           setTotalLoadingNumber();

                           var dataElements = data[_serviceDataKeys.multiple];

                           if(hasModuleConfigForKey(page_id, 'randomize') && getModuleConfigForKey(page_id, 'randomize')) {
                               dataElements = shuffle(toArray(dataElements));
                           }

                           angular.forEach(dataElements, function(element) {
                               var _element = new _CTDataClass(angular.extend(angular.copy(element), {
                                   page_id: page_id
                               }));

                               if(!_loadedData[requestHash]) {
                                   _loadedData[requestHash] = [];
                               }

                               for(var index = 0; index < _loadedData[requestHash].length; index++) {
                                   if(_loadedData[requestHash][index].id === _element.id) {
                                       return;
                                   }
                               }

                               _loadedData[requestHash].push(_element);
                           });


                           doNotify();

                           callPageLoad();
                       }

                       function setTotalLoadingNumber() {
                           var minLoadingCount   = _totalDataCount[requestHash] > 0 ? Math.min(_totalDataCount[requestHash], minCount) : minCount,
                               totalLoadingCount = _totalDataLoading[requestHash] === Infinity && _totalDataCount[requestHash] ? _totalDataCount[requestHash] : (_totalDataLoading[requestHash] || 0);

                           //console.debug('Set totalEventsLoading to Math.max(%i, %i) = %i', totalLoadingCount, minLoadingCount, Math.max(totalLoadingCount, minLoadingCount));

                           _totalDataLoading[requestHash] = Math.max(totalLoadingCount, minLoadingCount);
                       }


                       /**
                        * Finish the call by creating the return array removing the promise
                        * from active promises and resolve the promise
                        */
                       function finishCall() {

                           var returnArray;
                           if(minCount < Infinity) {
                               returnArray = $filter('limitTo')(_loadedData[requestHash], minCount);
                               //console.debug('Limit events for return to %i, Result: %o', minCount, returnArray);
                           }
                           else {
                               returnArray = _loadedData[requestHash];
                           }

                           //Remove saved promise
                           delete _loadedDataDefer[requestHash];

                           _reachedTheEnd[requestHash] = (_loadedData[requestHash].length >= _totalDataCount[requestHash]);

                           //Resolve the promise
                           requestDefer.resolve(returnArray);
                       }

                       /**
                        * Notify the promise about updates
                        */
                       function doNotify() {
                           requestDefer.notify({
                               total       : _totalDataLoading[requestHash],
                               loaded      : ObjectKeys(_loadedData[requestHash]).length,
                               current_data: _loadedData[requestHash]
                           });
                       }

                       /**
                        * Check if a page can or should be loaded and run the functions
                        */
                       function callPageLoad() {
                           _totalDataCount[requestHash] = (typeof _totalDataCount[requestHash] === 'undefined' ? Infinity : _totalDataCount[requestHash]);

                           var pageToLoad        = Math.floor(_loadedData[requestHash].length / page_size),
                               maxAvailablePages = Math.ceil(_totalDataCount[requestHash] / page_size) - 1;

                           //console.group('Page Load');
                           //console.debug('Page to load: %i', pageToLoad);
                           //console.debug('Page size: %i', page_size);
                           //console.debug('Maximum available Pages: %i', maxAvailablePages);
                           //console.debug('Currently loaded Stories: %i', _loadedData[requestHash].length);
                           //console.debug('Currently loaded Stories: %o', _loadedData);
                           //console.debug('Available Stories: %i', (_totalDataCount[requestHash] || Infinity));
                           //

                           if(pageToLoad > page_nr) {

                               //console.debug('Finish call because of pageToLoad(%i) > page_nr(%i)', pageToLoad, page_nr);
                               finishCall();

                           }
                           else if(pageToLoad > maxAvailablePages) {

                               //console.debug('Finish call because of pageToLoad(%i) > maxAvailablePages(%i)', pageToLoad, maxAvailablePages);
                               finishCall();
                               _reachedTheEnd[requestHash] = true;

                           }
                           else if(_loadedData[requestHash].length >= (_totalDataCount[requestHash] || Infinity)) {

                               //console.debug('Finish call because of currentlyLoadedStories(%i) >= availableStories(%i)', _loadedData[requestHash].length, (_totalDataCount[requestHash] || Infinity));
                               finishCall();
                               _reachedTheEnd[requestHash] = true;

                           }
                           else if(_reachedTheEnd[requestHash]) {

                               //console.debug('Finish call because of reachedTheEnd');
                               finishCall();

                           }
                           else {

                               //console.debug('Now loading the page…');
                               getPage(page_id, filter, pageToLoad, page_size)
                                   .then(checkLoadedData,
                                       function() {
                                           //TODO: add failed callback
                                       }, doNotify)
                               ;
                           }

                           //console.debug('Request hash: %s', requestHash);
                           //console.debug('Defer: %o', _loadedDataDefer[requestHash]);

                           //console.trace();
                           //console.groupEnd('Page Load');
                       }


                       if(!_loadedDataDefer[requestHash]) {
                           //Save promise
                           _loadedDataDefer[requestHash] = requestDefer;
                           callPageLoad();
                       }
                       else {
                           _loadedDataDefer[requestHash].promise.then(function() {
                               //Save promise
                               _loadedDataDefer[requestHash] = requestDefer;
                               callPageLoad();
                           }, function() {
                               //TODO: add failed callback
                           }, doNotify);
                       }

                       return requestDefer.promise;
                   };

                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getFilteredUntilNextPage
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id to use to load
                    * @param {object} filter An object that contains filter for the backend
                    * @param {number} page_size The size of each page
                    *
                    * @description
                    * Get the array of filtered elements including the next page
                    *
                    * If you have one page loaded with a `page_size` of 20 and call `getFilteredUntilNextPage` with a `page_size` of 20 you'll then get all elements from 0 to 39.
                    * If you have one page loaded with a `page_size` of 10 and call `getFilteredUntilNextPage` with a `page_size` of 5 you'll then get all elements from 0 to 14.
                    *
                    * @returns {promise}
                    *  - Resolves with an array of `CTData` elements
                    *  - Rejects with
                    *    - `reset` If a reset is called during the call
                    *    - `failed` If the elements couldn´t be loaded
                    */
                   _CTServiceDataParent.getFilteredUntilNextPage = function(page_id, filter, page_size) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       filter    = filter ? filter : {};
                       page_size = page_size ? page_size : DataDefaultPageSize;

                       var requestHash     = getRequestHash(page_id, filter),
                           currentlyLoaded = angular.isArray(_loadedData[requestHash]) ? _loadedData[requestHash].length : 0,
                           availablePages  = Math.floor(currentlyLoaded / page_size);

                       return _CTServiceDataParent.getFilteredUntilPage(page_id, filter, availablePages, page_size);
                   };

                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getAll
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id Thepage_id to use to load
                    *
                    * @description
                    * Get the full array of available Elements for the given module
                    *
                    * @returns {promise}
                    *  - Resolves with an array of `CTData` elements
                    *  - Rejects with
                    *    - `reset` If a reset is called during the call
                    *    - `failed` If the elements couldn´t be loaded
                    */
                   _CTServiceDataParent.getAll = function(page_id) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       return _CTServiceDataParent.getFilteredUntilPage(page_id, {}, Infinity, DataDefaultPageSize);
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getFilteredSync
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id to use to load
                    * @param {object} filter The filter of the request
                    *
                    * @description
                    * Get the full array of currently loaded Elements for the given module and filter
                    *
                    * @returns {array}
                    *  The array of all currently loaded elements
                    */
                   _CTServiceDataParent.getFilteredSync = function(page_id, filter) {
                       return _loadedData[getRequestHash(page_id, filter)];
                   };

                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getAllFiltered
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id to use to load
                    * @param {object} filter The options to use for filtering
                    *
                    * @description
                    * Get the filtered array of available Elements for the given module
                    *
                    * @returns {promise} Will be resolved with the filtered elements array
                    */
                   _CTServiceDataParent.getAllFiltered = function(page_id, filter) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       return _CTServiceDataParent.getFilteredUntilPage(page_id, filter, Infinity, DataDefaultPageSize);
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getUntilPage
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id to use to load
                    * @param {number} page_nr the least page to load
                    * @param {number=} [page_size=20] the size of each page
                    *
                    * @description
                    * Get the array of elements until the given page
                    *
                    * @returns {promise}
                    *  - Resolves with an array of `CTData` elements
                    *  - Rejects with
                    *    - `reset` If a reset is called during the call
                    *    - `failed` If the elements couldn´t be loaded
                    */
                   _CTServiceDataParent.getUntilPage = function(page_id, page_nr, page_size) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       return _CTServiceDataParent.getFilteredUntilPage(page_id, {}, page_nr, page_size);
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#reachedTheEnd
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id to check
                    * @param {object} filter The filters of your requests
                    *
                    * @description
                    * Returns if all elements are loaded
                    *
                    */
                   _CTServiceDataParent.reachedTheEnd = function(page_id, filter) {
                       return !!_reachedTheEnd[getRequestHash(page_id, filter)];
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#get
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id to use to load
                    * @param {number|string} id The id to load
                    * @param {object} overrideFilter Use this if you want to set a specific filter for searching
                    *
                    * @description
                    * Get a specific story
                    *
                    * @returns {promise}
                    *  - Resolves with `CTData`
                    *  - Rejects with
                    *    - `reset` If a reset is called during the call
                    *    - `failed` If the story couldn´t be loaded
                    */
                   _CTServiceDataParent.get = function(page_id, id, overrideFilter) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       function findElement() {
                           //console.debug('Search Element');
                           var resolved = false;
                           angular.forEach(_loadedData[getRequestHash(page_id, overrideFilter)], function(element) {
                               //console.debug('Check Element: ', element.id);
                               if(element.id == id) {
                                   //console.info('Found Element: ', element.id);
                                   resolved = true;
                                   _loadedDataDefer[getRequestHash(page_id, {id: id})].resolve(element);
                               }
                           });

                           if(!resolved && !_CTServiceDataParent.reachedTheEnd(page_id, overrideFilter)) {
                               //console.debug('Nothing found - load next: ', overrideFilter);
                               _CTServiceDataParent.getFilteredUntilNextPage(page_id, overrideFilter)
                                                   .then(findElement, function(reason) {
                                                       //console.warn('Error on load', reason);
                                                       (_loadedDataDefer[getRequestHash(page_id, {id: id})] || {
                                                           reject: function() {
                                                           }
                                                       }).reject(reason);
                                                   });
                           }
                           else if(!resolved && _CTServiceDataParent.reachedTheEnd(page_id, overrideFilter)) {
                               //console.debug('Nothing found - Finished loading');
                               _loadedDataDefer[getRequestHash(page_id, {id: id})].reject();
                           }
                       }

                       if(!_loadedDataDefer[getRequestHash(page_id, {id: id})]) {
                           _loadedDataDefer[getRequestHash(page_id, {id: id})] = $q.defer();

                           findElement();
                       }
                       else {
                           //console.debug('Deferred exists');
                       }


                       return _loadedDataDefer[getRequestHash(page_id, {id: id})].promise;
                   };

                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getNextId
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id
                    * @param {CTData} element
                    * @param {object} filter
                    *
                    * @description
                    * return the next storyId depending on the given story
                    *
                    * @returns {promise}
                    *  - resolves with the id as `Number`
                    */
                   _CTServiceDataParent.getNextId = function(page_id, element, filter) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       var requestHash = getRequestHash(page_id, filter);

                       function resolveElementIndex(index) {
                           _nextDataIdPromises[requestHash][element.id].resolve(index);
                       }

                       function noIndex() {
                           _nextDataIdPromises[requestHash][element.id].reject();
                       }

                       function checkIndex() {
                           var givenElementIndex = -1,
                               nextElement, nextElementId;

                           angular.forEach(_loadedData[requestHash], function(savedElement, index) {
                               if(savedElement.id == element.id) {
                                   givenElementIndex = index;
                               }
                           });

                           if(givenElementIndex >= 0) {
                               nextElement = _loadedData[requestHash][givenElementIndex + 1];
                               if(nextElement) {
                                   resolveElementIndex(nextElement.id);
                               }
                               else if(!_CTServiceDataParent.reachedTheEnd(page_id, filter)) {
                                   _CTServiceDataParent.getFilteredUntilNextPage(page_id, filter)
                                                       .then(checkIndex, noIndex);
                               }
                               else {
                                   noIndex();
                               }
                           }
                           else if(!_CTServiceDataParent.reachedTheEnd(page_id, filter)) {
                               _CTServiceDataParent.getFilteredUntilNextPage(page_id, filter)
                                                   .then(checkIndex, noIndex);
                           }
                           else {
                               noIndex();
                           }
                       }

                       if(!_nextDataIdPromises[requestHash]) {
                           _nextDataIdPromises[requestHash] = {};
                       }

                       if(!_nextDataIdPromises[requestHash][element.id]) {
                           _nextDataIdPromises[requestHash][element.id] = $q.defer();

                           checkIndex();
                       }

                       return _nextDataIdPromises[requestHash][element.id].promise;
                   };

                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getPreviousId
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id
                    * @param {CTData} element
                    * @param {object} filter
                    *
                    * @description
                    * return the previous storyId depending on the given story
                    *
                    * @returns {promise}
                    *  - resolves with the id as `Number`
                    */
                   _CTServiceDataParent.getPreviousId = function(page_id, element, filter) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       var requestHash = getRequestHash(page_id, filter);

                       function resolveElementIndex(index) {
                           _previousDataIdPromises[requestHash][element.id].resolve(index);
                       }

                       function noIndex() {
                           _previousDataIdPromises[requestHash][element.id].reject();
                       }

                       function checkIndex() {
                           var givenElementIndex = -1,
                               previousElement, previousElementId;

                           angular.forEach(_loadedData[requestHash], function(savedElement, index) {
                               if(savedElement.id == element.id) {
                                   givenElementIndex = index;
                               }
                           });

                           if(givenElementIndex > 0) {
                               previousElement = _loadedData[requestHash][givenElementIndex - 1];
                               resolveElementIndex(previousElement.id);
                           }
                           else if(!_CTServiceDataParent.reachedTheEnd(page_id, filter)) {
                               _CTServiceDataParent.getFilteredUntilNextPage(page_id, filter)
                                                   .then(checkIndex, noIndex);
                           }
                           else {
                               noIndex();
                           }
                       }

                       if(!_previousDataIdPromises[requestHash]) {
                           _previousDataIdPromises[requestHash] = {};
                       }

                       if(!_previousDataIdPromises[requestHash][element.id]) {
                           _previousDataIdPromises[requestHash][element.id] = $q.defer();

                           checkIndex();
                       }

                       return _previousDataIdPromises[requestHash][element.id].promise;
                   };

                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#getTotalCount
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id
                    * @param {string} filter The filter
                    *
                    * @description
                    * Add a new `CTData` to the Service
                    *
                    * @returns {promise}
                    *  - `true` if the story was added to the service
                    *  - `false` if there was a problem. Probably because of no `id`
                    */
                   _CTServiceDataParent.getTotalCount = function(page_id, filter) {
                       var requestHash = getRequestHash(page_id, filter);
                       return _totalDataCount[requestHash] || 0;
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#reset
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @description
                    * Reset the given module
                    */
                   _CTServiceDataParent.reset = function() {
                       angular.forEach(_loadedDataDefer, function(deferredObject) {
                           deferredObject.reject('reset');
                       });

                       _loadedData             = {};
                       _loadedDataDefer        = {};
                       _totalDataLoading       = {};
                       _totalDataCount         = {};
                       _reachedTheEnd          = {};
                       _nextDataIdPromises     = {};
                       _previousDataIdPromises = {};
                   };

                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#resetFromPage
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id    the current page_id
                    * @param {string} filter   the current filter params
                    * @param {int} page_nr   the nr of the page where the reset shall start (1-based!!)
                    * @param {int} page_size
                    *
                    * @description
                    * removes the stored data from a specified page until the end of the array.
                    * also recalculates the number of loaded elements.
                    */
                   _CTServiceDataParent.resetFromPage = function(page_id, filter, page_nr, page_size) {
                       // A hash that identifies the request
                       var requestHash = getRequestHash(page_id, filter);

                       // compute the number of elements we want to remove
                       var elementsToRemove = _loadedData[requestHash].length - ((page_nr - 1) * page_size);
                       // remove all loaded data from this page until the end
                       _loadedData[requestHash].splice((page_nr - 1) * page_size, elementsToRemove);

                       // reset the number of loaded items
                       _totalDataLoading[requestHash] = _totalDataLoading[requestHash] - elementsToRemove;

                       // reset the reachedThEndProperty if more data is available
                       if(_totalDataLoading[requestHash] < _totalDataCount[requestHash]) {
                           _reachedTheEnd[requestHash] = false;
                       }
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#add
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id
                    * @param {CTData} element The story to add. The Story needs an `id` to be added
                    *
                    * @description
                    * Add a new `CTData` to the Service
                    *
                    * @returns {boolean}
                    *  - `true` if the story was added to the service
                    *  - `false` if there was a problem. Probably because of no `id`
                    */
                   _CTServiceDataParent.add = function(page_id, element) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       if(!element.id) {
                           //throw new Error('Story has no ID set');
                           return false;
                       }

                       var requestHash = getRequestHash(page_id, {});
                       // if no object for this hash exists, create an empty one
                       if(!_loadedData[requestHash]) {
                           _loadedData[requestHash] = {};
                       }

                       _loadedData[requestHash][element.id] = element;
                       return true;
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#save
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id
                    * @param {CTData} element The story to save.
                    *
                    * @description
                    * Add a new `CTData` to the Service
                    *
                    * @returns {promise}
                    *  - Resolves with the saved story
                    *  - Rejects if the story could not be saved
                    */
                   _CTServiceDataParent.save = function(page_id, element) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }
                       var deferred = $q.defer(), apiMethod, apiUrl, _rawData = element.rawData();

                       if(_rawData.id > 0) {
                           apiMethod = 'PUT';
                           apiUrl    = '/pages/' + _serviceDataRoute + '/' + page_id + '/' + _rawData.id;
                       }
                       else {
                           apiMethod = 'POST';
                           apiUrl    = '/pages/' + _serviceDataRoute + '/' + page_id;
                       }


                       element.APICall = true;
                       $CB_API({
                           method: apiMethod,
                           url   : apiUrl,
                           data  : _rawData
                       }).then(function(data) {

                           if(data[_serviceDataKeys.single]) {
                               element.update(data[_serviceDataKeys.single]);
                           }

                           element.APICall = false;

                           deferred.resolve(element);

                       }, function(error) {
                           deferred.reject();
                           element.APICall = false;
                       });


                       return deferred.promise;
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#remove
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {string} page_id The page_id
                    * @param {function} ctdata The ctdata to delete.
                    *
                    * @description
                    * Add a new `ctdata` to the Service
                    *
                    * @returns {promise}
                    *  - Resolves When the ctdata was deleted
                    *  - Rejects if the ctdata could not be deleted
                    */
                   _CTServiceDataParent.remove = function(page_id, ctdata) {
                       if(!page_id) {
                           throw new Error('page_id is not defined');
                       }

                       //TODO: DELETE the ctdata
                       //TODO: Remove the ctdata from the array
                   };

                   _CTServiceDataParent.deleteFromBucket = function(page_id, filter, elementToRemove, identifyBy) {
                       if(!page_id) {
                           throw new Error(' is not defined');
                       }
                       if(!filter) {
                           throw new Error('Filter is not defined');
                       }
                       if(!elementToRemove) {
                           throw new Error('Element is not defined');
                       }

                       identifyBy = ((typeof identifyBy !== 'undefined') ? identifyBy : 'id');

                       var requestHash = getRequestHash(page_id, filter);

                       for(var index = 0; index < _loadedData[requestHash].length; index++) {
                           var element = _loadedData[requestHash][index];
                           if(element && element[identifyBy] == elementToRemove[identifyBy]) {
                               _loadedData[requestHash].splice(index, 1);
                               break;
                           }
                       }
                   };


                   /**
                    * @ngdoc method
                    * @name ct.services.data.service#addModuleConfig
                    * @methodOf ct.services.data.service:CTServiceDataParent
                    *
                    * @param {Object} config_object        the config object, with page_ids as keys, e.g. {MID1: {randomize: true}, MID2: {a:1, b:2}}
                    *
                    * @description
                    * Extends the config for a module with the config_object.
                    */
                   _CTServiceDataParent.addModuleConfig = function(config_object) {
                       angular.extend(_moduleConfig, config_object);
                   };


                   var getRequestHash = _CTServiceDataParent._getRequestHash = function(mid, filter) {
                       var hashObject = {};
                       angular.forEach(filter, function(value, key) {
                           if(value instanceof (CTPerson)) {
                               hashObject[key] = value.id;
                           }
                           else {
                               hashObject[key] = value
                           }
                       });
                       return mid + JSON.stringify(hashObject);
                   };

                   function shuffle(a) {
                       for(var b, c, d = a.length; d; b = Math.floor(Math.random() * d), c = a[--d], a[d] = a[b], a[b] = c) {
                           ;
                       }
                       return a
                   }

                   function toArray(a) {
                       var b = [];
                       for(var c in a) {
                           a.hasOwnProperty(c) && b.push(a[c]);
                       }
                       return b
                   }


                   /**
                    * Tests, if a key is set in the module config.
                    *
                    * ALWAYS USE IT before getModuleConfigForKey(page_id, key)
                    *
                    * @param page_id
                    * @param key
                    * @returns {boolean} If key is set in the config
                    */
                   function hasModuleConfigForKey(page_id, key) {
                       return (_moduleConfig && _moduleConfig.hasOwnProperty(page_id) && _moduleConfig[page_id].hasOwnProperty(key));
                   }

                   /**
                    * Returns the key in the config Values of the module.
                    *
                    * ALWAYS USE hasModuleConfigForKey(page_id, key) first to test, if value is set.
                    *
                    * @param {string} page_id
                    * @param {string} key The key to search for in the Module Config.
                    * @returns {*} The Value for the key in the module config
                    */
                   function getModuleConfigForKey(page_id, key) {
                       return (_moduleConfig[page_id][key]);
                   }

                   /**
                    * Init the service
                    * @param {string} route The route to use. Can be any resource route like `elements` or `events`
                    * @param {function} CTDataClass The constructor for any CTData class like `CTStory` or `CTEvent`
                    * @param {object} dataKeys The name of the key inside the responded JSON
                    * @param {object=} [service_config={}] An optional config object
                    * @returns {*}
                    */
                   function init(route, CTDataClass, dataKeys, service_config) {

                       _serviceDataRoute = route;
                       _CTDataClass      = CTDataClass;
                       _serviceConfig    = angular.extend({defaultFilterParams: {}}, service_config);
                       _moduleConfig     = {};
                       _serviceDataKeys  = dataKeys;

                       return _CTServiceDataParent;
                   }

                   return init(route, CTDataClass, dataKeys, service_config);
               };
           }
       ]);
