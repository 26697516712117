angular.ctModule('ct.services.ctGetCoordinates', [])

    /**
     * @ngdoc service
     * @name ct.services.ctGetCoordinates.service:CTGetCoordinates
     *
     * @author Michael Scharl <ms@campaigning-bureau.com>
     *
     * @description
     *
     * Get Coordinates from the mapbox geocoder
     */
    .service('CTGetCoordinates', [
        '$q',
        '$log',
        'CTConfig',
        function($q, $log, CTConfig) {

            /**
             * Cache CoordinateData
             *
             * @type {object}
             */
            var CTGetCoordinatesCache = {},

                /**
                 * Hold the geocoder
                 */
                geocoder,

                /**
                 * The Service itself
                 *
                 * @type {{}}
                 */
                ctGetCoordinatesService = this;


            function init() {
                geocoder = mapboxSdk({ accessToken: CTConfig.MAPBOX_ACCESS_TOKEN });
            }

            function callGeocoder(query, callback) {
                if (!geocoder) {
                    return callback(false);
                }

                ctGetCoordinatesService.APICall = true;

                /** Run the Query */
                geocoder.geocode(query, function(results, status) {

                    ctGetCoordinatesService.APICall = false;

                    if (status == google.maps.GeocoderStatus.OK) {
                        callback(results);
                    }
                    else {
                        callback(false);
                    }
                });
            }

            /**
             * @ngdoc method
             * @name ct.services.ctGetCoordinates.service#flush
             * @methodOf ct.services.ctGetCoordinates.service:CTGetCoordinates
             *
             * @description
             * Flush the Geocode cache
             */
            ctGetCoordinatesService.flush = function() {
                $log.debug('CTGetCoordinatesCache was cleared');
                CTGetCoordinatesCache = {};
            };

            /**
             * @ngdoc method
             * @name ct.services.ctGetCoordinates.service#byAddress
             * @methodOf ct.services.ctGetCoordinates.service:CTGetCoordinates
             *
             * @description
             * Load coordinates from a given address
             *
             * @param {string} street The Street
             * @param {string} city The City
             * @param {string|number} zip_code The ZipCode

             * @returns {promise} The Promise is solved with a {@link https://developers.google.com/maps/documentation/javascript/reference?csw=1#LatLng GoogleAPI LatLng}
             */
            ctGetCoordinatesService.byAddress = function(street, city, zip_code) {
                let geocoderDefer = $q.defer();

                geocoder.geocoding
                    .forwardGeocode({
                        query: street + ', ' + zip_code + ' ' + city,
                        countries: [
                            'AT',
                            'DE'
                        ],
                        autocomplete: false,
                        limit: 1
                    })
                    .send()
                    .then(function(response) {
                        if (response && response.body && response.body.features && response.body.features.length) {
                            geocoderDefer.resolve(response.body.features[0].center);
                        }
                        else {
                            geocoderDefer.reject();
                        }
                    }, function(error) {
                        geocoderDefer.reject();
                    });

                return geocoderDefer.promise;
            };


            init();
            return ctGetCoordinatesService;

        }
    ]);
