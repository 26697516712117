angular.ctModule('ct.directives', [
    'ct.directives.countTo',
    'ct.directives.labelHide',
    'ct.directives.imageUpload',
    'ct.directives.shareWindow',
    'ct.directives.stateButton',
    'ct.directives.textOrMedia',
    'ct.directives.setImageProportionClass',
    'ct.directives.mediaDrop',
    'ct.directives.parallax',
    'ct.directives.fixedBar',
    'ct.directives.resourceState',
    'ct.directives.infScroll'
]);
