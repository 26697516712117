/**
 * Angular Module "Masonry"
 *
 * @description This is a incredible simple module to combine Angular with Masonry
 *              The element with the attribute "masonry" becomes the masonry container.
 *              Each item inside the container needs the "masonry-brick" attribute set.
 *
 *              The "masonry-element" controller will initialize and save the MasonryObject as a Scope variable
 *              The "masonry-brick-elements" will append and remove themselves automatically
 *
 *              UPDATE (27.01.2014):
 *              The directive will push elements into masonry in their correct order defined by the markup.
 *
 *              UPDATE (29.01.2014):
 *              Load Masonry options from attributes - since masonry doesn't load it automatically
 *
 *              UPDATE (30.01.2014):
 *              Add a reset load index event handler
 *
 *              UPDATE (03.02.2014):
 *              Rewrite functionality for a more generic use
 *
 *              UPDATE (12.02.2014):
 *              Catch an IE <= 9 Bug
 *
 * @author Michael Scharl <michael.scharl@me.com>
 */
angular.ctModule('ct.directives.masonry', [])

       .value('masonryLoad', {index: 0})

       // Create the directive that initializes masony and saves it reference
       .directive('ctMasonry', [
           function() {
               var $masonry = {};

               // Restrict the directive to an attribute
               $masonry.restrict = 'A';

               // Create the controller
               $masonry.controller = [
                   "$scope",
                   "$element",
                   "$attrs",
                   "$log",
                   'masonryLoad',
                   function($scope, $element, $attributes, $log, masonryLoad) {

                       // Load Masonry options from attributes
                       var masonryOptions = angular.fromJson($attributes.masonryOptions);

                       if(typeof Masonry !== 'undefined') {
                           // Create Masonry and save it to the scope
                           $scope.Masonry = new Masonry($element[0], masonryOptions);
                       }
                       else {
                           $scope.Masonry = {
                               stamp   : function() {
                               },
                               unstamp : function() {
                               },
                               layout  : function() {
                               },
                               appended: function() {
                               },
                               remove  : function() {
                               }
                           };
                       }


                       // Add handler to reset the load index
                       $scope.$on('masonry.reset.loadindex', function() {
                           //$log.log('Reset Loadindex');
                           masonryLoad.index = 0;
                       });

                   }
               ];

               return $masonry;
           }
       ])


       // Create the directive that handles a stamp
       .directive('ctMasonryStamp', [
           function() {

               var $stamp = {};

               $stamp.restrict = 'A';

               $stamp.controller = [
                   "$scope", "$element", "$attrs", function($scope, $element, $attrs) {

                       function manageStamp() {
                           switch($scope.isVisible) {
                               case true:

                                   setTimeout(function() {
                                       imagesLoaded($element, function() {
                                           $scope.$apply(function() {
                                               $element.show();
                                               $scope.Masonry.stamp($element[0]);

                                               // Tell masonry to re-layout
                                               $scope.Masonry.layout();

                                               if(jQuery.fn.velocity) {
                                                   $element.hide().velocity('callout.tada', {display: 'block'});
                                               }
                                           });
                                       });
                                   }, 100); // we use this timeout to ensure that all markup is already written into the DOM -
                                            // otherwise "imagesLoaded" will not get our images

                                   break;
                               case false:

                                   $scope.Masonry.unstamp($element[0]);
                                   $element.hide();
                                   // Tell masonry to re-layout
                                   $scope.Masonry.layout();

                                   break;
                           }
                       }

                       if($attrs.hasOwnProperty('ctMasonryStampStatic')) {
                           $scope.$applyAsync(function() {
                               $element.show();
                               $scope.Masonry.stamp($element[0]);
                               $scope.Masonry.layout();
                           });
                       }
                       else {
                           $scope.$watch('isVisible', manageStamp);
                       }
                   }
               ];

               return $stamp;
           }
       ])


       // Create the directive that handles the binding and unbinding of each masonry child item
       .directive('ctMasonryBrick', [
           function() {
               var $brick = {};

               // Restrict the directive to an attribute
               $brick.restrict = 'A';

               $brick.controller = [
                   '$scope',
                   '$element',
                   'masonryLoad',
                   '$rootScope',
                   '$q',
                   '$log',
                   function($scope, $element, masonryLoad, $rootScope, $q, $log) {

                       // init Flags
                       var loadedForWall = false, // Will tell us if the element is ready to be displayed
                           showingOnWall = false; // Will tell us if the element is hidden or not

                       // Hide the element for startup
                       $element.hide();

                       //$log.log('Init: ', $scope.$index);

                       function showLoadedElement() {
                           //if(!loadedForWall && !showingOnWall) { $log.log('Ready for Display: ', $scope.$index, 'LoadIndex is
                           // ', masonryLoad.index); } If the element is loaded, not displayed and the next in the queue
                           if(loadedForWall && !showingOnWall && $scope.$index === masonryLoad.index) {
                               //$log.log('Show: ', $scope.$index);
                               // Show the element
                               if(jQuery.fn.velocity) {

                                   $element.show();
                                   addToMasonry();
                                   $element.hide().velocity('transition.bounceIn', {display: 'block'});

                               }
                               else {
                                   $element.show();
                                   addToMasonry();
                               }

                               // Set the show flag to true
                               showingOnWall = true;

                               // Increment the load index
                               masonryLoad.index += 1;

                               // Remove own Listener
                               showNextEventListener();

                               // Tell everyone we are ready to show the next item
                               $rootScope.$broadcast('masonry.show.next');
                           }
                       }

                       // Tell masonry about it
                       function addToMasonry() {
                           $scope.Masonry.appended($element[0]);
                           //$log.log('Add to Masonry', $scope.$index);
                       }

                       function imagesDidLoad() {
                           // We can save that our element is ready to be displayed
                           loadedForWall = true;
                           showLoadedElement();
                       }

                       var checkIfImageHasDimensionsLoop = 0;

                       function checkIfImageHasDimensions() {
                           //$log.log('Image should be loaded:', $scope.$index);
                           var checkImages = $q.defer();
                           checkImages.promise.then(imagesDidLoad, function() {
                               //$log.info('Image not loaded try again', $scope.$index);
                               setTimeout(checkIfImageHasDimensions, 50);
                           });

                           if(checkIfImageHasDimensionsLoop++ < 25) {
                               var images = $element.find('img');

                               if(images.length) {
                                   images.each(function(index, image) {
                                       var width  = (image.naturalWidth || image.width) || 0,
                                           height = (image.naturalHeight || image.height) || 0;

                                       //$log.log('Check image', image, (image.naturalWidth || image.width), (image.naturalHeight
                                       // || image.height));

                                       if(!(width > 0 && height > 0)) {
                                           checkImages.reject();
                                           //$log.log('Retry', $scope.$index);
                                       }
                                       else {
                                           //$log.log('Did load', $scope.$index);
                                           checkImages.resolve();
                                       }
                                   });
                               }
                               else {
                                   checkImages.resolve();
                               }
                           }
                           else {
                               checkImages.resolve();
                           }
                       }

                       var showNextEventListener = $scope.$on('masonry.show.next', function() {
                           showLoadedElement();
                       });

                       //Add Element to Masonry after all images are loaded
                       setTimeout(function() {
                           //$log.log('Trigger image load', $scope.$index);
                           imagesLoaded($element, checkIfImageHasDimensions);
                       }, 200); // we use this timeout to ensure that all markup is already written into the DOM - otherwise
                                // "imagesLoaded" will not get our images

                       // Remove Element from Masonry when it´s removed from the DOM
                       $scope.$on('$destroy', function() {
                           // Remove the element from Masonry
                           try {
                               $scope.Masonry.remove($element[0]);
                           } catch(e) {
                           }
                           // Tell masonry to re-layout
                           $scope.Masonry.layout();
                       });

                   }
               ];

               return $brick;
           }
       ])
;
