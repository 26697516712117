angular.ctModule('ct.services.confirmationDialog', ['ui.bootstrap.modal']).service('CTConfirmationDialog', [
    "$modal", function($modal) {
        /**
         * CTConfirmationDialog
         *
         * @param {string} title The title of the dialog
         * @param {string} [message] The message of the dialog
         * @param {string} [action] The action, that will be displayed in the OK-Button
         */
        return function CTConfirmationDialog(title, message, action, cancel) {

            return $modal.open({
                template      : '<div class="ct-modal md:w-80">' + '<div class="modal-header">' + '<h4 class="modal-title">{{ modal.title }}</h4>' + '</div>' + '<div class="modal-body" ng-if="modal.message">' + '<p>{{ modal.message }}</p>' + '</div>' + '<div class="modal-footer">' + '<div class="row">' + '<div class="col-sm-9">' + '<button class="btn btn-lg btn-block btn--cta" ng-click="ok()">{{ modal.action }}</button> ' + '</div>' + '<div class="col-sm-3">' + '<button class="btn btn-block btn-link btn-link--underlined btn-lg text-danger" ng-click="cancel()">{{ modal.cancel }}</button> ' + '</div>' + '</div>' + '</div>' + '</div>',
                windowTemplate: "<div tabindex=\"-1\" class=\"modal fade ct__info_modal {{ windowClass }}\" ng-class=\"{in: animate}\" ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\" ng-click=\"close($event)\">\n" + "    <div class=\"modal-dialog\"><div class=\"modal-content\" ng-transclude></div></div>\n" + "</div>",
                backdrop      : false,
                keyboard      : false,
                controller    : [
                    "$scope",
                    "$modalInstance",
                    "$timeout",
                    function CTConfirmationDialogController($scope, $modalInstance, $timeout) {
                        $scope.modal = {
                            title: title, message: message, action: action, cancel: (cancel ? cancel : 'Doch nicht!')
                        };

                        $scope.ok     = function() {
                            $modalInstance.close('ok');
                        };
                        $scope.cancel = function() {
                            $modalInstance.dismiss('cancel');
                        };
                    }
                ]
            });

        };
    }
]);
