angular.ctModule('ct.directives.setImageProportionClass', [])

       /**
        * @ngdoc directive
        * @name ct.directives.directive:setImageProportionClass
        * @restrict A
        *
        * @requires $compile
        *
        * @author Rainer Angerer <rainer.angerer@campaigning-bureau.com>
        *
        * @description
        * Sets class 'img-portrait' if the Image Ratio is portrait, else it sets the class 'img-landscape'
        *
        * @example
        * <doc:example module="ct.directives.setImageProportionClass">
        <doc:source>

        <img src="http://lorempixel.com/output/animals-q-c-640-480-9.jpg" data-set-image-proportion-class>
        <img src="http://lorempixel.com/output/nightlife-h-g-490-583-1.jpg" data-set-image-proportion-class>
        </doc:source>
        </doc:example>
        */

       .directive('setImageProportionClass', [
           "$log", function($log) {

               var _setImageProportionClassDirective = {};

               _setImageProportionClassDirective.restrict = 'A';

               _setImageProportionClassDirective.link = function($scope, $element, $attr) {

                   var actualImage;

                   function calculateImageClass() {

                       if(actualImage.width && actualImage.height) {
                           if(actualImage.width > actualImage.height) {
                               $element.removeClass('img-portrait').addClass('img-landscape');
                           }
                           else {
                               $element.removeClass('img-landscape').addClass('img-portrait');
                           }
                       }
                       else {
                           setTimeout(calculateImageClass, 50);
                       }
                   }

                   /**
                    * Due to Firefox Issues (new IE), we have to load the original Image
                    * and calculate the dimensions from there
                    */
                   function setupImageCalculation() {
                       actualImage     = new Image();
                       actualImage.src = $attr.src;
                       calculateImageClass();
                   }


                   $scope.$watch(function() {
                       return $attr.src
                   }, function() {
                       setupImageCalculation();
                   });
               };

               return _setImageProportionClassDirective;
           }
       ]);
