/**
 * Polyfill for `Object.keys`
 *
 * @param {object} object The object to extract tje keys
 * @returns {Array}
 */
const ObjectKeys = function(object) {
    const keys = [];
    for(let key in object) {
        if(object.hasOwnProperty(key)) {
            keys.push(key);
        }
    }

    return keys;
};
