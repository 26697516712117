angular.ctModule('ct.services.user', ['ct.constant', 'ui.bootstrap', 'ct.services.facebook'])

       /**
        * @ngdoc service
        * @name ct.services.user.service:CTServiceUser
        *
        *
        * @author Stefan Schindler <stefan.schindler@campaigning-bureau.com>
        * @description
        * tbd
        *
        */
       .service('CTServiceUser', [
           "$rootScope",
           "$q",
           "$log",
           "ctAuthenticationEvents",
           "CTServiceAuthentication",
           "CTServiceLogin",
           function($rootScope, $q, $log, ctAuthenticationEvents, CTServiceAuthentication, CTServiceLogin) {
               "use strict";

               var
                   /** @access private */
                   CTServiceUser        = this,

                   /**
                    * Holds the currentUser Object or false if no user is set
                    * @type (object|boolean)
                    * @access private
                    */
                   _currentUser         = false,

                   /** @access private */
                   ctUserCookies        = {
                       UserIdentifier: false,
                       UserNonce     : false
                   },

                   /** @access private */
                   pwSetPromise         = $q.defer(),

                   /** @access private */
                   pwSetInProgress      = false,
                   /** @access private */
                   deleteUserPromise    = $q.defer(),
                   /** @access private */
                   deleteUserInProgress = false;

               /**
                * set the current user
                * @param user
                */
               CTServiceUser.setUser = function(user) {
                   _currentUser = user;
               };

               /**
                * open the set password dialog. returns a promise.
                * the promise is resolved, if the set password modal was successfully resolved, else it gets rejected
                *
                * @returns {Promise.promise|*}
                */
               CTServiceUser.setPassword = function() {
                   // no user -> reject promise
                   if(_currentUser === false) {
                       pwSetPromise.reject();
                   }

                   if(!pwSetInProgress) {
                       pwSetInProgress  = true;
                       var modalPromise = CTServiceLogin.setPassword(_currentUser);
                       modalPromise.result.then(function() {
                           pwSetPromise.resolve();
                           pwSetInProgress = false;
                       }, function(data) {
                           $log.error(data);
                           pwSetPromise.reject(data);
                           pwSetInProgress = false;
                       });
                   }

                   return pwSetPromise.promise;
               };

               /**
                * opens the delete user modal. returns a promise.
                * the promise is resolved, if the delete user modal was successfully resolved, else it gets rejected
                * @returns {Promise.promise|*}
                */
               CTServiceUser.deleteUser = function() {
                   // no user -> reject promise
                   if(_currentUser === false) {
                       deleteUserPromise.reject();
                   }

                   if(!deleteUserInProgress) {
                       deleteUserInProgress = true;
                       var modalPromise     = CTServiceLogin.deleteUser(_currentUser);
                       modalPromise.result.then(function() {
                           deleteUserPromise.resolve();
                           deleteUserInProgress = false;
                           CTServiceAuthentication.logout();
                       }, function(data) {
                           $log.error(data);
                           deleteUserPromise.reject(data);
                           deleteUserInProgress = false;
                       });
                   }

                   return deleteUserPromise.promise;
               };

               /**
                * Init function to startup everything
                *
                * @access private
                */
               function init() {

                   pwSetPromise.promise["finally"](function() {
                       pwSetInProgress = false;
                   });
               }


               //After all - Start it up
               init();
           }
       ])

       .run([
           "CTServiceAuthentication",
           "CTServiceHelper",
           "CTServiceUser",
           "CTServiceLogin",
           "$location",
           "$log",
           "$rootScope",
           function(CTServiceAuthentication, CTServiceHelper, CTServiceUser, CTServiceLogin, $location, $log, $rootScope) {
               // check if GET Params exist
               var action = CTServiceHelper.getParameterByName('action');

               if(action !== '') {
                   CTServiceAuthentication.isLoggedIn().then(function() {
                       CTServiceUser.setUser(CTServiceAuthentication.getCurrentUser());
                       switch(action) {
                           case 'user.set_password':
                               CTServiceUser.setPassword();
                               break;
                           case 'user.delete_account':
                               CTServiceUser.deleteUser();
                               break;
                           default:
                               break;
                       }
                   });
               }
               else {
                   $log.info('no action');
               }
           }
       ]);
