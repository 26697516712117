angular.ctModule('ct.services.events', [
    'ct.filters.truncate',
    'ct.services.ctGetCoordinates',
    'ct.services.authentication',
    'ct.constant',
    'ct.services.data'
])

       .constant('CTServiceEventsEvents', {
           RELOAD: 'CTServiceEventsEvents.RELOAD'
       })

       .constant('CTServiceEventsLoadingDefaults', {
           events_from: CT_date2string(new Date(), 'date'),
           // TUGU-340: Events bitte standardmäßig nach Enddatum aufsteigend sortiert zurueckgeben.
           order_by   : 'starts_at',
           order_dir  : 'asc'
           // end TUGU-340
       })

       /**
        * @ngdoc service
        * @name ct.services.events.service:CTServiceEvents
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * A service to manage all events
        */
       .service('CTServiceEvents', [
           "$q",
           "CTServiceEventsEvents",
           "$rootScope",
           "CTServiceEventsLoadingDefaults",
           "CTServiceDataParent",
           "CTServiceAuthentication",
           function($q, CTServiceEventsEvents, $rootScope, CTServiceEventsLoadingDefaults, CTServiceDataParent, CTServiceAuthentication) {

               /**
                * @access private
                */
               var ctServiceEvents = new CTServiceDataParent('events', function(data) {
                   return new CTEvent(data);
               }, {multiple: 'events', single: 'event'}, {defaultFilterParams: CTServiceEventsLoadingDefaults});

               // Enable cached routes
               ctServiceEvents.setUseCachedRoutes(true);

               /**
                * A multi dimensional Object/Array.
                * The first layer is keyed with the page id. It's element is
                * an array of events
                *
                * @type {object}
                * @private
                */
               var _loadedEvents            = {},

                   /**
                    * This object is keyed with the page id. It's element is a
                    * `$q.defer()` object
                    * @type {object}
                    * @private
                    */
                   _loadedEventsPromises    = {},

                   /**
                    * Total number of available events
                    * @type {object}
                    * @private
                    */
                   _totalEventsLoading      = {},

                   /**
                    * Total number of available events
                    * @type {object}
                    * @private
                    */
                   _totalEvents             = {},

                   /**
                    * Is it completely loaded
                    * @type {object}
                    * @private
                    */
                   _reachedTheEnd           = {},

                   /**
                    * Calculated next ids
                    * @type {number}
                    * @private
                    */
                   _nextEventIdPromises     = {},

                   /**
                    * Calculated previous ids
                    * @type {number}
                    * @private
                    */
                   _previousEventIdPromises = {},

                   /**
                    * Hold defereds of counters
                    *
                    * @type {object}
                    * @private
                    */
                   _loadedCountDeferreds    = {},

                   /**
                    * Holds deferred objects for 'my events'
                    * @type {{}}
                    * @private
                    */
                   _loadedMyEvents          = {},

                   /**
                    * Holds deferred objects for get
                    * @type {{}}
                    * @private
                    */
                   _getSingleEventPromise   = {};


               /**
                * @ngdoc method
                * @name ct.services.storywall.service#count
                * @methodOf ct.services.storywall.service:CTServiceStories
                *
                * @param {string} page_id The page id
                * @param {string} filter
                * A string that identifies a predefined filter
                *  - `new`: All new Stories
                *  - `active`: All active Stories
                *  - `blocked`: All blocked Stories
                *
                * @description
                * Add a new `CTStory` to the Service
                *
                * @returns {promise}
                *  - `true` if the story was added to the service
                *  - `false` if there was a problem. Probably because of no `id`
                */
               ctServiceEvents.count = function(page_id, filter) {
                   if(!page_id) {
                       throw new Error('Page id is not defined');
                   }

                   if(!_loadedCountDeferreds[page_id]) {
                       _loadedCountDeferreds[page_id] = {};
                   }

                   if(!_loadedCountDeferreds[page_id][filter]) {
                       _loadedCountDeferreds[page_id][filter] = $q.defer();

                       _loadedCountDeferreds[page_id][filter].promise.then(function() {
                           _loadedCountDeferreds[page_id][filter] = false;
                       }, function() {
                           _loadedCountDeferreds[page_id][filter] = false;
                       });

                       var _filterParams = {
                           counter_only: 1
                       };

                       switch(filter) {
                           case 'new':
                               _filterParams.state = 0;
                               break;

                           case 'active':
                               _filterParams.state = 1;
                               break;

                           case 'blocked':
                               _filterParams.state = 2;
                               break;
                       }

                       $CB_API({
                           method: 'GET',
                           url   : '/pages/' + page_id + '/events',
                           params: _filterParams
                       }).then(function(data) {
                           _loadedCountDeferreds[page_id][filter].resolve(data.total_count);
                       }, function(error) {
                           _loadedCountDeferreds[page_id][filter].reject(error);
                       });
                   }

                   return _loadedCountDeferreds[page_id][filter].promise;
               };

               /**
                * return 'my events'
                *
                * @param page_id
                * @param user_identifier
                * @param filter
                * @returns {*}
                */
               ctServiceEvents.getMyEvents = function(page_id, user_identifier, filter) {
                   if(!page_id) {
                       throw new Error('page id is not defined');
                   }

                   var requestHash = ctServiceEvents._getRequestHash(page_id, filter);

                   if(!_loadedMyEvents[requestHash]) {
                       _loadedMyEvents[requestHash] = {};
                   }

                   if(!_loadedMyEvents[requestHash][user_identifier]) {
                       _loadedMyEvents[requestHash][user_identifier] = {};
                   }

                   if(!_loadedMyEvents[requestHash][user_identifier][filter]) {
                       _loadedMyEvents[requestHash][user_identifier][filter] = $q.defer();

                       $CB_API({
                           method: 'GET',
                           url   : '/users/' + user_identifier + '/myevents/' + page_id,
                           params: filter
                       }).then(function(data) {
                           var eventObjects = [];
                           angular.forEach(data.events, function(event) {
                               var _element = new CTEvent(angular.extend(angular.copy(event), {
                                   page_id: page_id
                               }));

                               eventObjects.push(_element);
                           });

                           _loadedMyEvents[requestHash][user_identifier][filter].resolve(eventObjects);
                       }, function(error) {
                           _loadedMyEvents[requestHash][user_identifier][filter].reject(error);
                       });
                   }

                   return _loadedMyEvents[requestHash][user_identifier][filter].promise;
               };


               var original_ctServiceEventsGet = ctServiceEvents.get;
               /**
                * @ngdoc method
                * @name ct.services.data.service#get
                * @methodOf ct.services.data.service:CTServiceDataParent
                *
                * @param {string} page_id The module identifier to use to load
                * @param {number|string} id The id to load
                *
                * @description
                * Get a specific story
                *
                * @returns {promise}
                *  - Resolves with `CTData`
                *  - Rejects with
                *    - `reset` If a reset is called during the call
                *    - `failed` If the story couldn´t be loaded
                */
               ctServiceEvents.get = function(page_id, id) {
                   const doEventRequest = () => $CB_API({
                       method: 'GET',
                       url   : `/pages/${page_id}/events/${id}`,
                       params: {}
                   }).then((data) => new CTEvent(data.event), (error) => {
                       console.error(error);
                       return $q.reject(error);
                   });

                   if(!_getSingleEventPromise[page_id + '.' + id]) {
                       _getSingleEventPromise[page_id + '.' + id] = CTServiceAuthentication
                           .isLoggedIn()
                           .then(doEventRequest, doEventRequest);
                   }

                   return _getSingleEventPromise[page_id + '.' + id];
               };


               /**
                * @ngdoc method
                * @name ct.services.events.service#pushEvent
                * @methodOf ct.services.events.service:CTServiceEvents
                *
                * @param {string} page_id The module identifier to check
                * @param {CTEvent} event The event to add
                *
                * @description
                * Add an event to the unfiltered array
                *
                */
               ctServiceEvents.pushEvent = function(page_id, event) {
                   return ctServiceEvents.add(page_id, event);
               };


               /**
                * @ngdoc method
                * @name ct.services.events.service#resetEvents
                * @methodOf ct.services.events.service:CTServiceEvents
                *
                * @description
                * Resets all loaded data
                *
                */
               var original_ctServiceEvents_reset = ctServiceEvents.reset;
               ctServiceEvents.reset              = function(page_id) {
                   original_ctServiceEvents_reset(page_id);

                   $rootScope.$broadcast(CTServiceEventsEvents.RELOAD);
               };

               /**
                * @ngdoc method
                * @name ct.services.data.service#getAllFiltered
                * @methodOf ct.services.data.service:CTServiceDataParent
                *
                * @param {string} page_id The module identifier to use to load
                * @param {object} filter The options to use for filtering
                *
                * @description
                * Get the filtered array of available Elements for the given module
                *
                * @returns {promise} Will be resolved with the filtered elements array
                */
               ctServiceEvents.getAllFiltered = function(page_id, filter) {
                   if(!page_id) {
                       throw new Error('page id is not defined');
                   }

                   return ctServiceEvents.getFilteredUntilPage(page_id, filter, Infinity, 60);
               };


               return ctServiceEvents;
           }
       ]);
