angular.ctModule('ct.services.facebook', [])

       /**
        * @ngdoc service
        * @name ct.services.facebook.service:CTServiceFacebook
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * This service provides some functionallity to load Facebook data with promises.
        *
        * It automatically checks if ``FB`` available of the and rejects a call when it is not loaded
        */
       .service('CTServiceFacebook', [
           "$rootScope", "$q", function($rootScope, $q) {

               var
                   /** @access private */
                   ctServiceFacebook        = this,

                   /** @type object Hold the defered object for checking if FB is available */
                   FBIsAvailable            = $q.defer(),

                   /** @type int Hold the number of tries to load FB */
                   triedFBLoadingCount      = 0,

                   /** @type int Hold the number of maximum tries to load FB */
                   triedFBLoadingMax        = 10,

                   /**
                    * Holds the current facebook user Id or false if no user is set
                    * @type (string|boolean)
                    * @access private
                    */
                   currentFacebookId        = false,

                   /**
                    * holds the promise that checks the facebook id
                    * @type {promise}
                    * @access private
                    */
                   currentFacebookIdPromise = $q.defer(),

                   /**
                    * holds functions that are called when the user successfully signed up via facebook.
                    *
                    * @type {Array}
                    */
                   signupCallbackArray      = [];


               /**
                * @ngdoc method
                * @name ct.services.facebook.service#api
                * @methodOf ct.services.facebook.service:CTServiceFacebook
                *
                * @description
                * Wrapper for {@link https://developers.facebook.com/docs/javascript/reference/FB.api FB.api}
                *
                * @param {string} path This is the Graph API endpoint path that you want to call.
                * @param {string} method This is the HTTP method that you want to use for the API request.
                * @param {object} params This is an object consisting of any parameters that you want to pass into your Graph API call.
                *
                * @returns {promise} The promise that will be resolved with the FB api result
                */
               ctServiceFacebook.api = function(path, method, params) {
                   //Wrap the Facebook call with the FacebookCall function to automatically reject a APICall when FB is not loaded
                   return FacebookCall(function(apiDefer) {

                       //Now call the Facebook API
                       FB.api(path, method, params, function(facebookResponse) {

                           //The API returned nothing or an error
                           if(!facebookResponse || facebookResponse.error) {
                               apiDefer.reject(facebookResponse);
                           }

                           //The API returned our data
                           else {
                               apiDefer.resolve(facebookResponse);
                           }
                       });
                   });
               };


               /**
                * @ngdoc method
                * @name ct.services.facebook.service#me
                * @methodOf ct.services.facebook.service:CTServiceFacebook
                *
                * @description
                * Wrapper for loading data about the current user. Prefilled with the most common fields (also see ct.services.facebook.service#api)
                *
                * @param {object} params This is an object consisting of any parameters that you want to pass into your Graph API call.
                *
                * @returns {promise} The promise that will be resolved with the FB api result
                */
               ctServiceFacebook.me = function(params) {
                   params = angular.extend({fields: "first_name, last_name, email"}, (params || {}));
                   return ctServiceFacebook.api('/me', params);
               };

               /**
                * @ngdoc method
                * @name ct.services.facebook.service#ui
                * @methodOf ct.services.facebook.service:CTServiceFacebook
                *
                * @description
                * Wrapper for {@link https://developers.facebook.com/docs/javascript/reference/FB.ui FB.ui}
                *
                * @param {object} params A collection of parameters that control which dialog is loaded, and relevant settings.
                *
                * @returns {promise} The promise that will be resolved with the FB api result
                */
               ctServiceFacebook.ui = function(params) {
                   //Wrap the Facebook call with the FacebookCall function to automatically reject a APICall when FB is not loaded
                   return FacebookCall(function(apiDefer) {

                       //Now call the Facebook API
                       FB.ui(params, function(facebookResponse) {

                           //The API returned nothing or an error
                           if(!facebookResponse || facebookResponse.error_code) {
                               apiDefer.reject(facebookResponse);
                           }

                           //The API returned our data
                           else {
                               apiDefer.resolve(facebookResponse);
                           }
                       });
                   });
               };


               /**
                * @ngdoc method
                * @name ct.services.facebook.service#login
                * @methodOf ct.services.facebook.service:CTServiceFacebook
                *
                * @description
                * Wrapper for {@link https://developers.facebook.com/docs/reference/javascript/FB.login FB.login}
                *
                * @param {object} opts Options to modify login behavior. See {@link https://developers.facebook.com/docs/reference/javascript/FB.login/v2.0#options FB.login} for properties you can set on the object.
                *
                * @returns {promise} The promise that will be resolved with the FB api result
                */
               ctServiceFacebook.login = function(opts) {
                   //Wrap the Facebook call with the FacebookCall function to automatically reject a APICall when FB is not loaded
                   return FacebookCall(function(apiDefer) {

                       //Now call the Facebook API
                       FB.login(function(facebookResponse) {

                           //The API returned nothing or an error
                           if(!facebookResponse || facebookResponse.error_code) {
                               apiDefer.reject(facebookResponse);
                           }

                           //The API returned our data
                           else {
                               apiDefer.resolve(facebookResponse);
                               // if signup callbacks are registered, they are now called
                               launchSignupCallbacks(facebookResponse);
                           }
                       }, opts);
                   });
               };


               /**
                * @ngdoc method
                * @name ct.services.facebook.service#FBIsAvailable
                * @methodOf ct.services.facebook.service:CTServiceFacebook
                *
                * @description
                * returns a promise to detect if Facebook is loaded
                *
                * @returns {promise} The promise that will be resolved with the FB api result
                */
               ctServiceFacebook.FBIsAvailable = function() {
                   return FBIsAvailable.promise;
               };

               /**
                * @ngdoc method
                * @name ctServiceFacebook.service#getCurrentUsersFBId
                * @methodOf ct.services.facebook.service:CTServiceFacebook
                *
                * @description
                * Return the Facebook ID of the currently logged in user or false, if no user is available
                *
                * @returns {promise} The FacebookID Promise that resolves with the facebook ID of the currently logged in user, or false, if no user is available
                */
               ctServiceFacebook.getCurrentUsersFBId = function() {

                   if(!currentFacebookId) {
                       ctServiceFacebook.FBIsAvailable().then(function() {

                           //If Facebook is available ask for the login status
                           FB.getLoginStatus(function(fbResponse) {
                               if(fbResponse.status === 'connected') {
                                   //If User is logged in resolve the promise
                                   currentFacebookId = fbResponse.authResponse.userID;
                                   currentFacebookIdPromise.resolve(currentFacebookId);
                               }
                               else {
                                   //If user is not logged in reject the promise
                                   currentFacebookIdPromise.reject();
                               }
                           });
                       }, function() {
                           //If Facebook is not available reject the promise
                           currentFacebookIdPromise.reject();
                       });
                   }

                   return currentFacebookIdPromise.promise;
               };

               /**
                * @ngdoc method
                * @name ct.services.facebook.service#signupCallback
                * @methodOf ct.services.facebook.service:CTServiceFacebook
                *
                * @description
                * registers a method as a signup callback.
                * pushes the method into the signup callback array.
                *
                * @param callback
                */
               ctServiceFacebook.signupCallback = function(callback) {
                   signupCallbackArray.push(callback);
               };

               /**
                * Handle all FB calls
                *
                * @param {function} available_callback the function that will be called if Facebook is available
                * @returns {object}
                */
               function FacebookCall(available_callback) {
                   var apiDefer = $q.defer();

                   ctServiceFacebook.FBIsAvailable().then(
                       //Run callback if available
                       function() {
                           available_callback(apiDefer);
                       },

                       //Reject when not available
                       function() {
                           apiDefer.reject('Facebook not loaded');
                       }
                   );

                   return apiDefer.promise;
               }


               /**
                * Check if Facebook is available
                * Will resolve if available or reject if can't be loaded
                *
                * @access private
                */
               function checkFacebookIsLoaded() {
                   triedFBLoadingCount++;

                   //Facebook is now available
                   if(typeof FB !== "undefined") {
                       FBIsAvailable.resolve();
                   }

                   //Try again
                   else if(triedFBLoadingCount < triedFBLoadingMax) {
                       setTimeout(checkFacebookIsLoaded, 500);
                   }

                   //Facebook seems not to be available
                   else {
                       FBIsAvailable.reject();
                   }
               }


               /**
                * launch all registered signup callbacks.
                * returns the respone that was received by the FB.login method.
                *
                * @param data
                */
               function launchSignupCallbacks(data) {
                   angular.forEach(signupCallbackArray, function(callback) {
                       callback(data);
                   });
               }

               function init() {
                   checkFacebookIsLoaded();
               }


               init();
               return ctServiceFacebook;
           }
       ]);
