angular.ctModule('ct.services.events').service('CTServiceEventUpdate', [
    "$modal", function($modal) {
        /**
         * CTInfoDialog
         *
         * @param {CTEvent} event The event to publish the update to
         */
        return function CTServiceEventUpdate(event) {

            return $modal.open({
                templateUrl: 'ct.services.events.eventUpdate.html',
                controller : [
                    "$scope",
                    "$modalInstance",
                    "CTServiceHelper",
                    function CTServiceEventUpdateController($scope, $modalInstance, CTServiceHelper) {
                        $scope.update = {
                            title      : '',
                            description: '',
                            shout_out  : false,
                            image_id   : null
                        };

                        function init() {
                            CTServiceHelper.trackPageView('/edit/update');
                        }

                        /**
                         * Callback for image uploads
                         * @param imageId
                         * @param imageUrl
                         */
                        $scope.onupload = function(imageId, imageUrl) {
                            if(imageId) {
                                $scope.update.image_id = imageId;
                            }
                            else {
                                $scope.resetSelectedMedia();
                            }
                        };

                        /**
                         * Remove selected image data
                         */
                        $scope.resetSelectedMedia = function() {
                            $scope.update.image_id = null;
                        };

                        $scope.submitUpdate = function() {
                            $CB_API({
                                method: 'POST',
                                url   : '/pages/' + event.page_id + '/events/' + event.id + '/updates',
                                data  : $scope.update
                            }).then(function() {
                                $modalInstance.close();
                                event.loadBlogEntries();
                            });
                        };

                        $scope.cancel = function() {
                            $modalInstance.dismiss();
                        };

                        init();
                    }
                ]
            });

        };
    }
]);
