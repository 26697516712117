'use strict';

/**
 * @ngdoc directive
 * @name videoDirectiveApp.directive:multiVideo
 * @scope
 * @description
 * # multiVideo
 */
angular.ctModule('multiVideoDirective', [])
       .constant('multiVideoTemplates', {
           youtube        : '<iframe class="embed-responsive-item" ng-src="{{ embedUrl }}" frameborder="0"/>',
           vimeo          : '<iframe class="embed-responsive-item" ng-src="{{ embedUrl }}" frameborder="0"></iframe>',
           instagram      : '<video class="embed-responsive-item" ng-src="{{ embedUrl }}" controls>Ihr Browser ist leider zu alt um dieses Video darzustellen. (<a href="http://browsehappy.com/?locale=de" target="_blank">Hier aktualisieren</a>)</video>',
           instagram_embed: '<iframe class="embed-responsive-item" ng-src="{{ embedUrl }}" frameborder="0"></iframe>',
           facebook       : '<video class="embed-responsive-item" controls><source ng-src="{{ embedUrl }}" type="video/mp4">Ihr Browser ist leider zu alt um dieses Video darzustellen. (<a href="http://browsehappy.com/?locale=de" target="_blank">Hier aktualisieren</a>)</video>'
       })
       .run(function() {
           angular.element("<style type=\"text/css\">.embed-responsive.embed-responsive-instagram.embed-responsive-instagram{padding-bottom:calc(100% + 80px);max-height:100%;}</style>")
                  .appendTo('head');
       })
       .directive('multiVideo', [
           '$compile', '$sce', 'multiVideoTemplates', function($compile, $sce, multiVideoTemplates) {
               return {
                   restrict: 'A',
                   replace : true,
                   scope   : {
                       multiVideo: '@',
                       thumbnail : '@',
                       params    : '@'
                   },
                   link    : function link($scope, $element, $attrs) {
                       var _queryParams = {};


                       /* Converts an object into a key/value par with an optional prefix. Used for converting objects to a query string */
                       function queryString(obj, prefix) {
                           var str = [];
                           for(var p in obj) {
                               var k = prefix ? prefix + "[" + p + "]" : p,
                                   v = obj[k];
                               str.push(angular.isObject(v) ? qs(v, k) : (k) + "=" + encodeURIComponent(v));
                           }
                           return str.join("&");
                       }

                       function getProvider() {
                           if($scope.multiVideo.indexOf('youtube') >= 0 || $scope.multiVideo.indexOf('youtu.be') >= 0) {
                               return 'youtube';
                           }

                           else if($scope.multiVideo.indexOf('vimeo.com') >= 0) {
                               return 'vimeo';
                           }

                           else if($scope.multiVideo.indexOf('instagram.com') >= 0) {
                               if($scope.multiVideo.indexOf('/embed') >= 0) {
                                   return 'instagram_embed';
                               }
                               else {
                                   return 'instagram';
                               }
                           }

                           else if($scope.multiVideo.indexOf('video.') > 0 && $scope.multiVideo.indexOf('.fbcdn.') > 0) {
                               return 'facebook';
                           }
                       }

                       function setEmbedUrl() {
                           var regExp, match;

                           switch(getProvider()) {
                               case 'youtube':

                                   regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??(v=)?([^#\&\?]*).*/;
                                   match  = $scope.multiVideo.match(regExp);

                                   if(match && match[8]) {
                                       $scope.embedUrl = $sce.trustAsResourceUrl("https://www.youtube-nocookie.com/embed/" + match[8] + '?' + queryString(_queryParams));
                                   }

                                   break;

                               case 'vimeo':

                                   regExp = /vimeo.com\/(\d+)\/?/;
                                   match  = $scope.multiVideo.match(regExp);

                                   if(match && match[1]) {
                                       $scope.embedUrl = $sce.trustAsResourceUrl("https://player.vimeo.com/video/" + match[1] + '?' + queryString(_queryParams));
                                   }

                                   break;

                               case 'instagram_embed':
                                   $element.addClass('embed-responsive-instagram');
                               case 'instagram':
                                   $scope.embedUrl = $sce.trustAsResourceUrl($scope.multiVideo);
                                   break;

                               case 'facebook':
                                   $scope.embedUrl = $sce.trustAsResourceUrl($scope.multiVideo);
                                   break;
                           }

                       }

                       function getEmbedCode() {
                           return angular.element(multiVideoTemplates[getProvider()]);
                       }

                       function runEmbedCode() {
                           setEmbedUrl();

                           var embedCode = getEmbedCode();
                           embedCode     = $compile(embedCode)($scope);

                           $element.html(embedCode);
                       }

                       $scope.$watch(function() {
                           return JSON.stringify({
                               multiVideo: $scope.multiVideo,
                               thumbnail : $scope.thumbnail,
                               params    : $scope.params
                           });
                       }, function() {
                           _queryParams = $scope.$eval($scope.params);
                           runEmbedCode();
                       });
                   }
               };
           }
       ]);
