angular.ctModule('ct.directives.infScroll', [])
       /** TO BE DONE!
        * @ngdoc directive
        * @name ct.directives.directive:selecty
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Wrap markup to make a styleable SelectField
        *
        * @example
        <doc:example module="ct.directives.selecty">
        <doc:source>
        <script>
        angular.module('ct.directives.selecty').controller('testCtrl', ["$scope", function($scope) {
        $scope.filter = {};
        $scope.filter.categories = {"eins":{id:1,name:"Test 1"}, "zwei":{id:2,name:"Test 2"}, "drei":{id:3,name:"Test 3"}};
        $scope.filter.category = 3;
    }]);
        </script>
        <div ng-controller="testCtrl">
        <h2>Selected: {{filter.category}}</h2>
        <div data-selecty data-ng-model="filter.category" data-ng-options="value.id as value.name for (key, value) in filter.categories" class="form-control"></div>
        </div>
        </doc:source>
        </doc:example>
        */
       .directive('infScroll', [
           "$parse", "$timeout", function($parse, $timeout) {

               var _infScroll = {};

               function getViewport() {

                   var viewPortWidth  = 0,
                       viewPortHeight = 0;

                   // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
                   if(typeof window.innerWidth != 'undefined') {
                       viewPortWidth  = window.innerWidth;
                       viewPortHeight = window.innerHeight;
                   }

                   // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
                   else if(typeof document.documentElement != 'undefined'
                       && typeof document.documentElement.clientWidth !=
                       'undefined' && document.documentElement.clientWidth != 0) {
                       viewPortWidth  = document.documentElement.clientWidth;
                       viewPortHeight = document.documentElement.clientHeight;
                   }

                   // older versions of IE
                   else {
                       viewPortWidth  = document.getElementsByTagName('body')[0].clientWidth;
                       viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
                   }

                   return {width: viewPortWidth, height: viewPortHeight};
               }

               //Create the controller
               _infScroll.link = function _infScrollLink($scope, $element, $attrs) {
                   var throttleTimeout     = false,
                       isDisabled          = function() {
                           if($attrs['infScrollDisabled']) {
                               return $parse($attrs['infScrollDisabled'])($scope);
                           }

                           return false;
                       },
                       getOffset           = function() {
                           if($attrs['infScrollOffset']) {
                               return $parse($attrs['infScrollOffset'])($scope) || 0;
                           }
                           return 0
                       },
                       execScrollHandler   = function() {
                           if(!isDisabled()) {
                               globalDebug.log('info', 'execScrollHandler executing');
                               $parse($attrs['infScroll'])($scope);
                           }
                           else {
                               globalDebug.log('info', 'execScrollHandler disabled');
                           }
                       },
                       throttleExecution   = function() {
                           $timeout.cancel(throttleTimeout);
                           throttleTimeout = $timeout(execScrollHandler, 100);
                       },
                       windowScrollHandler = function() {

                           var wTop    = (window.scrollY || window.pageYOffset),
                               wHeight = getViewport().height,
                               eHeight = $element.height(),
                               eTop    = $element.offset().top;

                           var eBottom      = eTop + eHeight,
                               scrollBottom = (wTop + wHeight) + getOffset();

                           globalDebug.log('debug', 'scrollBottomWithOffset(=%o), offset(=%o), elementBottom(=%o)', scrollBottom, getOffset(), eBottom);
                           if(scrollBottom >= eBottom) {
                               throttleExecution();
                           }
                       };

                   angular.element(window).on('scroll', windowScrollHandler);
                   angular.element(window).on('resize', windowScrollHandler);
               };

               return _infScroll;
           }
       ]);
