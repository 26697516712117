angular.ctModule('ct.services.hash', [])

       /**
        * @ngdoc service
        * @name ct.services.hash.service:CTHash
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * This service provides a callback for changes inside the hash
        *
        */
       .service('CTHash', [
           "$rootScope", "$timeout", "$q", function($rootScope, $timeout, $q) {
               "use strict";

               var _ctHash     = this,

                   hashChange  = $q.defer(),

                   _hash       = '/',
                   _useTimeout = !("onhashchange" in window);


               function hashCheck() {
                   if(_hash !== location.hash) {
                       _hash = location.hash;

                       $timeout(function() {
                           hashChange.notify(_hash);
                       }, 1);
                   }

                   /*if(_useTimeout)*/
                   setTimeout(hashCheck, 200);
               }

               /**
                * @ngdoc method
                * @name ct.services.hash.service#change
                * @methodOf ct.services.hash.service:CTHash
                *
                * @description
                * add a callback for changes
                *
                * @param {function} callback The callback function
                */
               _ctHash.change = function(callback) {
                   hashChange.promise.then(null, null, callback);
                   callback(_hash);
               };

               /**
                * @ngdoc method
                * @name ct.services.hash.service#currentHash
                * @methodOf ct.services.hash.service:CTHash
                *
                * @description
                * Get the current hash
                *
                * @return {string} The current hash
                */
               _ctHash.currentHash = function() {
                   return _hash;
               };


               hashCheck();
               window.addEventListener("hashchange", hashCheck);

               return _ctHash;
           }
       ]);
