angular.ctModule('ct.directives.countTo', [])

       /**
        * @ngdoc directive
        * @name ct.directives.directive:countTo
        * @restrict A
        *
        * @requires $compile
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * "Animate" the count up or down of a given number
        *
        * @example
        <doc:example module="ct.directives.countTo">
        <doc:source>
        <script>
        function Ctrl($scope) {
                $scope.counter = 100;
                $scope.incrementCounter = function(n) {
                    $scope.counter += n;
                }
             }
        </script>
        <div ng-controller="Ctrl">
        <div>Raw counter {{ counter }}</div>
        <div count-to="counter">'Animated' {{ number }}</div>
        <button type="button" class="btn btn-sm btn-primary" ng-click="incrementCounter(10)">+10</button>
        <button type="button" class="btn btn-sm btn-primary" ng-click="incrementCounter(100)">+100</button>
        <button type="button" class="btn btn-sm btn-primary" ng-click="incrementCounter(1000)">+1000</button>
        </div>
        </doc:source>
        </doc:example>
        */
       .directive('countTo', [
           "$compile", function($compile) {

               var CountToDirective = {};

               CountToDirective.restrict = 'A';
               CountToDirective.scope    = {
                   countTo: '='
               };

               CountToDirective.controller = [
                   "$scope", "$element", "$timeout", function($scope, $element, $timeout) {
                       var currentlyCounting;

                       $scope.number = 0;

                       $element.append($compile($element.contents())($scope));

                       function countTo() {
                           var add = Math.max(Math.floor($scope.countTo * (100 - $scope.number / $scope.countTo * 100) / 1000), 1);
                           add     = isNaN(add) ? 0 : add;

                           $scope.number = $scope.number <= $scope.countTo ? $scope.number + add : $scope.number - add;
                           $scope.number = Math.min($scope.number, $scope.countTo);

                           if($scope.number != $scope.countTo) currentlyCounting = $timeout(countTo, Math.log($scope.number) * Math.log($scope.number));
                       }

                       $scope.$watch('countTo', function(newValue, oldValue) {
                           if(newValue != oldValue && newValue != 0) {
                               $timeout.cancel(currentlyCounting);
                               currentlyCounting = $timeout(countTo, 0);
                           }
                       });

                       currentlyCounting = $timeout(countTo, 0);
                   }
               ];

               return CountToDirective;

           }
       ]);


