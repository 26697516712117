(function() {
    angular.ctModule = function(name, requires, configFn) {
        var module = false;
        try {
            module = angular.module(name);
        } catch(e) {
            module = angular.module(name, requires, configFn);
        }

        return module;
    };
}());
