/**
 * @ngdoc overview
 * @name ct.constant.constant:CTConfig
 *
 * @description
 * Should be filled with
 *
 *  - `HOST` - The Host name of the tools</li>
 *  - `CUSTOMER_IDENTIFIER` - The currently used customer identifier
 */
angular.ctModule('ct.constant', [])
       .constant('CTConfig', {});
