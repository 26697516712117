angular.ctModule('ct.directives.stateButton', [])

       .value('stateButtonHTML', {
           INVALID: '<i aria-hidden="true" class="fa fa-exclamation-circle pull-left"></i> Bitte vervollständigen!',
           INVALID_NEEDS_TERMS: '<i aria-hidden="true" class="fa fa-exclamation-circle pull-left"></i> Bitte das Kästchen anklicken',
           //   VALID: $element.html(),
           CALL   : '<i aria-hidden="true" class="fa fa-clock-o pull-left"></i> Einen Moment…',
           SUCCESS: '<i aria-hidden="true" class="fa fa-check pull-left"></i> Gespeichert',
           ERROR  : '<i aria-hidden="true" class="fa fa-exclamation-circle pull-left"></i> Fehler'
       })

       /**
        * @ngdoc directive
        * @name ct.directives.directive:stateButton
        * @restrict A
        *
        * @requires $compile
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        *
        * @description
        * Automatically show states depending on the form
        *
        *
        */
       .directive('stateButton', [
           function() {

               var _stateButtonDirective = {};

               _stateButtonDirective.restrict = 'A';
               _stateButtonDirective.scope    = {
                   form        : '=stateButton',
                   activityFlag: '='
               };

               _stateButtonDirective.controller = [
                   "$scope",
                   "$element",
                   "$compile",
                   "stateButtonHTML",
                   function($scope, $element, $compile, stateHTML) {
                       var $fillElement = $element.find('[state-button-content]').length ? $element.find('[state-button-content]')
                                                                                                   .first() : $element,
                           validState   = $fillElement.html();

                       function setButtonState() {
                           console.log('MIKADO 12: ', $scope.form);
                           $element.attr('disabled', 'disabled');
                           if($scope.form.APICall || $scope.activityFlag) {
                               $fillElement.html(stateHTML.CALL);
                           }

                           else if($scope.form.APISuccess) {
                               $fillElement.html(stateHTML.SUCCESS);
                               $element.removeAttr('disabled');
                           }

                           else if($scope.form.APIError) {
                               $fillElement.html(stateHTML.ERROR);
                           }

                           else if($scope.form.$valid) {
                               $fillElement.html(validState);
                               $element.removeAttr('disabled');
                           }

                           else if($scope.form.$invalid) {
                               console.log('MIKADO: ', $scope.form);
                               $fillElement.html(stateHTML.INVALID_NEEDS_TERMS);
                           }

                           else if($scope.form.$invalid) {
                               $fillElement.html(stateHTML.INVALID);
                           }

                           $compile($fillElement.contents())($scope.$parent);
                       }


                       $scope.$watch('form.$valid', setButtonState);
                       $scope.$watch('form.$invalid', setButtonState);
                       $scope.$watch('form.APICall', setButtonState);
                       $scope.$watch('form.APISuccess', setButtonState);
                       $scope.$watch('activityFlag', setButtonState);

                   }
               ];

               return _stateButtonDirective;

           }
       ]);


