// TODO is this whole nonce thing and this file still needed?
angular.ctModule('ct.services.authentication', [
        'ct.constant',
        'ui.bootstrap',
        'ct.services.facebook',
        'ct.services.helpers',
        'ct.services.customers',
        'ct.services.csphotoselector',
        'ct.directives.imageUpload',
    ])

    /**
     * @ngdoc service
     * @name ct.services.authentication.service:CTServiceAdminAuthentication
     *
     * @author Michael Scharl <ms@campaigning-bureau.com>
     * @description
     * For Authenticating a user and checking it´s permission, this is the service you need to use
     *
     * The ``CTServiceAdminAuthentication`` - Service provides functions to login, and getting the user or its current authentication status for the administration view.
     */
    .service('CTServiceAdminAuthentication', [
        '$rootScope',
        '$q',
        'ctAuthenticationEvents',
        'CTConfig',
        'CTServiceAuthenticationConfig',
        '$http',
        '$modal',
        function($rootScope, $q, ctAuthenticationEvents, CTConfig, CTServiceAuthenticationConfig, $http, $modal) {
            'use strict';

            var /** @access private */
                CTServiceAdminAuthentication = this,

                /**
                 * Holds the currentUser Object or false if no user is set
                 * @type (object|boolean)
                 * @access private
                 */
                _currentUser = false,

                /**
                 * Holds the current login state
                 *
                 * @type {string}
                 * @access private
                 */
                _loginState = 'LOGGED_OUT',

                /** @access private */
                _ctUserCookies = {
                    UserIdentifier: false,
                    UserNonce: false,
                },

                /** @access private */
                userIsLoading = false,

                /** @access private */
                userDeferred = $q.defer(),

                /** @access private */
                didCheckCookies = $q.defer(),

                /** @access private */
                getCustomerDeferred = [],

                /** @access private */
                loginInProgress = false,

                /** @access private */
                _passwordHash = false;


            /**
             * @ngdoc method
             * @name ct.services.authentication.service#login
             * @methodOf ct.services.authentication.service:CTServiceAdminAuthentication
             *
             * @description
             * Handle the user login and opens the dialog if necessary
             *
             * @param {string} email The email of the user to sign in
             * @param {string} password_hash The password hash of the user to sign in
             * @param {string} customer_identifier The customer identifier that should be used to log in to
             *
             * @returns {promise} A promise that will be resolved after the login and will be valid until the user logs out
             */
            CTServiceAdminAuthentication.login = function(email, password_hash, customer_identifier) {

                if(!loginInProgress) {
                    loginInProgress = true;

                    if(_loginState == 'LOGGED_OUT' || !_currentUser) {
                        /** Create a new deferred object for this login run */
                        userDeferred = $q.defer();
                        userDeferred.promise['finally'](function() {
                            loginInProgress = false;
                        });
                    }

                    didCheckCookies.promise.then(function() {
                        if(_loginState == 'LOGGED_OUT' || !_currentUser) {

                            angular.extend(CTConfig, { CUSTOMER_IDENTIFIER: customer_identifier });
                            $CB_API(CTConfig, true);
                            $CB_API({
                                USER_IDENTIFIER: '',
                                USER_NONCE: '',
                            }, true);

                            Cookies.set('ADMIN_DATA', JSON.stringify({
                                CUSTOMER_IDENTIFIER: customer_identifier,
                                PASSWORD_HASH: password_hash,
                            }), { expires: 24 * 60 * 60 });

                            $CB_API({
                                method: 'GET',
                                url: '/user_nonce',
                                params: {
                                    email: email,
                                    password_hash: password_hash,
                                },
                            })
                                .then(function() {
                                    _passwordHash = password_hash;
                                })
                                .then(function(data) {
                                    //console.log(data);
                                })
                                .then(checkUserCookie, technicalLogout);

                        }
                        else {
                            loginInProgress = false;
                        }
                    });
                }

                return userDeferred.promise;
            };


            /**
             * @ngdoc function
             * @name CTServiceAdminAuthentication.service#switchCustomer
             * @methodOf ct.services.authentication.service:CTServiceAdminAuthentication
             *
             * @description
             * Switch to another customer
             *
             * @param {string} email The email of the user to sign in
             * @param {string} password_hash The password hash of the user to sign in
             * @param {string} customer_identifier The customer identifier that should be used to log in to
             *
             * @returns {promise} A promise that will be resolved after the login and will be valid until the user logs out
             */
            CTServiceAdminAuthentication.switchCustomer = function(email, password_hash, customer_identifier) {
                _currentUser = false;
                _loginState = 'LOGGED_OUT';
                loginInProgress = false;

                Cookies.set('ADMIN_DATA', '', { expires: -(24 * 60 * 60) });
                Cookies.set('USER_IDENTIFIER', '', { expires: -(24 * 60 * 60) });
                Cookies.set('USER_NONCE', '', { expires: -(24 * 60 * 60) });

                _ctUserCookies = {};

                $rootScope.$broadcast(ctAuthenticationEvents.LOGOUT);

                return CTServiceAdminAuthentication.login(email, password_hash, customer_identifier);
            };


            /**
             * @ngdoc function
             * @name CTServiceAdminAuthentication.service#logout
             * @methodOf ct.services.authentication.service:CTServiceAdminAuthentication
             *
             * @description
             * Log the user out and remove all nonces from the API
             *
             * @param {string} returnTo The URL to redirect after the logout
             *
             * @returns {promise} The promise will resolve after the logout
             */
            CTServiceAdminAuthentication.logout = function(returnTo) {

                function userLogout() {
                    Cookies.set('ADMIN_DATA', '', { expires: -(24 * 60 * 60) });
                    Cookies.set('USER_IDENTIFIER', '', { expires: -(24 * 60 * 60) });
                    Cookies.set('USER_NONCE', '', { expires: -(24 * 60 * 60) });

                    location.href = returnTo || logout_url;
                }

                return $CB_API({
                    method: 'POST',
                    url: '/users/' + _currentUser.identifier + '/logout',
                })
                    .then(userLogout, userLogout);

            };


            /**
             * @ngdoc function
             * @name CTServiceAdminAuthentication.service#isLoggedIn
             * @methodOf ct.services.authentication.service:CTServiceAdminAuthentication
             *
             * @description
             * Return the user promise
             *
             * @returns {promise} the current login promise
             */
            CTServiceAdminAuthentication.isLoggedIn = function() {
                return userDeferred.promise;
            };


            /**
             * @ngdoc method
             * @name CTServiceAdminAuthentication.service#getLoginStatus
             * @methodOf ct.services.authentication.service:CTServiceAdminAuthentication
             *
             * @description
             * Return the current login status
             *
             * @returns {string} Either 'LOGGED_IN' or 'LOGGED_OUT'
             */
            CTServiceAdminAuthentication.getLoginStatus = function() {
                return _loginState;
            };


            /**
             * @ngdoc method
             * @name CTServiceAdminAuthentication.service#getCurrentUser
             * @methodOf ct.services.authentication.service:CTServiceAdminAuthentication
             *
             * @description
             * Return the current User object or false if not user is available
             *
             * @returns {boolean|object} Returns false if not user is set or the user object
             */
            CTServiceAdminAuthentication.getCurrentUser = function() {
                return _currentUser;
            };

            /**
             * @ngdoc method
             * @name CTServiceAdminAuthentication.service#hasContentEditPermission
             * @methodOf ct.services.authentication.service:CTServiceAdminAuthentication
             *
             * @description
             * Check if the current user has content edit permission
             *
             * @returns {boolean} returns whether the user has content edit permission or not
             */
            CTServiceAdminAuthentication.hasContentEditPermission = function() {
                return (currentUser && currentUser.has_content_edit_permission === true);
            };

            /**
             * check the cookies
             * calls itself every 2 seconds after the first call
             *
             * @access private
             */
            function checkUserCookie() {
                _ctUserCookies.UserIdentifier = Cookies.get('USER_IDENTIFIER');
                _ctUserCookies.UserNonce = Cookies.get('USER_NONCE');

                //console.group('Check UserCookie');
                //console.debug(_ctUserCookies);

                // If all cookies are set and login state is logged out - load the user
                if(_ctUserCookies.UserIdentifier && _ctUserCookies.UserNonce && _loginState == 'LOGGED_OUT') {
                    //console.info('all cookies are set and login state is logged out - load the user');
                    var admin_data = Cookies.get('ADMIN_DATA');
                    if(admin_data) {
                        admin_data = JSON.parse(admin_data);

                        angular.extend(CTConfig, { CUSTOMER_IDENTIFIER: admin_data.CUSTOMER_IDENTIFIER });
                        $CB_API(CTConfig, true);

                        _passwordHash = admin_data.PASSWORD_HASH;
                    }
                    else {
                        //console.error(e);
                        //console.groupEnd('Check UserCookie');
                        didCheckCookies.resolve(true);
                        technicalLogout();
                        return;
                    }

                    loadUser();
                    setTimeout(checkUserCookie, 2000);
                }

                // If either nonce or identifer are unset but login state is logged in - call logout function
                else if((!_ctUserCookies.UserIdentifier || !_ctUserCookies.UserNonce) && _loginState == 'LOGGED_IN') {
                    //console.info('either nonce or identifer are unset but login state is logged in - call logout function');
                    didCheckCookies.resolve(true);
                    technicalLogout();
                    warnUserViaInterface();
                }

                //If user is logged in - call the checkUserCookieState function
                else if(_loginState == 'LOGGED_IN') {
                    //console.info('user is logged in - call the checkUserCookieState function');
                    setTimeout(checkUserCookie, 2000);
                }

                else {
                    //console.info('User is logged out');
                    didCheckCookies.resolve(true);
                    technicalLogout();
                }

                //console.groupEnd('Check UserCookie');
            }

            function technicalLogout() {
                //Reject the old Object
                userDeferred.reject();

                // Set state variables
                _currentUser = false;
                _loginState = 'LOGGED_OUT';
                loginInProgress = false;

                //            Cookies.set('ADMIN_DATA', '', { expires: -(24*60*60) });
            }

            /**
             * Function to load the User
             *
             * @access private
             */
            function loadUser() {
                if(userIsLoading) {
                    return;
                }

                userIsLoading = true;
                $CB_API({
                    method: 'GET',
                    url: '/users/' + _ctUserCookies.UserIdentifier,
                    authentication: { type: 'user' },
                    level: 'user',
                })
                    .done(loadUserSuccess)
                    .fail(loadUserFailed);
            }


            /**
             * Success callback after loading the user
             *
             * @access private
             * @param data
             */
            function loadUserSuccess(data) {
                _currentUser = data.user;

                _currentUser.created_at = CT_string2date(_currentUser.created_at);
                _currentUser.updated_at = CT_string2date(_currentUser.updated_at);
                _currentUser.person.created_at = CT_string2date(_currentUser.person.created_at);
                _currentUser.person.updated_at = CT_string2date(_currentUser.person.updated_at);

                _currentUser.password_hash = _passwordHash;


                if(_loginState == 'LOGGED_OUT') {
                    _loginState = 'LOGGED_IN';

                    $rootScope.$apply(function() {
                        userDeferred.resolve();
                        didCheckCookies.resolve(true);
                        $rootScope.$broadcast(ctAuthenticationEvents.LOGIN);
                    });
                }

                userIsLoading = false;
            }


            /**
             * Error callback after loading the user
             *
             * @access private
             */
            function loadUserFailed() {
                _currentUser = false;

                if(_loginState == 'LOGGED_IN') {
                    _loginState = 'LOGGED_OUT';

                    $rootScope.$apply(function() {
                        technicalLogout();
                        didCheckCookies.resolve(true);
                    });
                }

                userIsLoading = false;
            }


            function warnUserViaInterface() {
                $modal.open({
                    templateUrl: 'ct.adminAuthenticationWarningModal',
                });
            }


            /**
             * Init function to startup everything
             *
             * @access private
             */
            function init() {
                if(CTServiceAuthenticationConfig.checkCookieOnStartup) {
                    checkUserCookie();
                }

                userDeferred.promise['finally'](function() {
                    loginInProgress = false;
                });
            }

            //After all - Start it up
            init();
        },
    ]);
