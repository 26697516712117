var CTPerson;
angular.ctModule('ct.services.people').run([
    "CTServicePeople", function(CTServicePeople) {
        CTPerson = function(data) {
            var _CTPerson = this;


            _CTPerson.update = function(data) {
                _preparePersonData(data);
                //console.log(_CTPerson, data);
                angular.extend(_CTPerson, data);
            };

            _CTPerson.loadCounter = function() {
                return CTServicePeople.loadPersonCounter(_CTPerson).then(function(data) {

                }, function(error) {

                })
            };

            _CTPerson.getActions = function() {
                return CTServicePeople.getPersonActions(_CTPerson).then(function() {

                }, function(error) {

                });
            };

            _CTPerson.loadEntries = function(module_route, module_identifier) {
                return CTServicePeople.loadPersonsEntries(_CTPerson, module_route, module_identifier).then(function() {

                }, function(error) {

                });
            };

            _CTPerson.save = function() {
                return CTServicePeople.save(_CTPerson);
            };

            function _preparePersonData(data) {

                if(data.user_type) {
                    data.user_type = !isNaN(parseInt(data.user_type, 10)) ? parseInt(data.user_type, 10) : 0;
                }
                else if(!_CTPerson.user_type) {
                    data.user_type = 0;
                }

                if(data.actions && angular.isArray(data.actions)) {
                    angular.forEach(data.actions, function(action) {
                        action.pivot.created_at = CT_string2date(action.pivot.created_at);
                    })
                }

                if(data.created_at && !(data.created_at instanceof Date)) {
                    data.created_at = CT_string2date(data.created_at);
                }
            }

            function init(data) {

                _CTPerson.update(data);

                angular.extend(_CTPerson, {
                    content_counters: {
                        loning: false,
                        total : 0
                    }
                });

                return _CTPerson;
            }

            return init(data);

        };
    }
]);
