angular.ctModule('ct.directives.selectizr', [])
       .directive('selectizr', function() {

           var _selectizr = {};


           _selectizr.link = function _selectizrLink($scope, $element, $attr) {
               if(jQuery && jQuery.fn.cbToolkit_Selectizr) {
                   jQuery($element).cbToolkit_Selectizr();
               }
           };

           return _selectizr;
       });
