angular.ctModule('ct.services.authentication')
    .constant('ctAuthenticationEvents', {
        /**
         * @ngdoc event
         * @name ctServiceAuthentication.service#LOGIN
         * @eventOf ct.services.authentication.service:CTServiceAuthentication
         * @eventType broadcast on $rootScope
         *
         * @description
         * To use this event you need to inject the constant `ctAuthenticationEvents` and listen to `ctAuthenticationEvents.LOGIN`
         */
        LOGIN: 'ctAuthenticationEvents.LOGIN',
        /**
         * @ngdoc event
         * @name ctServiceAuthentication.service#LOGOUT
         * @eventOf ct.services.authentication.service:CTServiceAuthentication
         * @eventType broadcast on $rootScope
         *
         * @description
         * To use this event you need to inject the constant `ctAuthenticationEvents` and listen to `ctAuthenticationEvents.LOGOUT`
         */
        LOGOUT: 'ctAuthenticationEvents.LOGOUT'
    })

    .constant('CTServiceAuthenticationConfig', {
        checkCookieOnStartup: true
    })

    /**
     * @ngdoc service
     * @name ct.services.authentication.service:CTServiceAuthentication
     *
     * @requires ct.services.authentication.service:CTServiceLogin
     * @requires ct.services.authentication.service:CTServiceFacebook
     *
     * @author Michael Scharl <ms@campaigning-bureau.com>
     * @description
     * For Authenticating a user and checking it´s permission, this is the service you need to use
     *
     * The ``CTServiceAuthentication`` - Service provides functions to login, logout, getting the user or its current authentication status.
     */
    .service('CTServiceAuthentication', [
        '$rootScope',
        '$q',
        'ctAuthenticationEvents',
        'CTServiceLogin',
        'CTConfig',
        'CTServiceFacebook',
        'CTServiceAuthenticationConfig',
        function($rootScope,
            $q,
            ctAuthenticationEvents,
            CTServiceLogin,
            CTConfig,
            CTServiceFacebook,
            CTServiceAuthenticationConfig
        ) {
            'use strict';

            var /** @access private */
                ctServiceAuthentication = this,

                /**
                 * Holds the currentUser Object or false if no user is set
                 * @type (object|boolean)
                 * @access private
                 */
                currentUser = false,

                /**
                 * Holds the current login state
                 *
                 * @type {string}
                 * @access private
                 */
                loginState = 'LOGGED_OUT',

                /** @access private */
                ctUserCookies = {
                    UserIdentifier: false,
                    UserNonce: false,
                    AccessToken: false
                },

                /** @access private */
                userIsLoading = false,

                /** @access private */
                userDeferred = $q.defer(),

                /** @access private */
                didCheckCookies = $q.defer(),

                /** @access private */
                loginInProgress = false;

            /**
             * @ngdoc method
             * @name ct.services.authentication.service#login
             * @methodOf ct.services.authentication.service:CTServiceAuthentication
             *
             * @description
             * Handle the user login and opens the dialog if necessary
             *
             * @param {string=} [title='Login'] The Title of the Dialog
             * @param {string=} [callback_url=''] Where to return if E-Mail Auth is used
             * @param {boolean=} [guest_user=true] Defines if a guest_user shall be allowed for this dialog
             *
             * @returns {promise} A promise that will be resolved after the login and will be valid until the user logs out
             */
            ctServiceAuthentication.login = function(title, callback_url, guest_user) {

                if (typeof guest_user === 'undefined') {
                    guest_user = true;
                }

                if (!title) {
                    title = 'Login';
                }

                if (!loginInProgress) {
                    loginInProgress = true;

                    if (loginState == 'LOGGED_OUT' || !currentUser) {
                        /** Create a new deferred object for this login run */
                        userDeferred = $q.defer();
                        userDeferred.promise['finally'](function() {
                            loginInProgress = false;
                        });
                    }

                    didCheckCookies.promise.then(function() {
                        if (loginState == 'LOGGED_OUT' || !currentUser) {
                            CTServiceLogin.login({
                                'callback_url': callback_url,
                                'title': title,
                                'guest_user': guest_user
                            })
                                .result
                                .then(checkUserCookie, function() {
                                    technicalLogout();
                                });
                        }
                        else {
                            loginInProgress = false;
                        }
                    });
                }

                return userDeferred.promise;
            };


            /**
             * @ngdoc method
             * @name ct.services.authentication.service#loginWithFacebook
             * @methodOf ct.services.authentication.service:CTServiceAuthentication
             *
             * @description
             * Handles much like the standard `login` function but only uses Facebook Login.
             *
             * @param {object} opts Options to modify login behavior. See {@link https://developers.facebook.com/docs/reference/javascript/FB.login/v2.0#options FB.login} for properties you can set on the object.
             *
             * @returns {promise} Returns the same promise as the `login` function
             */
            ctServiceAuthentication.loginWithFacebook = function(opts) {
                if (!loginInProgress) {
                    CTServiceFacebook.login(opts)
                        .then(function(fbResponse) {
                            $CB_API({
                                method: 'POST',
                                url: '/oauth/facebook',
                                params: {
                                    facebook_id: fbResponse.authResponse.userID,
                                    access_token: fbResponse.authResponse.accessToken
                                },
                                excludeApiVersionPart: true
                            })
                                .then(//Login was successful
                                    checkUserCookie,

                                    //Could not login
                                    function(data) {
                                        technicalLogout();
                                    }
                                )
                                .always(function() {
                                    $rootScope.$apply();
                                });
                        }, checkUserCookie);
                }

                loginInProgress = true;

                return userDeferred.promise;
            };

            /**
             * @ngdoc method
             * @name ctServiceAuthentication.service#logout
             * @methodOf ct.services.authentication.service:CTServiceAuthentication
             *
             * @description
             * Log the use out and reset all local variables
             */
            ctServiceAuthentication.logout = function() {
                // Reset/Delete Cookies
                Cookies.set('USER_NONCE', '', { expires: -(60 * 60) });
                Cookies.set('USER_IDENTIFIER', '', { expires: -(60 * 60) });
                Cookies.set('ACCESS_TOKEN', '', { expires: -(60 * 60) });

                technicalLogout();

                $rootScope.$broadcast(ctAuthenticationEvents.LOGOUT);
            };


            /**
             * @ngdoc function
             * @name ctServiceAuthentication.service#isLoggedIn
             * @methodOf ct.services.authentication.service:CTServiceAuthentication
             *
             * @description
             * Return the user promise
             *
             * @returns {promise} the current login promise
             */
            ctServiceAuthentication.isLoggedIn = function() {
                return userDeferred.promise;
            };


            /**
             * @ngdoc method
             * @name ctServiceAuthentication.service#getLoginStatus
             * @methodOf ct.services.authentication.service:CTServiceAuthentication
             *
             * @description
             * Return the current login status
             *
             * @returns {string} Either 'LOGGED_IN' or 'LOGGED_OUT'
             */
            ctServiceAuthentication.getLoginStatus = function() {
                return loginState;
            };


            /**
             * @ngdoc method
             * @name ctServiceAuthentication.service#getCurrentUser
             * @methodOf ct.services.authentication.service:CTServiceAuthentication
             *
             * @description
             * Return the current User object or false if not user is available
             *
             * @returns {boolean|object} Returns false if not user is set or the user object
             */
            ctServiceAuthentication.getCurrentUser = function() {
                return currentUser;
            };

            /**
             * @ngdoc method
             * @name ctServiceAuthentication.service#hasContentEditPermission
             * @methodOf ct.services.authentication.service:CTServiceAuthentication
             *
             * @description
             * Check if the current user has content edit permission
             *
             * @returns {boolean} returns whether the user has content edit permission or not
             */
            ctServiceAuthentication.hasContentEditPermission = function() {
                return (currentUser && currentUser.has_content_edit_permission === true);
            };

            /**
             * check the cookies
             * calls itself every 2 seconds after the first call
             *
             * @access private
             */
            function checkUserCookie() {
                ctUserCookies.UserIdentifier = Cookies.get('USER_IDENTIFIER');
                ctUserCookies.UserNonce = Cookies.get('USER_NONCE');
                ctUserCookies.AccessToken = Cookies.get('ACCESS_TOKEN');

                // If all cookies are set and login state is logged out - load the user
                if (ctUserCookies.UserIdentifier &&
                    (ctUserCookies.UserNonce || ctUserCookies.AccessToken) &&
                    loginState ===
                    'LOGGED_OUT') {
                    loadUser();
                }

                // If either nonce or identifer are unset but login state is logged in - call logout function
                else if ((!ctUserCookies.UserIdentifier || (!ctUserCookies.UserNonce && !ctUserCookies.AccessToken)) &&
                         loginState ===
                         'LOGGED_IN') {
                    didCheckCookies.resolve(true);
                    technicalLogout();
                }

                //If user is logged in - call the checkUserCookieState function
                else if (loginState === 'LOGGED_IN') {
                    setTimeout(checkUserCookie, 2000);
                }

                else {
                    didCheckCookies.resolve(true);
                    technicalLogout();
                }
            }

            function technicalLogout() {
                //Reject the old Object
                userDeferred.reject();

                // Set state variables
                currentUser = false;
                loginState = 'LOGGED_OUT';
            }

            /**
             * Function to load the User
             *
             * @access private
             */
            function loadUser() {
                if (userIsLoading) {
                    return;
                }

                $CB_API({
                    method: 'GET',
                    url: '/users/' + ctUserCookies.UserIdentifier,
                    authentication: { type: 'user' },
                    level: 'user'
                })
                    .then(loadUserSuccess, loadUserFailed)
                    .then(function() {
                        setTimeout(checkUserCookie, 2000);
                    }, function() {
                        setTimeout(checkUserCookie, 2000);
                    });
            }


            /**
             * Success callback after loading the user
             *
             * @access private
             * @param data
             */
            function loadUserSuccess(data) {

                currentUser = data.user;

                currentUser.created_at = CT_string2date(currentUser.created_at);
                currentUser.updated_at = CT_string2date(currentUser.updated_at);
                currentUser.person.created_at = CT_string2date(currentUser.person.created_at);
                currentUser.person.updated_at = CT_string2date(currentUser.person.updated_at);


                if (loginState === 'LOGGED_OUT') {
                    loginState = 'LOGGED_IN';

                    $rootScope.$apply(function() {
                        userDeferred.resolve();
                        didCheckCookies.resolve(true);
                        $rootScope.$broadcast(ctAuthenticationEvents.LOGIN);
                    });
                }
            }


            /**
             * Error callback after loading the user
             *
             * @access private
             */
            function loadUserFailed() {
                currentUser = false;

                if (loginState === 'LOGGED_IN') {
                    loginState = 'LOGGED_OUT';

                    $rootScope.$apply(function() {
                        technicalLogout();
                        didCheckCookies.resolve(true);
                        ctServiceAuthentication.logout();
                    });
                }
            }


            /**
             * Init function to startup everything
             *
             * @access private
             */
            function init() {
                if (CTServiceAuthenticationConfig.checkCookieOnStartup) {
                    checkUserCookie();
                }

                userDeferred.promise['finally'](function() {
                    loginInProgress = false;
                });
            }

            // After all - Start it up
            init();
        }
    ]);
