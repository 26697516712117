/*!
 * Author:
 */

(function($) {
    if(!$.cbToolkit) {
        $.cbToolkit = {};
    }

    $.cbToolkit.ieFrame = function(el, options) {
        // To avoid scope issues, use 'base' instead of 'this'
        // to reference this class from internal events and functions.
        var base = this;

        // Access to jQuery and DOM versions of element
        base.$el = $(el);
        base.el  = el;

        // Add a reverse reference to the DOM object
        base.$el.data("cbToolkit.ieFrame", base);

        base.init = function() {

            base.postMessageOrigin = location.origin || "*";

            base.unique = 'ieFrame_' + base.hashCode(new Date().getTime().toString());

            base.options = $.extend({}, $.cbToolkit.ieFrame.defaultOptions, options);

            var tagName = base.$el.prop("tagName").toUpperCase();

            if(tagName === 'FORM') {
                throw new Error('You must not use this on a form');
            }

            base.wrapElement();

            base.prepareFrame();
            base.sendForm();
        };

        /**
         * Generate a hash from a string
         *
         * @param string
         * @returns {*}
         */
        base.hashCode = function(string) {
            var hash = 0, i, char, l;
            if(string.length == 0) return hash;
            for(i = 0, l = string.length; i < l; i++) {
                char = string.charCodeAt(i);
                hash = ((hash << 5) - hash) + char;
                hash |= 0; // Convert to 32bit integer
            }
            hash = Math.abs(parseInt(hash));
            return ('' + hash);
        };


        /**
         * Wrap the given element in a form
         */
        base.wrapElement = function() {

            base.options.params.ieframe = true;
            var params                  = $.param(base.options.params),
                url                     = base.options.url + '?' + params;

            base.$form = jQuery('<form></form>')
                .attr('id', base.unique + '_FORM')
                .attr('method', base.options.method)
                .attr('target', base.unique + '_FRAME')
                .attr('action', url)
                .attr('enctype', 'multipart/form-data')
            ;

            base.$el.wrap(base.$form);

            $('<input type="submit" value="submit" style="display:none">').insertAfter(base.$el);
        };

        /**
         * Prepare the iframe for submit
         */
        base.prepareFrame = function() {
            base.$frame = jQuery('<iframe></iframe>')
                .attr('id', base.unique + '_FRAME')
                .attr('name', base.unique + '_FRAME')
                .css({
                    position: 'absolute',
                    top     : '-99999px',
                    left    : '-99999px',
                    width   : '100px',
                    height  : '100px'
                })
            ;

            jQuery('body').append(base.$frame);

            base.$frame.on('load', base.onLoad);
        };

        /**
         * Trigger the submit
         */
        base.sendForm = function() {
            document.getElementById(base.unique + '_FORM').submit();
        };


        /**
         * Handle onload
         *
         * @param event
         */
        base.onLoad = function(event) {
            var data, // = base.$frame.contents().find('body').text(),
                ieFrameWin = document.getElementById(base.unique + '_FRAME').contentWindow;


            function checkData() {
                if(!data) {
                    ieFrameWin.postMessage('response?', base.postMessageOrigin);
                    setTimeout(checkData, 100);
                }
            }

            function respondedData() {
                base.options.callback(data);

                base.$frame.remove();
                base.$el.unwrap();
            }

            function respondToRequest(event) {
                data = event.data;
                respondedData();
            }

            if(window.addEventListener) {
                // For standards-compliant web browsers
                window.addEventListener("message", respondToRequest, false);
            }
            else {
                window.attachEvent("onmessage", respondToRequest);
            }
            checkData();
        };

        base.init();
    };

    $.cbToolkit.ieFrame.defaultOptions = {
        callback: $.noop,
        url     : '/',
        params  : {},
        method  : 'GET'
    };

    $.fn.cbToolkit_ieFrame = function(options) {
        return this.each(function() {
            (new $.cbToolkit.ieFrame(this, options));
        });
    };

})(jQuery);
