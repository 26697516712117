angular.ctModule('ct.services.events', [
    'ct.filters.truncate',
    'ct.services.ctGetCoordinates',
    'ct.services.authentication',
    'ct.constant',
    'ct.services.data',
    'ct.filters.parseLinks'
])
    .value('globalEventFilterValue', {})/**
 * @ngdoc directive
 * @name ct.directives.directive:eventMap
 * @restrict A
 *
 * @author Michael Scharl <ms@campaigning-bureau.com>
 * @author Stefan Schindler <stefan.schindler@campaigning-bureau.com>
 * @description
 * Implement the event map.
 * Loads all or a given amount of events via the events service and displays them on the map.
 *
 * Possible parameter:
 * - max-events={int}   the maximum events to display
 * @scope
 *
 */
    .directive('eventMap', [
        function() {

            let eventMap = {};

            eventMap.scope = {
                eventMap: '=',
                maxEvents: '=?'
            };
            eventMap.restrict = 'A';

            eventMap.controller = [
                '$scope',
                '$element',
                'globalEventFilterValue',
                'CTServiceEvents',
                'CTConfig',
                function($scope, $element, globalEventFilterValue, CTServiceEvents, CTConfig) {

                    let previousMapFilter = {},
                        currentMarkers = [];

                    mapboxgl.accessToken = CTConfig.MAPBOX_ACCESS_TOKEN;

                    function init() {
                        initMap();

                        // watch the maxEvents var to react to changes
                        $scope.$watch('max-events', loadEvents);

                        $scope.$on('eventsFilter', applyMapFilter);
                    }

                    /**
                     * initMap
                     *
                     * @description
                     * run code to initialize the map
                     */
                    function initMap() {

                        $scope.map = new mapboxgl.Map({
                            container: $element[0],
                            style: 'mapbox://styles/mapbox/streets-v11',
                            zoom: 4,
                            scrollZoom: false,
                            center: new mapboxgl.LngLat(16.373471, 48.208411)
                        });
                    }

                    /**
                     * applyMapFilter
                     *
                     * @description
                     * Check for changed filter values and run methods to apply those filters
                     */
                    function applyMapFilter() {
                        if (!angular.equals({
                            zip_code: previousMapFilter.zip_code,
                            radius: previousMapFilter.radius
                        }, {
                            zip_code: globalEventFilterValue.zip_code,
                            radius: globalEventFilterValue.radius
                        })) {
                            clearCurrentMarkers();
                            applyMapGeoFilter();
                        }

                        else if (!angular.equals(previousMapFilter, globalEventFilterValue)) {
                            clearCurrentMarkers();
                            CTServiceEvents.getAllFiltered(CTConfig.PAGE_ID, globalEventFilterValue)
                                .then(function(events) {
                                    if (!events.length) {
                                        return;
                                    }

                                    putEventsOnMap(events);
                                });
                        }

                        previousMapFilter = angular.copy(globalEventFilterValue);
                    }

                    /**
                     * applyMapGeoFilter
                     *
                     * @description
                     * apply new values for the location filter
                     */
                    function applyMapGeoFilter() {
                        let mapboxClient = mapboxSdk({ accessToken: mapboxgl.accessToken });

                        // if a zip filter is set, call the geocoder
                        if (globalEventFilterValue.zip_code) {
                            mapboxClient.geocoding
                                .forwardGeocode({
                                    query: globalEventFilterValue.zip_code,
                                    countries: [
                                        'AT',
                                        'DE'
                                    ],
                                    autocomplete: false,
                                    limit: 1
                                })
                                .send()
                                .then(function(response) {
                                    if (response &&
                                        response.body &&
                                        response.body.features &&
                                        response.body.features.length) {
                                        let feature = response.body.features[0];
                                        $scope.map.setCenter(feature.center);

                                        CTServiceEvents.getAllFiltered(CTConfig.PAGE_ID, globalEventFilterValue)
                                            .then(eventsResult);
                                    }
                                });
                        }
                        else {  // else just load events
                            loadEvents();
                        }

                        function eventsResult(events) {
                            putEventsOnMap(events);
                        }
                    }

                    /**
                     * clearCurrentMarkers
                     *
                     * @description
                     * Remove all markers from the map
                     */
                    function clearCurrentMarkers() {
                        for (let i = currentMarkers.length - 1; i >= 0; i--) {
                            currentMarkers[i].remove();
                        }
                    }

                    function loadEvents() {
                        // initialized with events?
                        if (angular.isArray($scope.eventMap)) {
                            handleReceivedEvents($scope.eventMap);
                        }// else, is a valid maximum amount specified?
                        else if (typeof $scope.maxEvents !== 'undefined' && $scope.maxEvents > 0) {
                            // load the specified amount of events
                            CTServiceEvents.getFilteredUntilPage(CTConfig.PAGE_ID,
                                globalEventFilterValue,
                                0,
                                $scope.maxEvents
                            )
                                .then(function(events) {
                                    handleReceivedEvents(events);
                                });
                        }
                        else   // no valid amount
                        {
                            CTServiceEvents.getAllFiltered(CTConfig.PAGE_ID, globalEventFilterValue)
                                .then(function(events) {
                                    handleReceivedEvents(events);
                                });
                        }
                    }

                    /**
                     * handleReceivedEvents
                     *
                     * @description
                     * handle the received events.
                     * place them on the map and set the correct map bounds.
                     *
                     * @param {CTEvent[]} events
                     */
                    function handleReceivedEvents(events) {
                        putEventsOnMap(events);
                    }

                    function putEventsOnMap(events) {
                        if (events.length === 0) {
                            return;
                        }

                        let bounds = new mapboxgl.LngLatBounds();
                        angular.forEach(events, function(event) {
                            if (event.map && event.map.marker) {

                                event.map.marker.setPopup(event.mapPopupWindow());
                                event.map.marker.addTo($scope.map);
                                currentMarkers.push(event.map.marker);
                            }

                            if (event.map && event.map.latlng) {
                                bounds.extend(event.map.latlng);
                            }
                        });

                        $scope.map.fitBounds(bounds,
                            {
                                padding: 50,
                                maxZoom: 16
                            }
                        );
                    }


                    /**
                     * Check dependencies
                     */
                    if (mapboxgl) {
                        init();
                    }
                }
            ];


            return eventMap;
        }
    ]);
