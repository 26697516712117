angular.ctModule('ct.directives.responsiveInclude', [])
       .directive('responsiveInclude', [
           '$http', '$templateCache', '$anchorScroll', '$compile', '$animate', '$sce', '$window',
           function($http, $templateCache, $anchorScroll, $compile, $animate, $sce, $window) {
               return {
                   restrict  : 'ECA',
                   terminal  : true,
                   transclude: 'element',
                   compile   : function(element, attr, transclusion) {
                       var mediaQueryIsSupported = true;
                       if(typeof(matchMedia) !== 'function') {
                           mediaQueryIsSupported = false;
                       }

                       var srcExp        = attr.responsiveInclude || attr.src,
                           onloadExp     = attr.onload || '',
                           autoScrollExp = attr.autoscroll,
                           mediaQuery,
                           queryFallback = false;

                       if(mediaQueryIsSupported) {
                           mediaQuery = $window.matchMedia(attr.mediaQuery);
                       }
                       else {
                           queryFallback = attr.queryFallback;
                       }

                       return function(scope, $element) {
                           var changeCounter = 0,
                               currentScope,
                               currentElement,
                               listener,
                               parsedSrc;

                           var cleanupLastIncludeContent = function() {

                               if(currentScope) {
                                   currentScope.$destroy();
                                   currentScope = null;
                               }
                               if(currentElement) {
                                   $animate.leave(currentElement);
                                   currentElement.empty();
                                   currentElement = null;
                               }
                           };

                           var ngIncludeAction = function(mq) {
                               var thisChangeId = ++changeCounter;
                               if(parsedSrc && ((!mediaQueryIsSupported && queryFallback) || (mediaQueryIsSupported && mq.matches))) {
                                   $http.get(parsedSrc, {cache: $templateCache}).success(function(response) {
                                       if(thisChangeId !== changeCounter) return;
                                       var newScope = scope.$new();
                                       transclusion(newScope, function(clone) {
                                           cleanupLastIncludeContent();

                                           currentScope   = newScope;
                                           currentElement = clone;

                                           currentElement.html(response);
                                           $animate.enter(currentElement, null, $element);
                                           $compile(currentElement.contents())(currentScope);

                                           if(typeof autoScrollExp !== 'undefined' && (!autoScrollExp || scope.$eval(autoScrollExp))) {
                                               $anchorScroll();
                                           }

                                           currentScope.$emit('$includeContentLoaded');
                                           scope.$eval(onloadExp);
                                       });
                                   }).error(function() {
                                       if(thisChangeId === changeCounter) cleanupLastIncludeContent();
                                   });

                                   scope.$emit('$includeContentRequested');
                               }
                               else {
                                   cleanupLastIncludeContent();
                               }
                           };

                           scope.$watch($sce.parseAsResourceUrl(srcExp), function ngIncludeWatchAction(src) {
                               parsedSrc = src;
                               ngIncludeAction(mediaQuery);
                           });
                           scope.$on('$destroy', function() {
                               if(mediaQueryIsSupported) {
                                   mediaQuery.removeListener(ngIncludeAction);
                               }
                           });

                           if(mediaQueryIsSupported) {
                               mediaQuery.addListener(ngIncludeAction);
                           }

                       };
                   }
               };
           }
       ]);
