angular.ctModule('ct.directives.mediaDrop', ['ct.directives.setImageProportionClass', 'ct.services.csphotoselector'])

       .value('mediaDropDefaults', {
           text: {
               addMedia                 : 'FOTO',
               maxFileSize              : '(max. 5MB)',
               chooseAnImage            : 'Foto auswählen',
               chooseAnImageFromFacebook: 'Foto von Facebook',
               removeMedia              : 'Entfernen',

               dropzone      : 'Jaaa, genau hier!',
               dropzoneHelper: 'Datei hier ablegen'
           },

           dropzoneActivator      : 'self::helper',
           dropzoneHelperActivator: 'body',
           useCropper             : false
       })
       /**
        * @ngdoc directive
        * @name ct.directives.directive:labelHide
        * @restrict A
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * Wrap an element for use with drag and drop image uploading
        *
        *  - config values:
        *   - dropzoneParent {string}
        *     Selector string where the dropzone will be inserted
        *
        *   - dropzoneActivator {string}
        *     Selector string for the element to trigger drag over events to show
        *     the dropzone
        *
        *   - useCropper {bool}
        *     Activates the cropping feature
        *
        *  - attributes
        *   - onprogress {function}
        *     Passes paramater `active` as a bool to determine if the directive is
        *     busy or not
        *
        *   - ondrop {function}
        *     Fired when an image is dropped or selected (no parameters)
        *
        *   - onupload {function}
        *     Fired when the image upload was successfull
        *     Passes `ìmage_url`, `image_id` and `upload_token`
        *
        *   - onremove {function}
        *     Fired when the media is removed (no parameters)
        *
        *   - onerror {function}
        *     Fired when the API returns an error
        *     Passes `data` - the JSON response from the API
        *
        *   - name {string}
        *     Used to place a reference into the parent scope named by the
        *     attributes value
        *
        *   - cropRelation {string}
        *     Used to define the relation. Should be given like "16:9"
        *
        *   - maxHeight {number}
        *     A number that defines the maximum container height in pixel.
        *
        * @scope
        *
        */
       .directive('mediaDrop', [
           'mediaDropDefaults', function(mediaDropDefaults) {

               var mediaDropDirective = {};

               /**
                * Allow this directive as attribute only
                * @type {string}
                */
               mediaDropDirective.restrict = 'A';

               /**
                * Define the scope and databinding of the directive
                * @type {object}
                */
               mediaDropDirective.scope = {
                   mediaDrop : '&',
                   onProgress: '&',
                   onprogress: '&',
                   onDrop    : '&',
                   ondrop    : '&',
                   onUpload  : '&',
                   onupload  : '&',
                   onRemove  : '&',
                   onremove  : '&',
                   onError   : '&',
                   onerror   : '&',
                   name      : '@'
               };

               /**
                * Define the TemplateURL for the directives markup
                * @type {string}
                */
               mediaDropDirective.templateUrl = 'theme/media-drop';

               mediaDropDirective.controller = [
                   "$scope",
                   "$element",
                   "$attrs",
                   "$templateCache",
                   "$log",
                   "$q",
                   "CTCSPhotoselector",
                   "$rootScope",
                   "$compile",
                   function($scope, $element, $attr, $templateCache, $log, $q, CTCSPhotoselector, $rootScope, $compile) {
                       /**
                        * Detect FileAPI
                        */
                       if(!window.FileAPI) {
                           throw new Error('FileAPI not found!');
                       }
                       if(!window.jQuery) {
                           throw new Error('jQuery is required!');
                       }

                       var _useCropper  = false,
                           _forceNoCrop = false;


                       /**
                        * Set default values
                        */
                       $scope.mediaDrop = angular.extend({}, mediaDropDefaults, $scope.mediaDrop());
                       $scope.data = {};

                       $scope.mediaDrop.useLarge = function() {
                           return $attr.size == 'large';
                       };

                       $scope.mediaDrop.useCropper = function() {
                           return !_forceNoCrop && _useCropper;
                       };

                       if($attr.cropRelation) {
                           _useCropper         = true;
                           $scope.cropRelation = $attr.cropRelation;
                       }
                       else if($scope.mediaDrop.useCropper()) {
                           $scope.cropRelation = "1:1";
                       }

                       $scope.mediaDrop.containerStyle = function() {
                           return {}
                       };

                       $scope.mediaDrop.heightAdjustmentStyle = (function() {
                           if(!$scope.mediaDrop.useCropper()) {
                               return {"padding-top": '100%'};
                           }
                           else {
                               var x = parseFloat(($scope.cropRelation || "1:1").split(':')[0]),
                                   y = parseFloat(($scope.cropRelation || "1:1").split(':')[1]);

                               return {"padding-top": (100 * (y / x)).toString() + '%'};
                           }
                       }());

                       $scope.onprogress = (($attr.onProgress && !$attr.onprogress) ? $scope.onProgress : $scope.onprogress);
                       $scope.ondrop     = (($attr.onDrop && !$attr.ondrop) ? $scope.onDrop : $scope.ondrop);
                       $scope.onupload   = (($attr.onUpload && !$attr.onupload) ? $scope.onUpload : $scope.onupload);
                       $scope.onremove   = (($attr.onRemove && !$attr.onremove) ? $scope.onRemove : $scope.onremove);
                       $scope.onerror    = (($attr.onError && !$attr.onerror) ? $scope.onError : $scope.onerror);

                       var
                           /**
                            * Get HTMLElements
                            */
                           $elements         = {
                               fileInput: jQuery('.mediaDrop--addMedia-fileInput', $element)
                           },

                           $cropper          = {
                               cropper: jQuery('.mediaDrop--image-cropper', $element),
                               image  : jQuery('.mediaDrop--image-cropper img', $element).first()
                           },

                           /**
                            * Save if the dropzone if hovered
                            * @type {boolean}
                            */
                           dropzoneIsHovered = false,

                           /**
                            * Get Dropzone Elements
                            * @type {{wrapper: *, dropzone: *, dropzoneHelper: *, activator: *, helperActivator: *}}
                            */
                           $dropzone         = {
                               wrapper        : jQuery($templateCache.get('ct.directives.mediaDrop.dropzone.html')),
                               dropzone       : null,
                               dropzoneHelper : null,
                               activator      : ($scope.mediaDrop.dropzoneActivator == 'self::helper' ? $scope.mediaDrop.dropzoneActivator : jQuery($scope.mediaDrop.dropzoneActivator)),
                               helperActivator: jQuery($scope.mediaDrop.dropzoneHelperActivator)
                           };

                       $dropzone.dropzone       = jQuery('.mediaDrop--dropzone', $dropzone.wrapper);
                       $dropzone.dropzoneHelper = jQuery('.mediaDrop--dropzone-helper', $dropzone.wrapper);

                       if($dropzone.activator == 'self::helper') {
                           $dropzone.activator = $dropzone.dropzoneHelper
                       }

                       $compile($dropzone.wrapper)($scope);

                       function calculateMaxContainerHeight() {
                           var maxHeight = parseInt($attr.maxHeight);

                           if(!isNaN(maxHeight)) {
                               var container               = $element.find('.mediaDrop--content').first(),
                                   originalContainerHeight = container.height(),
                                   originalContainerWidth  = container.width(),
                                   newContainerWidth       = (originalContainerWidth / originalContainerHeight) * maxHeight;

                               container.css({"max-width": newContainerWidth.toString() + 'px'});
                           }
                       }

                       /**
                        * hoverDropzone
                        * @description
                        * handle actions when hovering the dropzone activator
                        *
                        * @param over {bool}
                        */
                       function hoverDropzone(over) {
                           if($scope.data.image) return;

                           if(over) {
                               //$log.debug('Hover Dropzone');
                               $dropzone.dropzone.show();
                               $element.addClass('mediaDrop--dragover');
                           }
                           else {
                               //$log.debug('UnHover Dropzone');
                               $dropzone.dropzone.hide();
                               $element.removeClass('mediaDrop--dragover');
                           }

                           dropzoneIsHovered = over;
                       }

                       /**
                        * hoverDropzoneHelper
                        * @description
                        * handle actions when hovering the dropzone helper activator
                        *
                        * @param over {bool}
                        */
                       function hoverDropzoneHelper(over) {
                           if($scope.data.image) return;

                           if(over && !dropzoneIsHovered) {
                               //$log.debug('Hover DropzoneHelper');
                               $dropzone.dropzoneHelper.css({'opacity': ''}).show();
                           }
                           else if(!dropzoneIsHovered) {
                               //$log.debug('UnHover DropzoneHelper');
                               $dropzone.dropzoneHelper.css({'opacity': ''}).hide();
                           }
                           else if(dropzoneIsHovered) {
                               $dropzone.dropzoneHelper.css({'opacity': '0'})
                           }
                       }

                       /**
                        * fileDrop
                        *
                        * @description
                        * Handle actions when a file is dropped
                        *
                        * @param files
                        */
                       function fileDrop(files) {
                           if($scope.data.image) return;

                           setTimeout(hoverDropzone, 50);
                           setTimeout(hoverDropzoneHelper, 50);

                           $scope.$apply(function() {
                               $scope.ondrop();
                           });

                           uploadFile(files[0]);
                       }

                       /**
                        * fileSelect
                        *
                        * @description
                        * Handle the file selection
                        *
                        * @param event
                        */
                       function fileSelect(event) {
                           $scope.$apply(function() {
                               $scope.ondrop();
                           });

                           var files = FileAPI.getFiles(event);
                           uploadFile(files[0]);
                       }

                       /**
                        * uploadFile
                        *
                        * @description
                        * Handle the file upload
                        *
                        * @param file
                        */
                       function uploadFile(file) {
                           $scope.$apply(function() {
                               $scope.onprogress({active: true});
                           });

                           var requestConfig = {
                                   method: 'POST',
                                   url   : '/uploaded_images'
                               },
                               ieVersion     = (function() {
                                   var myNav = navigator.userAgent.toLowerCase();
                                   return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
                               }());

                           if(ieVersion && ieVersion <= 9) {
                               requestConfig.imageElement = $elements.fileInput.first();
                           }
                           else {
                               requestConfig.imageFile = file;
                           }

                           $CB_API(requestConfig).then(function(data) {

                               $scope.data              = {};
                               $scope.data.image_id     = data.image_id;
                               $scope.data.image    = data.image;
                               $scope.data.upload_token = data.upload_token;

                               $scope.$apply(function() {
                                   console.log($scope.data);
                                   $scope.onprogress({active: false});
                                   $scope.onupload($scope.data);
                               });
                               // broadcast an event
                               $rootScope.$broadcast('mediaUpload', 'success');
                           }, function(data) {
                               if(window.console && window.console.warn) {
                                   console.warn(data);
                               }
                               $scope.$apply(function() {
                                   $scope.onprogress({active: false});
                                   $scope.onerror({data: data});
                               });
                           });
                       }

                       /**
                        * cropableImageDidLoad
                        *
                        * @drescription
                        * when the image is loaded we need to update the axis to drag and
                        * reset the position
                        */
                       function cropableImageDidLoad() {
                           var ratioX      = parseFloat(($scope.cropRelation || "1:1").split(':')[0]),
                               ratioY      = parseFloat(($scope.cropRelation || "1:1").split(':')[1]),
                               imageRatioY = ($cropper.image.height() / $cropper.image.width()) * ratioX;

                           var axis = (imageRatioY < ratioY ? 'x' : 'y');

                           $cropper.image
                                   .data('axis', axis)
                                   .draggable('option', 'axis', axis)
                                   .css({
                                       left: '0px',
                                       top : '0px'
                                   });
                       }

                       /**
                        * cropableImageDidDrag
                        *
                        * @description
                        * On drag we need to make sure the image is not dragged outside
                        *
                        * @param event
                        * @param ui
                        * @returns {boolean}
                        */
                       function cropableImageDidDrag(event, ui) {
                           var parentWidth  = $cropper.image.parent().width(),
                               parentHeight = $cropper.image.parent().height(),
                               imageWidth   = $cropper.image.width(),
                               imageHeight  = $cropper.image.height();

                           switch($cropper.image.data('axis')) {
                               case 'x':
                                   if(ui.position.left >= 0) return false;
                                   if((ui.position.left + imageWidth) < (parentWidth - 1)) return false;
                                   break;

                               case 'y':
                                   if(ui.position.top >= 0) return false;
                                   if((ui.position.top + imageHeight) < (parentHeight - 1)) return false;
                                   break;
                           }
                       }

                       /**
                        * cropableImageDidFinishDrag
                        *
                        * @description
                        * After the drag we want to save the new indention
                        *
                        * @param event
                        * @param ui
                        */
                       function cropableImageDidFinishDrag(event, ui) {
                           var imageNaturalWidth  = $cropper.image[0].naturalWidth,
                               imageNaturalHeight = $cropper.image[0].naturalHeight,
                               imageWidth         = $cropper.image.width(),
                               imageHeight        = $cropper.image.height(),

                               factor             = ($cropper.image.data('axis') == 'x' ? (imageNaturalWidth / imageWidth) : (imageNaturalHeight / imageHeight)),

                               indentTop          = -(ui.position.top * factor),
                               indentLeft         = -(ui.position.left * factor);

                           $scope.$apply(function() {
                               $scope.data.indent_top  = indentTop;
                               $scope.data.indent_left = indentLeft;
                           });
                       }

                       /**
                        * setMediaFromOutside
                        */
                       function setMediaFromOutside(image, type, disallow_crop) {
                           $scope.data.image = image;
                           $scope.data.type      = type || 'IMG';

                           _forceNoCrop = (disallow_crop === true);
                       }

                       /**
                        *
                        */
                       function submitMedia() {
                           var waitForIt = $q.defer();

                           function submitImageCrop() {
                               return $CB_API({
                                   method: 'PUT',
                                   url   : '/uploaded_images/' + $scope.data.image_id,
                                   data  : {
                                       upload_token: $scope.data.upload_token,
                                       format      : $scope.cropRelation,
                                       left_indent : $scope.data.indent_left,
                                       top_indent  : $scope.data.indent_top
                                   }
                               }).then(function() {
                                   resolve()
                               }, function(data) {
                                   reject(data)
                               });
                           }

                           function uploadImageURL() {
                               return $CB_API({
                                   method: 'POST',
                                   url   : '/uploaded_images',
                                   params: {
                                       url: $scope.data.image_url
                                   }
                               }).then(function(data) {
                                   $scope.$apply(function() {
                                       $scope.data.image_id     = data.image_id;
                                       $scope.data.upload_token = data.upload_token;

                                       $scope.onupload(angular.extend({}, $scope.data, {image: data.image}));
                                   });

                                   if($scope.mediaDrop.useCropper()) {
                                       submitImageCrop();
                                   }
                                   else {
                                       resolve();
                                   }
                               }, function(data) {
                                   reject(data)
                               });
                           }

                           function resolve() {
                               $scope.$apply(function() {
                                   $scope.onprogress({active: false});
                                   waitForIt.resolve();
                               });
                           }

                           function reject(data) {
                               $scope.$apply(function() {
                                   $scope.onprogress({active: false});
                                   $scope.onerror({data: data});
                                   waitForIt.reject(data)
                               });
                           }

                           if($scope.mediaDrop.useCropper()) {

                               $scope.onprogress({active: true});

                               if($scope.data.upload_token) {
                                   submitImageCrop();
                               }
                               else if($scope.data.image) {
                                   uploadImageURL();
                               }
                               else {
                                   waitForIt.resolve();
                               }

                           }
                           else if(!$scope.data.upload_token && $scope.data.image) {
                               uploadImageURL();
                           }
                           else {
                               waitForIt.resolve();
                           }

                           return waitForIt.promise;
                       }

                       $scope.removeMedia = function(fromEvent) {
                           $scope.data = {};
                           $elements.fileInput.each(function(index, input) {
                               input.value = null;
                           });

                           // broadcast an event
                           $rootScope.$broadcast('mediaUpload', 'removed');

                           // if not called through a received event (newUserGeneratedContentSent), run callback function
                           if(typeof fromEvent === 'undefined') {
                               $scope.onremove();
                           }

                           _forceNoCrop = false;
                       };

                       $scope.$on('newUserGeneratedContentSent', function(data) {
                           $scope.removeMedia(true);
                       });

                       $scope.selectFacebookPhoto = function() {

                           CTCSPhotoselector.select().then(function(data) {
                               $scope.data              = {};
                               $scope.data.image_id     = data.image_id;
                               $scope.data.image        = {
                                   xs      : data.image_url,
                                   sm      : data.image_url,
                                   md      : data.image_url,
                                   xl      : data.image_url,
                                   original: data.image_url,
                               };
                               $scope.data.upload_token = data.upload_token;

                               $scope.onprogress({active: false});
                               $scope.onupload($scope.data);

                               // broadcast an event
                               $rootScope.$broadcast('mediaUpload', 'success');
                           }, function(data) {
                               if(window.console && window.console.warn) {
                                   console.warn(data);
                               }

                               $scope.onprogress({active: false});
                               $scope.onerror({data: data});
                           });
                       };

                       /**
                        * Check if Drag and drop is supported
                        */
                       if(FileAPI.support.dnd) {

                           /**
                            * Set the parent element for the dropzone
                            */
                           if($scope.mediaDrop.dropzoneParent) {
                               $dropzone.parent = jQuery($scope.mediaDrop.dropzoneParent);
                           }
                           else {
                               $dropzone.parent = $element.parents('form');
                           }

                           /**
                            * Add dropzone to the DOM
                            */
                           $dropzone.dropzone.hide();
                           $dropzone.dropzoneHelper.hide();
                           $dropzone.wrapper.appendTo($dropzone.parent);


                           /**
                            * Add D'n'D events
                            */
                           FileAPI.event.dnd(document.getElementsByTagName('body')[0], angular.noop, angular.noop);
                           FileAPI.event.dnd($dropzone.helperActivator[0], hoverDropzoneHelper, angular.noop);
                           FileAPI.event.dnd($dropzone.activator[0], hoverDropzone, angular.noop);
                           FileAPI.event.dnd($dropzone.dropzone[0], angular.noop, fileDrop);
                           FileAPI.event.dnd($dropzone.dropzoneHelper[0], angular.noop, fileDrop);
                       }

                       /**
                        * Add FileInput events
                        */
                       $elements.fileInput.each(function(index, input) {
                           FileAPI.event.on(input, 'change', fileSelect);
                       });


                       /**
                        * Check if image should be cropped
                        */
                       if($scope.mediaDrop.useCropper() && jQuery.fn.draggable) {

                           $cropper.image
                                   .on('load', cropableImageDidLoad)
                                   .draggable({
                                       axis: 'x',
                                       drag: cropableImageDidDrag,
                                       stop: cropableImageDidFinishDrag
                                   });

                       }
                       else if($scope.mediaDrop.useCropper() && !jQuery.fn.draggable) {
                           $log.error('\'mediaDrop\' depends on jQuery and jQuery.draggable');
                       }

                       /**
                        * If a name is set - add a reference to the parent scope
                        */
                       if($scope.name) {
                           var _parent          = $attr.hasOwnProperty('parent') ? $scope.$parent[$attr.parent] : $scope.$parent;
                           _parent[$scope.name] = {
                               setMedia   : setMediaFromOutside,
                               removeMedia: $scope.removeMedia,
                               submitMedia: submitMedia
                           };

                           $scope.$on($scope.name + '.removeMedia', $scope.removeMedia);

                           $scope.$on('$destroy', function() {
                               delete $scope.$parent[$scope.name];
                           });
                       }

                       //On Init run height calculation
                       setTimeout(calculateMaxContainerHeight, 100);
                   }
               ];


               /**
                * Tell angular about the awesomeness
                */
               return mediaDropDirective;
           }
       ])

       .directive('mediaDropSetImageProportionClass', [
           function() {
               var _setImageProportionClassDirective = {};

               _setImageProportionClassDirective.restrict = 'A';

               _setImageProportionClassDirective.link = function($scope, $element, $attrs) {

                   var actualImage;

                   function calculateImageClass() {
                       if(actualImage.width && actualImage.height) {
                           var ratioX      = parseFloat(($scope.cropRelation || "1:1").split(':')[0]),
                               ratioY      = parseFloat(($scope.cropRelation || "1:1").split(':')[1]),
                               imageRatioY = (actualImage.height / actualImage.width) * ratioX;


                           if(imageRatioY < ratioY) {
                               $element.removeClass('img-portrait').addClass('img-landscape');
                           }
                           else {
                               $element.removeClass('img-landscape').addClass('img-portrait');
                           }
                       }
                       else {
                           setTimeout(calculateImageClass, 50);
                       }
                   }

                   /**
                    * Due to Firefox Issues, we have to load the original Image
                    * and calculate the dimensions from there
                    */
                   function setupImageCalculation() {
                       actualImage     = new Image();
                       actualImage.src = $attrs.src;
                       calculateImageClass();
                   }


                   $scope.$watch(function() {
                       return $attrs.src
                   }, function() {
                       setupImageCalculation();
                   });
               };

               return _setImageProportionClassDirective;
           }
       ]);
