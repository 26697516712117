angular.ctModule('ct.services.people').service('CTServicePeople', [
    "$q", "CTServiceDataParent", function($q, CTServiceDataParent) {
        var _ctServicePeople = new CTServiceDataParent('people', function(data) {
            return new CTPerson(data);
        }, {multiple: 'people', single: 'person'});

        var personCounterDefered = {},
            personActionsDefered = {},
            personEntriesDefered = {};


        _ctServicePeople.getPersonActions = function(person) {
            if(!person) {
                throw new Error('No person id specified');
            }

            if(!personActionsDefered[person.id]) {
                personActionsDefered[person.id] = $q.defer();

                person.actions         = {};
                person.actions.loading = true;

                $CB_API({
                    method: 'GET',
                    url   : '/people/' + person.id + '/actions'
                }).then(function(data) {

                    delete person.actions.loading;
                    person.update(data.person);

                    personActionsDefered[person.id].resolve(person);
                }, function(error) {
                    personActionsDefered[person.id].reject(error)
                });
            }

            return personActionsDefered[person.id].promise;
        };

        _ctServicePeople.loadPersonsEntries = function(person, module_route, module_identifier) {

            if(!person) {
                throw new Error('No person id specified');
            }

            if(!personEntriesDefered[person.id + module_identifier]) {
                personEntriesDefered[person.id + module_identifier] = $q.defer();

                person.entries                    = person.entries || {};
                person.entries[module_identifier] = {loading: true};

                $CB_API({
                    method: 'GET',
                    url   : '/people/' + person.id + '/' + module_route + '/' + module_identifier
                }).then(function(data) {

                    //console.log(data);

                    person.entries[module_identifier] = data[module_route];

                    personEntriesDefered[person.id + module_identifier].resolve(person);
                }, function(error) {
                    personEntriesDefered[person.id + module_identifier].reject(error)
                });
            }

            return personEntriesDefered[person.id + module_identifier].promise;

        };

        _ctServicePeople.loadPersonCounter = function(person) {
            if(!person) {
                throw new Error('No person id specified');
            }

            function addAllCounters() {
                var total = 0;
                angular.forEach(person.content_counters, function(module_counter) {
                    angular.forEach(module_counter, function(count) {
                        !isNaN(count) && (total += count);
                    });
                });

                return total;
            }

            if(!personCounterDefered[person.id]) {
                personCounterDefered[person.id] = $q.defer();

                person.content_counters.loading = true;

                $CB_API({
                    url   : '/people/' + person.id,
                    method: 'GET',
                    params: {
                        fields: 'content_counter'
                    }
                }).then(function(data) {

                    person.update({content_counters: data.person.content_counters});
                    person.content_counters.total   = addAllCounters();
                    person.content_counters.loading = false;

                    personCounterDefered[person.id].resolve(person);
                    delete personCounterDefered[person.id];
                }, function(data) {
                    person.content_counters.loading = false;
                    personCounterDefered[person.id].reject(person);
                    delete personCounterDefered[person.id];
                });
            }

            return personCounterDefered[person.id].promise;
        };

        _ctServicePeople.save = function(person) {

            if(!person.id) {
                throw new Error('Creating new person is not supported');
            }

            var _rawData = {};

            _rawData.name    = person.name;
            _rawData.surname = person.surname;
            _rawData.email   = person.email;
            _rawData.phone   = person.phone;
            _rawData.blocked = person.blocked;
            if(typeof person.addresses !== 'undefined') {
                _rawData.address = person.addresses[0];
            }

            //_rawData.address.street = (typeof _rawData.address.street !== 'undefined') ? _rawData.address.street : (typeof person.addresses[0].street !== 'undefined' ? person.addresses[0].street : '');
            //_rawData.address.zip_code = (typeof _rawData.address.zip_code !== 'undefined') ? _rawData.address.zip_code : (typeof person.addresses[0].zip_code !== 'undefined' ? person.addresses[0].zip_code : '');
            //_rawData.address.city = (typeof _rawData.address.street !== 'undefined') ? _rawData.address.city : (typeof person.addresses[0].city !== 'undefined' ? person.addresses[0].city : '');

            var waitForIt = $q.defer();

            $CB_API({
                method: 'POST',
                url   : '/people',
                data  : _rawData
            }).then(function() {
                waitForIt.resolve();
            }, function() {
                waitForIt.reject();
            });

            return waitForIt.promise;
        };


        return _ctServicePeople;
    }
]);
