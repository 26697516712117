/**
 * Angular Module "toolsDragUpload"
 *
 * Provides a directive to support uploading images via "drag and drop"
 * Optional it can Automatically upload files to the Tools server
 *
 *
 */

angular.ctModule('toolsDragUpload', [])
       .directive('toolsDragUpload', [
           function() {
               "use strict";

               var toolsDragUploadDirective,
                   toolsDragUploadLink,
                   toolsDragUploadUploader,

                   handlerDragEnter,
                   handlerDragLeave,
                   handlerDrop,

                   $fallbackElement,
                   DirectiveElement;

               toolsDragUploadUploader = function(file, scope, isElement) {
                   if(!$CB_API || !file) {
                       return false;
                   }

                   scope.toolsDragUploadUploadedFileIsLoading = scope.toolsDragUploadProgressFlag = true;

                   if(!isElement) {

                       $CB_API({
                           method        : 'POST',
                           url           : '/uploaded_images',
                           authentication: {type: 'customer'},
                           imageFile     : file
                       }).then(function(data) {
                           scope.toolsDragUploadUploadedImageId = data.image_id;

                           scope.toolsDragUploadUploadedFileIsLoading = scope.toolsDragUploadProgressFlag = false;
                       }, function() {
                           scope.toolsDragUploadUploadedFileIsLoading = scope.toolsDragUploadProgressFlag = false;
                       });
                   }
                   else {

                       $CB_API({
                           method        : 'POST',
                           url           : '/uploaded_images',
                           authentication: {type: 'customer'},
                           imageElement  : file
                       }).then(function(data) {
                           scope.toolsDragUploadUploadedImageId       = data.image_id;
                           scope.toolsDragUploadUploadedFileIsLoading = scope.toolsDragUploadProgressFlag = false;
                           scope.toolsDragUploadDataUri = data.image_url;
                       }, function() {
                           scope.toolsDragUploadUploadedFileIsLoading = scope.toolsDragUploadProgressFlag = false;
                       });

                   }
               };


               /*
                * Setup Eventhandler for DragEnter */
               handlerDragEnter = function(event, scope, $element, $attributes) {
                   event.stopPropagation();
                   event.preventDefault();

                   event.originalEvent.dataTransfer.dropEffect = 'copy';

                   /*
                    * Enter Effect */
                   jQuery('#story--submit--file-dropzone', $element).css({
                       display: 'block',
                       opacity: 1
                   });
               };

               /*
                * Setup Eventhandler for DragEnter */
               handlerDragLeave = function(event, scope, $element, $attributes) {
                   var inside = jQuery.contains($element[0], event.target);

                   if(inside) {
                       return;
                   }

                   /*
                    * Leave Effect */
                   jQuery('#story--submit--file-dropzone', $element).css({
                       display: 'none',
                       opacity: 0
                   });
               };

               /*
                * Setup Eventhandler for DragEnter */
               handlerDrop = function(event, scope, $element, $attributes) {
                   jQuery('#story--submit--file-dropzone', $element).css({
                       display: 'none',
                       opacity: 0
                   });

                   event.stopPropagation();
                   event.preventDefault();

                   var files  = event.originalEvent.dataTransfer.files,
                       file   = files[0],
                       Reader = new FileReader();

                   Reader.onload = function(readerEvent) {
                       scope.$apply(function() {
                           scope.toolsDragUploadDataUri = readerEvent.target.result;
                       });
                   };

                   Reader.readAsDataURL(file);
                   toolsDragUploadUploader(file, scope);
               };


               /*
                * Setup the linking function for the directive */
               toolsDragUploadLink = function(scope, $element, $attributes) {

                   DirectiveElement = $element;

                   /*
                    * Load the fallback element */
                   $fallbackElement = document.getElementById(scope.toolsDragUploadFallbackElementId);

                   /*
                    * First we check for File API support */
                   if(window.FileReader && window.FileAPI) {

                       //angular.element($fallbackElement).hide();

                       $element.bind('dragenter', function(event) {
                           handlerDragEnter(event, scope, $element, $attributes);
                       });
                       $element.bind('dragover', function(event) {
                           handlerDragEnter(event, scope, $element, $attributes);
                       });

                       $element.bind('dragleave', function(event) {
                           handlerDragLeave(event, scope, $element, $attributes);
                       });
                       $element.bind('dragend', function(event) {
                           handlerDragLeave(event, scope, $element, $attributes);
                       });

                       $element.bind('drop', function(event) {
                           handlerDrop(event, scope, $element, $attributes);
                       });

                       FileAPI.event.on($fallbackElement, 'change', function(event) {
                           scope.$apply(function() {
                               var files  = FileAPI.getFiles(event.target),
                                   file   = files[0],
                                   Reader = new FileReader();

                               Reader.onload = function(readerEvent) {
                                   scope.$apply(function() {
                                       scope.toolsDragUploadDataUri = readerEvent.target.result;
                                   });
                               };

                               Reader.readAsDataURL(file);
                               toolsDragUploadUploader(file, scope);
                           });
                       });

                   }
                   else {
                       //                $element.hide();

                       FileAPI.event.on($fallbackElement, 'change', function(event) {
                           scope.$apply(function() {
                               var files = FileAPI.getFiles(event.target),
                                   file  = files[0];

                               FileAPI.readAsDataURL(file, function(evt) {
                                   if(evt.type == 'load') {
                                       scope.$apply(function() {
                                           scope.toolsDragUploadDataUri = evt.result;
                                       });
                                   }
                               });

                               toolsDragUploadUploader($fallbackElement, scope, true);
                           });
                       });
                   }
               };


               /*
                * Setup the Directive Object */
               toolsDragUploadDirective      = {};
               toolsDragUploadDirective.link = toolsDragUploadLink;

               /*
                * Setup the scope */
               toolsDragUploadDirective.scope                                  = {};
               toolsDragUploadDirective.scope.toolsDragUploadDataUri           = '=';
               toolsDragUploadDirective.scope.toolsDragUploadProgressFlag      = '=';
               toolsDragUploadDirective.scope.toolsDragUploadFallbackElementId = '@';
               toolsDragUploadDirective.scope.toolsDragUploadUploadedImageId   = '=';

               return toolsDragUploadDirective;
           }
       ]);
