function CT_string2date(string) {

    var dateString = string.split(' ')[0],
        timeString = string.split(' ')[1],

        date,
        time;

    /** Set the Date */
    var year  = parseInt(dateString.split('-')[0], 10),
        month = (parseInt(dateString.split('-')[1], 10) - 1),     //Month is zero based - so january is 0 and december is 11
        day   = parseInt(dateString.split('-')[2], 10);

    date = new Date(year, month, day, 0, 0, 0);

    /** Set the Time */
    var hours   = parseInt(timeString.split(':')[0], 10),
        minutes = parseInt(timeString.split(':')[1], 10),
        seconds = parseInt(timeString.split(':')[2], 10);

    time = new Date();
    time.setUTCHours(hours);
    time.setUTCMinutes(minutes);
    time.setUTCSeconds(seconds);

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
}

function CT_date2string(date, type) {
    type = type || 'full';

    var year    = date.getUTCFullYear().toString(),
        month   = (date.getUTCMonth() + 1).toString(),
        day     = date.getUTCDate().toString(),

        hours   = date.getUTCHours().toString(),
        minutes = date.getUTCMinutes().toString(),
        seconds = date.getUTCSeconds().toString();

    month = (month < 10) ? '0' + month : month;
    day   = (day < 10) ? '0' + day : day;

    hours   = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    switch(type) {
        case 'time':
            return hours + ':' + minutes + ':' + seconds;

        case 'date':
            return year + '-' + month + '-' + day;

        default:
        case 'full':
            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
}
