angular.ctModule('ct.services.helpers', []).service('CTServiceHelper', [
    "$log", "$location", "$rootScope", function($log, $location, $rootScope) {
        "use strict";

        /**
         * @description
         * the buzzer type service object
         *
         * @type {{}}
         */
        var CTServiceHelper = {};

        /**
         * @description
         * converts a date object to a string accepted by the api
         *
         * @param $dateObject
         * @param only
         * @returns {string}
         */
        CTServiceHelper.convertDateToApiString = function($dateObject, only) {
            var month = $dateObject.getUTCMonth() + 1;
            if(month < 10) {
                month = '0' + month;
            }

            var day = $dateObject.getUTCDate();
            if(day < 10) {
                day = '0' + day;
            }

            var hours = $dateObject.getUTCHours();
            if(hours < 10) {
                hours = '0' + hours;
            }

            var minutes = $dateObject.getUTCMinutes();
            if(minutes < 10) {
                minutes = '0' + minutes;
            }

            var date = $dateObject.getUTCFullYear() + '-' + month + '-' + day,
                time = hours + ':' + minutes + ':00';

            switch(only) {
                case 'time':
                    return time;

                case 'date':
                    return date;

                default:
                    return date + ' ' + time;
            }
        };

        /**
         * @description
         * push a google analytics action
         *
         * @param {array} trackingParams and array of tracking params.
         *      needed in the following order: category, action, opt_label, opt_value, opt_noninteraction
         *      (https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide#SettingUpEventTracking)
         */
        CTServiceHelper.addGaq = function(trackingParams) {
            if(typeof ga !== 'undefined') {
                ga.apply(this, ['send', 'event'].concat(trackingParams));
            }
            else if(typeof _gaq !== 'undefined') {
                _gaq.push(['_trackEvent'].concat(trackingParams));
            }
            else {
                $log.info('analytics not available.', 'would track event', trackingParams);
            }
        };

        /**
         * wrapper function for addGaq
         *
         * @param {array} trackingParams an array of tracking params.
         *      needed in the following order: category, action, opt_label, opt_value, opt_noninteraction
         *      (https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide#SettingUpEventTracking)
         */
        CTServiceHelper.trackEvent = function(trackingParams) {
            this.addGaq(trackingParams);
        };

        /**
         * @description
         * track a page view. prepends the current path to the given urlPostFix before sending.
         * www.page.org/node/1234 + urlPostFix will be sent as /node/1234/urlPostFix
         *
         * https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiBasicConfiguration?hl=de#_gat.GA_Tracker_._trackPageview
         *
         * @param {string} urlPostFix the post fix to the url to be tracked
         */
        CTServiceHelper.trackPageView = function(urlPostFix) {
            // prepend the full url until the hash part to the urlPostFix (to get this url see http://stackoverflow.com/a/5818284/320564)
            var fullUrl = window.location.pathname + urlPostFix;

            if(typeof ga !== 'undefined') {
                ga('send', 'pageview', fullUrl);
            }
            else if(typeof _gaq !== 'undefined') {
                _gaq.push(['_trackPageview'].concat(fullUrl));
            }
        };

        /**
         * check if a given parameter is a number
         *
         * @param n
         * @returns {boolean}
         */
        CTServiceHelper.isNumber = function(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        };

        /**
         * @description
         * load a GET parameter by name and return its value
         * @param {string} name the get param that we want to search
         * @returns {string} the value of the get param or empty string if the param is not found
         */
        CTServiceHelper.getParameterByName = function(name) {
            name        = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex   = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        };

        /**
         * @description
         * add a param to an existing url and return that url
         * @param {string} key
         * @param {string} value
         * @returns {Array}
         */
        CTServiceHelper.addParameterToUrl = function(key, value) {
            var currentSearch  = angular.copy($location.search());
            currentSearch[key] = value;

            var url = [];
            for(var i in currentSearch) {
                if(currentSearch.hasOwnProperty(i)) {
                    url.push(i + '=' + currentSearch[i]);
                }
            }
            url = location.origin + location.pathname + '?' + url.join('&');
            url += $location.hash();

            return url;
        };

        /**
         * Randomize array element order in-place.
         * Using Fisher-Yates shuffle algorithm.
         *
         * @param array
         * @returns {*}
         */
        CTServiceHelper.shuffleArray = function(array) {
            for(var i = array.length - 1; i > 0; i--) {
                var j    = Math.floor(Math.random() * (i + 1));
                var temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        };

        /**
         * broadcast an event.
         *
         * @param {ctHelperEvents} event_type the event to broadcast
         * @param {{}} params additional params
         */
        CTServiceHelper.broadcastEvent = function(event_type, params) {
            $rootScope.$broadcast(event_type, params);
        };

        /**
         * @description
         * this function takes a decimal number in one language (german or english) and converts it to the other language (converts decimals)
         *
         * @param {string} from     the language code FROM with we want to convert ('de' or 'en')
         * @param {string} n        the number to conviert
         * @returns {XML|string}
         */
        CTServiceHelper.convertDecimals = function(from, n) {
            if(n === null) {
                return n;
            }
            else if(from === 'de') {
                return n.toString().replace(',', '.');
            }
            else if(from === 'en') {
                return n.toString().replace('.', ',');
            }
            else {
                return n;
            }
        };

        /* jshint ignore:start */
        CTServiceHelper.strtotime = function(a, b) {
            function o(a, b, c) {
                var d, e = h[b];
                "undefined" != typeof e && (d = e - g.getDay(), 0 === d ? d = 7 * c : d > 0 && "last" === a ? d -= 7 : 0 > d && "next" === a && (d += 7), g.setDate(g.getDate() + d))
            }

            function p(a) {
                var b = a.split(" "), c = b[0], d = b[1].substring(0, 3), e = /\d+/.test(c), f = "ago" === b[2],
                    h                                                                          = ("last" === c ? -1 : 1) * (f ? -1 : 1);
                if(e && (h *= parseInt(c, 10)), i.hasOwnProperty(d) && !b[1].match(/^mon(day|\.)?$/i)) return g["set" + i[d]](g["get" + i[d]]() + h);
                if("wee" === d) return g.setDate(g.getDate() + 7 * h);
                if("next" === c || "last" === c) {
                    o(c, d, h);
                }
                else if(!e) return !1;
                return !0
            }

            var c, d, e, f, g, h, i, j, k, l, m, n = !1;
            if(!a) return n;
            if(a = a.replace(/^\s+|\s+$/g, "")
                    .replace(/\s{2,}/g, " ")
                    .replace(/[\t\r\n]/g, "")
                    .toLowerCase(), d = a.match(/^(\d{1,4})([\-\.\/\:])(\d{1,2})([\-\.\/\:])(\d{1,4})(?:\s(\d{1,2}):(\d{2})?:?(\d{2})?)?(?:\s([A-Z]+)?)?$/), d && d[2] === d[4]) {
                if(d[1] > 1901) {
                    switch(d[2]) {
                        case"-":
                            return d[3] > 12 || d[5] > 31 ? n : new Date(d[1], parseInt(d[3], 10) - 1, d[5], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3;
                        case".":
                            return n;
                        case"/":
                            return d[3] > 12 || d[5] > 31 ? n : new Date(d[1], parseInt(d[3], 10) - 1, d[5], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3
                    }
                }
                else if(d[5] > 1901) {
                    switch(d[2]) {
                        case"-":
                            return d[3] > 12 || d[1] > 31 ? n : new Date(d[5], parseInt(d[3], 10) - 1, d[1], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3;
                        case".":
                            return d[3] > 12 || d[1] > 31 ? n : new Date(d[5], parseInt(d[3], 10) - 1, d[1], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3;
                        case"/":
                            return d[1] > 12 || d[3] > 31 ? n : new Date(d[5], parseInt(d[1], 10) - 1, d[3], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3
                    }
                }
                else {
                    switch(d[2]) {
                        case"-":
                            return d[3] > 12 || d[5] > 31 || d[1] < 70 && d[1] > 38 ? n : (f = d[1] >= 0 && d[1] <= 38 ? +d[1] + 2e3 : d[1], new Date(f, parseInt(d[3], 10) - 1, d[5], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3);
                        case".":
                            return d[5] >= 70 ? d[3] > 12 || d[1] > 31 ? n : new Date(d[5], parseInt(d[3], 10) - 1, d[1], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3 : d[5] < 60 && !d[6] ? d[1] > 23 || d[3] > 59 ? n : (e = new Date, new Date(e.getFullYear(), e.getMonth(), e.getDate(), d[1] || 0, d[3] || 0, d[5] || 0, d[9] || 0) / 1e3) : n;
                        case"/":
                            return d[1] > 12 || d[3] > 31 || d[5] < 70 && d[5] > 38 ? n : (f = d[5] >= 0 && d[5] <= 38 ? +d[5] + 2e3 : d[5], new Date(f, parseInt(d[1], 10) - 1, d[3], d[6] || 0, d[7] || 0, d[8] || 0, d[9] || 0) / 1e3);
                        case":":
                            return d[1] > 23 || d[3] > 59 || d[5] > 59 ? n : (e = new Date, new Date(e.getFullYear(), e.getMonth(), e.getDate(), d[1] || 0, d[3] || 0, d[5] || 0) / 1e3)
                    }
                }
            }
            if("now" === a) return null === b || isNaN(b) ? 0 | (new Date).getTime() / 1e3 : 0 | b;
            if(!isNaN(c = Date.parse(a))) return 0 | c / 1e3;
            if(g = b ? new Date(1e3 * b) : new Date, h = {
                    sun: 0,
                    mon: 1,
                    tue: 2,
                    wed: 3,
                    thu: 4,
                    fri: 5,
                    sat: 6
                }, i = {
                    yea: "FullYear",
                    mon: "Month",
                    day: "Date",
                    hou: "Hours",
                    min: "Minutes",
                    sec: "Seconds"
                }, k = "(years?|months?|weeks?|days?|hours?|minutes?|min|seconds?|sec|sunday|sun\\.?|monday|mon\\.?|tuesday|tue\\.?|wednesday|wed\\.?|thursday|thu\\.?|friday|fri\\.?|saturday|sat\\.?)", l = "([+-]?\\d+\\s" + k + "|" + "(last|next)\\s" + k + ")(\\sago)?", d = a.match(new RegExp(l, "gi")), !d) {
                return n;
            }
            for(m = 0, j = d.length; j > m; m++) {
                if(!p(d[m])) return n;
            }
            return g.getTime() / 1e3;
        };
        /* jshint ignore:end */


        /**
         * @description Parse a String and replace newlines with <br>
         *
         * @param {string} str          the string to parse
         * @param {boolean} is_xhtml    use xhtml? means to use <br />
         * @returns {string}            the string with <br> instead of newlines
         */
        CTServiceHelper.nl2br = function(str, is_xhtml) {
            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        };

        return CTServiceHelper;
    }
]);
