angular.ctModule('ct.directives.shareForm', ['ct.directives.shareWindow', 'ct.directives.copyField'])

       .value('shareFormDefaults', {
           show_form: true,
           text     : {
               facebook       : 'Facebook',
               twitter        : 'Twitter',
               whatsapp       : 'Whatsapp',
               copy_link      : 'Oder einfach Link kopieren:',
               copy_link_label: 'Share - Link',
               cancel         : 'Abbrechen'
           },
           analytics: {
               category: 'shareForm',
               action  : 'share'
           }
       })


       .config([
           '$compileProvider', function($compileProvider) {
               if($compileProvider && $compileProvider.urlSanitizationWhitelist) {
                   $compileProvider.urlSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript|whatsapp):/);
               }
               else if($compileProvider && $compileProvider.aHrefSanitizationWhitelist) {
                   $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript|whatsapp):/);
               }
           }
       ])
       /**
        * @ngdoc directive
        * @name ct.directives.directive:shareForm
        * @restrict A
        *
        * @author Stefan Schindler <stefan.schindler@campaigning-bureau.com>
        * @description
        * Implement the share form
        *
        * Prerequisites:
        *  The moderated model must have the following methods:
        *  - countFacebookShare(), called when clicking the fb-share button, that sends info to google analytics, cbtools etc.
        *  - countTwitterShare(), called when clicking the twitter-share button, that sends info to google analytics, cbtools etc.
        *  - getEncodedShareUrl(), that returns the encoded share url as string
        *
        *  Parameters:
        *  - shareable-model={Object} the model to share.
        *  - closing-callback={function} a function that gets called when the user clicks the cancel-button
        *
        * @scope
        *
        */
       .directive('shareForm', [
           'shareFormDefaults', function(shareFormDefaults) {

               var shareFormDirective = {};

               /**
                * Allow this directive as attribute only
                * @type {string}
                */
               shareFormDirective.restrict = 'A';

               /**
                * Define the scope and databinding of the directive
                * @type {object}
                */
               shareFormDirective.scope = {
                   shareableModel : '=',
                   twitterText    : '@',
                   twitterHashtags: '@',
                   twitterRelated : '@',
                   callback       : '&closingCallback'
               };

               /**
                * Define the TemplateURL for the directives markup
                * @type {string}
                */
               shareFormDirective.templateUrl = 'ct.directives.shareForm.html';

               shareFormDirective.controller = [
                   "$scope",
                   "$element",
                   "$attrs",
                   "$templateCache",
                   "$log",
                   "$timeout",
                   "CTConfig",
                   function($scope, $element, $attr, $templateCache, $log, $timeout, CTConfig) {

                       /**
                        * Set default values
                        */
                       angular.extend(shareFormDefaults, {
                           facebook_app_id: CTConfig.FACEBOOK_APP_ID,
                           analytics      : $scope.$eval($attr.analytics || '{"empty":true}')
                       });
                       $scope.shareForm = shareFormDefaults;


                       /**
                        * return the url of the current share model.
                        *
                        * @returns {string}
                        */
                       $scope.getDecodedShareUrl = function() {
                           if($scope.shareableModel && $scope.shareableModel.getEncodedShareUrl) {
                               return decodeURIComponent($scope.shareableModel.getEncodedShareUrl());
                           }
                           else {
                               return location.href;
                           }
                       };

                       /**
                        * return the url of the current share model.
                        *
                        * @returns {string}
                        */
                       $scope.getEncodedShareUrl = function() {
                           if($scope.shareableModel && $scope.shareableModel.getEncodedShareUrl) {
                               return $scope.shareableModel.getEncodedShareUrl();
                           }
                           else {
                               return encodeURIComponent(location.href);
                           }
                       };

                       /**
                        * called when the user clicks the cancel-button.
                        * calls the passed callback function.
                        */
                       $scope.closeForm = function() {
                           $scope.callback();
                       };
                   }
               ];

               /**
                * Tell angular about the awesomeness
                */
               return shareFormDirective;
           }
       ]);
