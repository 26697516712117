angular.ctModule('ct.services.hash', [])
       .service('CTHashService', [
           '$rootScope', '$location', 'CTHash', function($rootScope, $location, CTHash) {

               var _ctHashService   = this,

                   hashValues       = {},
                   initRun          = true,
                   _useSearchParams = false;

               function setHash() {
                   var _setHash;
                   //location.hash = createHash();
                   if(_useSearchParams) {
                       _setHash = function() {
                           var params = createHash(undefined, true);
                           angular.forEach(params, function(value, key) {
                               $location.search(key, value)
                           });
                       }
                   }
                   else {
                       _setHash = function() {
                           $location.path(createHash());
                       }
                   }

                   if($rootScope.$applyAsync) {
                       $rootScope.$applyAsync(_setHash);
                   }
                   else {
                       setTimeout(function() {
                           $rootScope.$apply(_setHash);
                       }, 1);
                   }
               }

               function createHash(overrides, returnSearchParams) {
                   var hash = [];

                   angular.forEach(angular.extend({}, hashValues, (overrides || {})), function(value, key) {
                       if(value) {
                           hash.push(key + '=' + value);
                       }
                   });

                   var hashString;
                   if(_useSearchParams) {
                       if(returnSearchParams) {
                           return angular.extend({}, hashValues, (overrides || {}));
                       }

                       var hashPrefix = $location.path().split(_useSearchParams)[0];
                       hashString     = hashPrefix + '?' + hash.join('&');
                       hashString     = (hashString.substring(0, 1) === '#') ? hashString.substring(1) : hashString;
                   }
                   else {
                       hashString = '/' + hash.join('/');
                   }

                   return hashString;
               }

               function parseHash(hash) {
                   var oldStoryhashRegex      = /#\/?story(?:[=\/])([0-9]+)/, // the link to a single commitment in old commitment lists were named 'statement', now it is 'commitment'
                       oldCommitmenthashRegex = /#\/?statement(?:[=\/])([0-9]+)/;

                   if(hash == '#/einsenden') {
                       hash = '#/einsenden=1';
                   }
                   else if(oldStoryhashRegex.test(hash)) {
                       hash = '#/entry=' + hash.match(oldStoryhashRegex)[1];
                   }
                   else if(oldCommitmenthashRegex.test(hash)) {
                       hash = '#/commitment=' + hash.match(oldCommitmenthashRegex)[1];
                   }
                   else if(_useSearchParams) {
                       hashValues = $location.search();
                   }
                   else {
                       hash = $location.path();
                   }

                   var hashArray = hash.split('/');
                   hashValues    = {};

                   angular.forEach(hashArray, function(h) {
                       if(h == '#') return;

                       h                = h.split('=');
                       //console.log('Parse', h);
                       hashValues[h[0]] = h[1];
                   });
               }

               _ctHashService.set = function(key, value) {
                   hashValues[key] = value;
                   setHash();
               };

               _ctHashService.get = function(key) {
                   return hashValues[key];
               };

               _ctHashService.del = function(key) {
                   if(_useSearchParams) {
                       hashValues[key] = null;
                   }
                   else {
                       delete hashValues[key];
                   }
                   setHash();
               };

               _ctHashService.getHash = function(obj) {
                   return createHash(obj);
               };

               _ctHashService.useSearch = function(flag) {
                   _useSearchParams = flag;
                   parseHash();
               };

               CTHash.change(parseHash);

               $rootScope.getHash = _ctHashService.getHash;

               return _ctHashService;
           }
       ]);
