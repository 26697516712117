angular.ctModule('ct.filters.parseLinks', [])

       /**
        * @ngdoc filter
        * @name ct.filters.filter:parseLinks
        *
        * @requires $sce
        *
        * @function
        *
        * @param {string} input The Text you want to parse for Links
        *
        * @author Michael Scharl <ms@campaigning-bureau.com>
        * @description
        * Parse a string for links and output them as html A-tag
        *
        * Will detect links with `http`, `https`, `ftp` and `mailto`
        * If you want to use the output as data binding you should use `ng-bind-html`
        *
        * @example
        <doc:example module="ct.filters.parseLinks">
        <doc:source>
        <script>
        function fooController($scope, $filter) {
                $scope.foo = {
                    bar: 'Mehr Infos siehe http://example.com'
                };

                $scope.$watch('foo.bar', function() {
                    $scope.foo.foo = $filter('parseLinks')($scope.foo.bar);
                });
            }
        </script>
        <div ng-controller="fooController">
        <div class="form-group">
        <textarea class="form-control" ng-model="foo.bar"></textarea>
        </div>
        <p ng-bind-html="foo.foo"></p>
        </div>
        </doc:source>
        </doc:example>
        */
       .filter('parseLinks', [
           "$sce", function($sce) {

               return function(input) {

                   var regEx = new RegExp('(((http|https|ftp)://)|mailto:|www\.)([^<\\s]+)', 'ig');

                   function generateLinkFromMatch(match, protocol, p2, p3, url) {
                       var stringSuffix = '', displayURL;

                       if(url.substr(-1) === '.' || url.substr(-1) === '?' || url.substr(-1) === '!' || url.substr(-1) === ',') {
                           stringSuffix = url.substr(-1);
                           url          = url.substr(0, url.length - 1);
                       }

                       //handle www.xy.at domains
                       if(protocol === "www.") {
                           protocol = "http://";
                           url      = "www." + url;
                       }

                       displayURL = url;
                       if(displayURL.substr(-1) === '/') {
                           displayURL = displayURL.substr(0, displayURL.length - 1);
                       }

                       return '<a href="' + protocol + url + '" target="_blank" >' + displayURL + '</a>' + stringSuffix;
                   }


                   return $sce.trustAsHtml(input.replace(regEx, generateLinkFromMatch));
               };
           }
       ]);
