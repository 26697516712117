angular.ctModule('ct.directives.moderationStamp', ['ct.services.csphotoselector'])

       .value('moderationStampDefaults', {
           displayStamp: false,
           displayMenu : false,
           baseIcon    : 'fa-pencil'
       })
       /**
        * @ngdoc directive
        * @name ct.directives.directive:labelHide
        * @restrict A
        *
        * @author Stefan Schindler <stefan.schindler@campaigning-bureau.com>
        * @description
        * Implement a moderation stamp.
        *
        * Prerequisites: The moderated model must have the following methods:
        *  updateState(int), that returns a promise that resolves, when the update was successful.
        *  getPerson(void), that returns a {CTPerson} object (for blocking a person).
        *
        *  - config values:
        *   - displayStamp {bool}
        *     The initial state of the whole stamp. it is hidden by default and gets shown if the user is moa.
        *
        *   - displayMenu {bool}
        *     The initial state of the menu
        *
        *   - baseIcon {string}
        *     The initial base stamp icon
        *
        * @scope
        *
        */
       .directive('moderationStamp', [
           'moderationStampDefaults', function(moderationStampDefaults) {

               var moderationStampDirective = {};

               /**
                * Allow this directive as attribute only
                * @type {string}
                */
               moderationStampDirective.restrict = 'A';

               /**
                * Define the scope and databinding of the directive
                * @type {object}
                */
               moderationStampDirective.scope = {
                   moderatedModel: '='
               };

               /**
                * Define the TemplateURL for the directives markup
                * @type {string}
                */
               moderationStampDirective.templateUrl = 'ct.directives.moderationStamp.html';

               moderationStampDirective.controller = [
                   "$scope",
                   "$element",
                   "$attrs",
                   "$templateCache",
                   "$log",
                   "$timeout",
                   "CTServiceAuthentication",
                   function($scope, $element, $attr, $templateCache, $log, $timeout, CTServiceAuthentication) {

                       /**
                        * Set default values
                        */
                       $scope.moderationStamp = angular.extend({}, moderationStampDefaults);

                       $scope.moderationStamp.APICall = false;


                       /**
                        * used to trigger the display of the moderation stamp.
                        * once the CTServiceAuthentication.isMOA function resolves to true, the stamp is displayed.
                        */
                       $scope.authentication = CTServiceAuthentication;

                       /**
                        * When clicked on the Icon it opens the Menu and change the baseIcon
                        * to a close button
                        */
                       $scope.toggleMenu = function() {
                           if(!$scope.moderationStamp.displayMenu) {
                               $scope.moderationStamp.baseIcon = 'fa-times';
                           }
                           else {
                               $scope.moderationStamp.baseIcon = 'fa-pencil';
                           }

                           $scope.moderationStamp.displayMenu = !$scope.moderationStamp.displayMenu;

                       };

                       /**
                        * called when the update state button is clicked.
                        * calls the updateState method of the current model.
                        * @param state
                        */
                       $scope.updateState = function(state) {
                           // update the state, if an updatestate function exists for the model
                           if(typeof $scope.moderatedModel.updateState == 'function') {
                               $scope.moderationStamp.APICall     = true;
                               // change to a spinning icon while saving
                               $scope.moderationStamp.baseIcon    = 'fa-refresh fa-spin';
                               // hide the menu
                               $scope.moderationStamp.displayMenu = false;

                               $scope.moderatedModel.updateState(state)
                                     .then(function() {

                                         $scope.moderationStamp.baseIcon = 'fa-check';
                                         $timeout(function() {
                                             // reset the icon after 2 seconds
                                             $scope.moderationStamp.baseIcon = moderationStampDefaults.baseIcon;
                                             $scope.moderationStamp.APICall  = false;
                                         }, 2000);
                                     }, function(error) {
                                         // error icon
                                         $scope.moderationStamp.baseIcon = 'fa-exclamation-circle';
                                         $scope.moderationStamp.APICall  = false;
                                         $log.error(error);
                                     });
                           }
                           else {
                               $log.error('function updateState not available for model', $scope.moderatedModel);
                           }

                       };

                       /**
                        * called when blocking a person.
                        *
                        * tries to set the blocked state to the person and reloads the page.
                        */
                       $scope.blockPerson = function() {

                           $scope.moderationStamp.APICall     = true;
                           // change to a spinning icon while saving
                           $scope.moderationStamp.baseIcon    = 'fa-refresh fa-spin';
                           // hide the menu
                           $scope.moderationStamp.displayMenu = false;

                           var person     = $scope.moderatedModel.getPerson();
                           // set the blocked state
                           person.blocked = true;

                           person.save().then(function() {
                               $scope.moderationStamp.baseIcon = 'fa-check';
                               $timeout(function() {
                                   // reload current page
                                   location.reload();
                               }, 2000);
                           }, function(error) {
                               $scope.moderationStamp.baseIcon = 'fa-exclamation-circle';
                               $scope.moderationStamp.APICall  = false;
                               $log.error('could not update person state', error);
                           });
                       };
                   }
               ];

               /**
                * Tell angular about the awesomeness
                */
               return moderationStampDirective;
           }
       ]);
